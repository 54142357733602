import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useProviderUpdate } from "./use-provider-update";
import { UpdateProviderBody } from "@user-management-context/write/domain/types/admin/provider";
import { ProviderForm } from "@user-management-context/primary/admin/provider-detail/provider-form.component";
import { retrieveProvider } from "@user-management-context/read/application/use-cases/admin/provider-retrieval/retrieve-provider";
import { selectProviderRetrieval } from "@user-management-context/read/application/use-cases/admin/provider-retrieval/selectors/provider-retrieval-selectors";
import { AppState } from "@redux/app-state";
import { isEmailUsed } from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";
import { ProviderDetailsSkeleton } from "@user-management-context/primary/admin/provider-detail/provider-details.skeleton";
import { updateProvider } from "@user-management-context/write/application/use-cases/admin/provider-update/update-provider";
import { BackButton } from "@shared-kernel/primary/shared/back-button/back-button";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";

export const ProviderDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { provider, isLoading } = useAppSelector(selectProviderRetrieval);
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);

  const checkIfEmailUsed = useCallback(
    (email: string) => {
      dispatch(isEmailUsed(email));
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      dispatch(retrieveProvider(id));
    }
  }, [id, dispatch]);

  const saveProvider = async (provider: Omit<UpdateProviderBody, "id">) => {
    if (id) dispatch(updateProvider({ ...provider, id }));
  };

  useProviderUpdate();

  return (
    <>
      <BackButton link="/providers" label="Retour à la liste" />
      <CustomCard title="Informations">
        <div className="space-y-5 w-full">
          {isLoading ? (
            <ProviderDetailsSkeleton />
          ) : (
            <ProviderForm provider={provider} onSubmit={saveProvider} doesEmailExist={doesExist} checkIfEmailUsed={checkIfEmailUsed} />
          )}
        </div>
      </CustomCard>
    </>
  );
};
