import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";

export const selectProviderRetrieval = (state: AppState) => {
  const {
    providerRetrieval: { data, fetching },
  } = state;

  return {
    provider: data,
    isLoading: isLoadingState(fetching),
  };
};
