import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ConfirmInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { Dependencies } from "src/redux/store";

export const adminConfirmProviderInternship = createAsyncThunk<void, ConfirmInternshipBody, { extra: Partial<Dependencies> }>(
  "providerInternships/adminConfirmProviderInternship",
  async (body: ConfirmInternshipBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.confirmInternship(body);
  }
);

export const adminResetConfirmProviderInternship = createAction("providerInternships/adminResetConfirmProviderInternship");
