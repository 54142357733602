import { TokenStorageGateway } from "../../../../../shared-kernel/application/ports/token-storage-gateway";
import { AdminUserInvitationPort } from "../../../ports/user-invitation-port";
import { UserInvitation } from "../../../../read/domain/types/admin/user-invitation";
import { InviteUserBody } from "../../../../write/domain/types/admin/user-invitation";
import { HttpGateway } from "../../../../../shared-kernel/secondary/http-gateway";
import config from "../../../../../config/backend";

export class AdminBackendUserInvitationAdapter extends HttpGateway implements AdminUserInvitationPort {
  private _route: string = "admin/users/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async invite(user: InviteUserBody): Promise<string> {
    const route = this._route + "/invite";
    const { data: userId } = await this._instance.post(route, user);
    return userId;
  }

  async all(): Promise<UserInvitation[]> {
    const route = this._route + "/invitations";
    const { data: invitations } = await this._instance.get(route);
    return invitations;
  }
}
