import { createReducer } from "@reduxjs/toolkit";
import { BudgetCreateState } from "@redux/app-state";
import { createBudget, resetCreateBudget } from "./create-budget";

const initialState: BudgetCreateState = { justCreatedBudgetId: null, fetching: "idle" };

export const createBudgetReducer = createReducer<BudgetCreateState>(initialState, builder => {
  builder.addCase(createBudget.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedBudgetId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createBudget.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createBudget.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateBudget, () => {
    return initialState;
  });
});
