import { useCallback, useState } from "react";

export const useModal = (isOpenByDefault: boolean = false) => {
  const [isModalOpen, setModalIsOpen] = useState<boolean>(isOpenByDefault);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  const toggleModal = useCallback(() => {
    setModalIsOpen(!isModalOpen);
  }, [isModalOpen]);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  return {
    isModalOpen,
    openModal,
    toggleModal,
    closeModal,
  };
};
