import { createReducer } from "@reduxjs/toolkit";
import { FundingRequestSubmissionToAfdasState } from "@redux/app-state";
import { submitFundingRequestToAfdas } from "./submit-funding-request-to-afdas";

const initialState: FundingRequestSubmissionToAfdasState = { fetching: "idle" };

export const submitFundingRequestToAfdasReducer = createReducer<FundingRequestSubmissionToAfdasState>(initialState, builder => {
  builder.addCase(submitFundingRequestToAfdas.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(submitFundingRequestToAfdas.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(submitFundingRequestToAfdas.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
