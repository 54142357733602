import { createReducer } from "@reduxjs/toolkit";
import { UserEmailExistenceState } from "@redux/app-state";
import { isEmailUsed, resetIsEmailUsed } from "./is-email-used";

const initialState: UserEmailExistenceState = { fetching: "idle", doesExist: false };

export const isEmailUsedReducer = createReducer<UserEmailExistenceState>(initialState, builder => {
  builder.addCase(isEmailUsed.fulfilled, state => {
    return {
      ...state,
      doesExist: true,
      fetching: "success",
    };
  });
  builder.addCase(isEmailUsed.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(isEmailUsed.rejected, (state, { error }) => {
    return {
      ...state,
      ...(error.message === "Request failed with status code 404" && { doesExist: false }),
      fetching: "failed",
    };
  });
  builder.addCase(resetIsEmailUsed, () => {
    return initialState;
  });
});
