import { createReducer } from "@reduxjs/toolkit";
import { ConventionPaymentListRetrievalState } from "@redux/app-state";
import { retrieveConventionTransferList } from "./retrieve-convention-transfer-list";

const initialState: ConventionPaymentListRetrievalState = { fetching: "idle", data: [] };

export const retrieveConventionTransferListReducer = createReducer<ConventionPaymentListRetrievalState>(initialState, builder => {
  builder.addCase(retrieveConventionTransferList.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveConventionTransferList.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveConventionTransferList.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
