import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadProfilePicture = createAsyncThunk<string, { picture: File }, { extra: Partial<Dependencies> }>(
  "users/uploadProfilePicture",
  async ({ picture }: { picture: File }, { extra: { userGateway } }) => {
    return userGateway!.uploadProfilePicture(picture);
  }
);

export const resetUploadProfilePicture = createAction("users/resetUploadProfilePicture");
