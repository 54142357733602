import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { FundingRequestEligibilityVM } from "../../../../domain/types/student/funding-request";

export const retrieveFundingRequestEligibility = createAsyncThunk<FundingRequestEligibilityVM, void, { extra: Partial<Dependencies> }>(
  "fundingRequests/retrieveFundingRequestEligibility",
  async (_: void, { extra: { fundingRequestGateway } }) => {
    return fundingRequestGateway!.fundingRequestEligibility();
  }
);

export const resetRetrieveFundingRequestEligibility = createAction("fundingRequests/resetRetrieveFundingRequestEligibility");
