import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export const adminSelectProviderInternshipsRetrievalForList = createSelector(
  [(state: AppState) => state.adminProviderInternshipsRetrieval],
  adminProviderInternshipsRetrieval => {
    const { data, fetching } = adminProviderInternshipsRetrieval;

    const formattedData = [...data].sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());

    return {
      internships: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
