import { createReducer } from "@reduxjs/toolkit";
import { TeacherOldConventionsRetrievalState } from "@redux/app-state";
import { retrieveOldConventions } from "./retrieve-old-conventions";

const initialState: TeacherOldConventionsRetrievalState = { fetching: "idle", data: [] };

export const retrieveTeacherOldConventionsReducer = createReducer<TeacherOldConventionsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveOldConventions.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveOldConventions.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveOldConventions.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
