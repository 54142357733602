import { object, string } from "yup";
import { Admin } from "@user-management-context/read/domain/types/admin/admin";
import { CreateAdminAccountBody } from "@user-management-context/write/domain/types/admin/admin";
import { validatePassword } from "../../../../../user-management-context/write/domain/services/password";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export type FormInputs = {
  name: string;
  lastName: string;
  password: string;
};

export const defaultValues = {
  name: "",
  lastName: "",
  password: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  password: string()
    .required(REQUIRED_FIELD)
    .test("Mot de passe valide", "Le mot de passe n'est pas valide", (password?: string) => {
      return password ? validatePassword(password) : false;
    }),
});

export const formatAdminDataToFormData = (admin: Admin): FormInputs => {
  return {
    ...defaultValues,
    name: admin.name ?? "",
    lastName: admin.lastName ?? "",
  };
};

export const adminFormatFormDataToBodyData = (admin: FormInputs, userId: string, token: string): CreateAdminAccountBody => {
  return {
    userId,
    token,
    name: admin.name,
    lastName: admin.lastName,
    password: admin.password,
  };
};
