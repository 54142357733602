import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ProviderStudentInvitationListSkeleton } from "@user-management-context/primary/provider/student-invitation-list/student-invitation-list.skeleton";
import { ProviderStudentInvitationListComponent } from "@user-management-context/primary/provider/student-invitation-list/student-invitation-list.components";
import { retrieveProviderStudentInvitations } from "@user-management-context/read/application/use-cases/provider/student-invitations-retrieval/retrieve-provider-student-invitations";
import { selectProviderStudentInvitationRetrievalForList } from "@user-management-context/read/application/use-cases/provider/student-invitations-retrieval/selectors/provider-student-invitation-list-selectors";
import { useModal } from "@hooks/useModal";
import { InviteProviderStudentModal } from "@user-management-context/primary/provider/student-invitation-list/invite-provider-student-modal";
import { useInternshipInvitationSent } from "@user-management-context/primary/provider/student-invitation-list/use-internship-invitation-sent";
import { retrieveProviderTrainings } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings";
import { selectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import { inviteStudentToInternship } from "@academy-context/write/application/use-cases/provider/student-internship-invitation/invite-student-to-internship";
import { ProviderStudentInternshipInvitationBody } from "@user-management-context/shared/application/ports/provider-repository";

export const ProviderStudentInvitationListContainer = () => {
  const dispatch = useAppDispatch();
  const { invitations, isLoading } = useAppSelector(selectProviderStudentInvitationRetrievalForList);
  const { isLoading: isTrainingsRetrievalLoading, trainings } = useAppSelector(selectProviderTrainingsRetrievalForList);
  const { closeModal, isModalOpen, openModal } = useModal();

  useEffect(() => {
    dispatch(retrieveProviderStudentInvitations());
    dispatch(retrieveProviderTrainings());
  }, [dispatch]);

  const onNewStudent = () => {
    openModal();
  };

  const onSubmit = (body: ProviderStudentInternshipInvitationBody) => {
    dispatch(inviteStudentToInternship(body));
  };

  useInternshipInvitationSent();

  const trainingOptions = trainings.map(training => ({ value: training.id, label: training.title }));

  return (
    <>
      <InviteProviderStudentModal isOpen={isModalOpen} onClose={closeModal} trainingOptions={trainingOptions} onSubmit={onSubmit} />
      <CustomCard
        title="Elèves"
        headerChildren={
          <div>
            <Button onClick={onNewStudent}>Nouvel élève</Button>
          </div>
        }
      >
        {isLoading || isTrainingsRetrievalLoading ? (
          <ProviderStudentInvitationListSkeleton />
        ) : (
          <ProviderStudentInvitationListComponent invitations={invitations} />
        )}
      </CustomCard>
    </>
  );
};
