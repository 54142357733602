import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveProviderStudentInvitations } from "@user-management-context/read/application/use-cases/provider/student-invitations-retrieval/retrieve-provider-student-invitations";
import { ProviderInvitationListItem } from "@user-management-context/read/domain/types/provider/invitation";

const initialState: RetrievalState<ProviderInvitationListItem[]> = { data: [], fetching: "idle" };

export const retrieveProviderStudentInvitationsReducer = createReducer<RetrievalState<ProviderInvitationListItem[]>>(
  initialState,
  builder => {
    builder.addCase(retrieveProviderStudentInvitations.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(retrieveProviderStudentInvitations.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(retrieveProviderStudentInvitations.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
