import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateProviderBody } from "@user-management-context/write/domain/types/admin/provider";

export const updateProvider = createAsyncThunk<void, UpdateProviderBody, { extra: Partial<Dependencies> }>(
  "providers/updateProvider",
  async (body: UpdateProviderBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.update(body);
  }
);

export const resetUpdateProvider = createAction("providers/resetUpdateProvider");
