import React from "react";
import { ArrowBigLeft } from "lucide-react";

interface Props {
  onBack: () => void;
  email: string;
}

const CheckPage = ({ onBack, email }: Props) => {
  return (
    <div className="space-y-3">
      <div>
        <span>Un email a été envoyé à l'adresse suivante:</span>
      </div>
      <div>
        <span>{email}</span>
      </div>
      <div>
        <span>Veuillez suivre les instructions afin de réinitialiser votre mot de passe.</span>
      </div>
      <div onClick={onBack} role="button" className="flex items-center hover:font-semibold">
        <ArrowBigLeft />
        <span>Retour</span>
      </div>
    </div>
  );
};

export default CheckPage;
