import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { requestCollaboration, resetRequestCollaboration } from "./request-collaboration";

const initialState: SimpleState = { processing: "idle" };

export const requestCollaborationReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(requestCollaboration.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(requestCollaboration.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(requestCollaboration.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetRequestCollaboration, () => {
    return initialState;
  });
});
