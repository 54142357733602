import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../redux/store";
import { CreateProviderBody } from "@user-management-context/write/domain/types/admin/provider";

export const createProvider = createAsyncThunk<string, CreateProviderBody, { extra: Partial<Dependencies> }>(
  "providers/createProvider",
  async (provider: CreateProviderBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.create(provider);
  }
);

export const resetCreateProvider = createAction("providers/resetCreateProvider");
