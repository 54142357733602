import React from "react";
import { BudgetSummary } from "./budget-summary.component";
import { WritingListSummary } from "./lessons-list-summary.components";
import { ActiveConventions } from "./active-conventions.components";
import { StudentSummaryExtended } from "../../../read/application/use-cases/student/lessons-retrieval/selectors/shared";

interface Props {
  summary: StudentSummaryExtended;
  lastActiveConvention: { teacher: string; hours: number; hoursDone: number } | null;
  adminView?: boolean;
}

export const StudentSummary = ({ summary, lastActiveConvention, adminView = false }: Props) => {
  const entries = adminView ? summary.lastEntries : summary.lastEntries.slice(0, 10);
  return (
    <div>
      <div className="grid grid-cols-10 gap-4 mb-5">
        <div className="col-span-7">
          <BudgetSummary
            nextFundingDate={summary.nextFundingDate}
            localeNextFundingDate={summary.localeNextFundingDate}
            balance={summary.balance}
          />
        </div>
        <div className="col-span-3">
          <ActiveConventions convention={lastActiveConvention} />
        </div>
      </div>
      <WritingListSummary adminView={adminView} entries={entries} />
    </div>
  );
};
