import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveRefundList } from "./retrieve-refund-list";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";

const initialState: RetrievalState<Refund[]> = { fetching: "idle", data: [] };

export const retrieveRefundListReducer = createReducer<RetrievalState<Refund[]>>(initialState, builder => {
  builder.addCase(retrieveRefundList.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveRefundList.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveRefundList.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
