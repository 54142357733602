import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";

export const retrieveProviderTrainings = createAsyncThunk<ProviderTraining[], void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderTrainings",
  async (_: void, { extra: { providerRepository } }) => {
    return providerRepository!.trainingList();
  }
);
