import { createReducer } from "@reduxjs/toolkit";
import { FundingRequestCreateState } from "@redux/app-state";
import { createInstantFundingRequest, resetCreateInstantFundingRequest } from "./create-instant-funding-request";

const initialState: FundingRequestCreateState = { justCreatedFundingRequestId: null, fetching: "idle" };

export const createInstantFundingRequestReducer = createReducer<FundingRequestCreateState>(initialState, builder => {
  builder.addCase(createInstantFundingRequest.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedFundingRequestId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createInstantFundingRequest.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createInstantFundingRequest.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateInstantFundingRequest, () => {
    return initialState;
  });
});
