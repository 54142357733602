import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { Dependencies } from "src/redux/store";

export const adminUpdateProviderInternship = createAsyncThunk<void, UpdateInternshipBody, { extra: Partial<Dependencies> }>(
  "providerInternships/adminUpdateProviderInternship",
  async (body: UpdateInternshipBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.updateInternship(body);
  }
);

export const adminResetUpdateProviderInternship = createAction("providerInternships/adminResetUpdateProviderInternship");
