export enum OPERA_OFF_TRAINING {
  TECHNIQUE_VOCALE = "Chanteurs (répertoires lyrique, baroque et contemporain) : travailler sa technique vocale et son interprétation",
  PERFECTIONNER_SA_VOIX = "Chanteurs et Acteurs : Perfectionner sa voix parlée/sa voix chantée",
  AUTONOMIE_LECTURE_PARTITION = "Atelier de Solfège pour Chanteurs Lyriques : comment acquérir l’autonomie dans la lecture d’une partition",
  ALLEMAND_CHANT = "Coaching LANGUE ALLEMANDE : comment renforcer et parfaire l’allemand parlé/chanté",
  SEUL_EN_SCENE = "Seul en scène : Accompagnement Individuel sur mesure et/ou Workshop collectif",
  LIVRE_AUDIO = "LIVRE AUDIO : devenir professionnel du LIVRE AUDIO",
  TECHNIQUE_ALEXANDER = "La Technique ALEXANDER : comment améliorer sa pratique vocale et instrumentale",
  LIVRET_COMEDIE_MUSICALE = "Ecrire LE LIVRET D'UNE COMÉDIE MUSICALE par Les Ateliers du LIBRE ARTISTE",
  CADREUR = "Cadreur/Réalisateur Captation spectacle vivant",
}
