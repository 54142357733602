import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export interface UpdateTrainingObjectivePayload {
  trainingId: string;
  id: string;
  role: "student" | "teacher";
  done: string;
}

export const updateTrainingObjective = createAsyncThunk<void, UpdateTrainingObjectivePayload, { extra: Partial<Dependencies> }>(
  "trainingSessions/updateTrainingObjective",
  async (payload: UpdateTrainingObjectivePayload, { extra: { trainingGateway } }) => {
    return trainingGateway!.updateTrainingObjective(payload);
  }
);
