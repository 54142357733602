import { DateInterval } from "@shared-kernel/core/types/date-interval";
import { Nullable } from "@shared-kernel/core/types/nullable";

export enum PROVIDER_INTERNSHIP_STATUS {
  FUNDING_REQUEST_SUBMITTED_BY_STUDENT = "funding_request_submitted_by_student",
  FUNDING_REQUEST_SUBMITTED_TO_FUNDER = "funding_request_submitted_to_funder",
  FUNDING_REQUEST_ACCEPTED_BY_FUNDER = "funding_request_accepted_by_funder",
  INTERNSHIP_CONFIRMED = "internship_confirmed",
  INTERNSHIP_CANCELED_BY_ADMIN = "internship_canceled_by_admin",
  INTERNSHIP_CANCELED_BY_PROVIDER = "internship_canceled_by_provider",
  INTERNSHIP_FINISHED = "internship_finished",
  INVOICE_RECEIVED = "invoice_received",
  INVOICE_PAID = "invoice_paid",
}

interface ProviderInternshipDetails {
  training: string;
  price: Nullable<number>;
  interval: Nullable<DateInterval>;
}

export interface ProviderInternship {
  id: string;
  student: { id: string; profilePictureUrl: Nullable<string>; name: string; lastName: string };
  provider: {
    tag: string;
    id: string;
  };
  details: ProviderInternshipDetails;
  status: PROVIDER_INTERNSHIP_STATUS;
  creationDate: string;
}
