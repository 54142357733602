import { createReducer } from "@reduxjs/toolkit";
import { FundingRequestRetrievalState } from "@redux/app-state";
import { resetRetrieveFundingRequest, retrieveFundingRequest } from "./retrieve-funding-request";

const initialState: FundingRequestRetrievalState = {
  fetching: "idle",
  data: null,
};

export const retrieveFundingRequestReducer = createReducer<FundingRequestRetrievalState>(initialState, builder => {
  builder.addCase(retrieveFundingRequest.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveFundingRequest.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveFundingRequest.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetRetrieveFundingRequest, () => {
    return initialState;
  });
});
