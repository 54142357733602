import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherOldConventionListVM } from "../../../../domain/types/teacher/convention";

export const retrieveOldConventions = createAsyncThunk<TeacherOldConventionListVM[], void, { extra: Partial<Dependencies> }>(
  "conventions/retrieveTeacherOldConventions",
  async (_: void, { extra: { teacherConventionGateway } }) => {
    return teacherConventionGateway!.oldConventions();
  }
);
