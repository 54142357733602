import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AdminUpdateProviderTrainingBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export const adminUpdateProviderTraining = createAsyncThunk<void, AdminUpdateProviderTrainingBody, { extra: Partial<Dependencies> }>(
  "providerTrainings/updateProviderTraining",
  async (body: AdminUpdateProviderTrainingBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.updateTraining(body);
  }
);

export const adminResetUpdateProviderTraining = createAction("providerTrainings/resetUpdateProviderTraining");
