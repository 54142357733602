import { CreateFundingRequestBody } from "@academy-context/write/domain/types/student/funding-request";
import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";

export type FormInputs = {
  educationalAdvisorType: EDUCATIONAL_ADVISOR;
  educationalAdvisorId: string;
  trainingTitle: string;
  afdasId: string;
  afdasPassword: string;
  resumeUrl: string;
  bioUrl: string;
  coverLetter: string;
  coverLetterText: string;
  personalizedEducationalProject: string;
  personalizedEducationalProjectText: string;
};

export const defaultValues = {
  educationalAdvisorType: EDUCATIONAL_ADVISOR.TEACHER,
  educationalAdvisorId: "",
  trainingTitle: "",
  afdasId: "",
  afdasPassword: "",
  resumeUrl: "",
  bioUrl: "",
  coverLetter: "",
  coverLetterText: "",
  personalizedEducationalProject: "",
  personalizedEducationalProjectText: "",
};

export const schema = object().shape(
  {
    educationalAdvisorType: string().max(255).required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
    educationalAdvisorId: string().max(255).required(REQUIRED_FIELD),
    trainingTitle: string()
      .max(255)
      .when("educationalAdvisorType", {
        is: EDUCATIONAL_ADVISOR.PROVIDER, // when educationalAdvisorType is 'provider'
        then: string().required(REQUIRED_FIELD), // trainingTitle is required
        otherwise: string().notRequired(), // otherwise it's not required
      }),
    afdasId: string().max(255).required(REQUIRED_FIELD),
    afdasPassword: string().max(255).required(REQUIRED_FIELD),
    resumeUrl: string().when("bioUrl", {
      is: "",
      then: string().url().max(255).required(REQUIRED_FIELD),
      otherwise: string().url().max(255),
    }),
    bioUrl: string().when("resumeUrl", {
      is: "",
      then: string().url().max(255).required(REQUIRED_FIELD),
      otherwise: string().url().max(255),
    }),
    coverLetter: string().max(4000).required(REQUIRED_FIELD),
    coverLetterText: string().max(4000).required(REQUIRED_FIELD),
    personalizedEducationalProject: string().max(4000).required(REQUIRED_FIELD),
    personalizedEducationalProjectText: string().max(4000).required(REQUIRED_FIELD),
  },
  [["resumeUrl", "bioUrl"]]
);

export const formatFormDataToBodyDataCreateFundingRequest = (fundingRequest: FormInputs): CreateFundingRequestBody => {
  return {
    educationalAdvisor: { id: fundingRequest.educationalAdvisorId, type: fundingRequest.educationalAdvisorType },
    afdasId: fundingRequest.afdasId,
    afdasPassword: fundingRequest.afdasPassword,
    trainingTitle: fundingRequest.trainingTitle || null,
    resumeUrl: fundingRequest.resumeUrl || null,
    bioUrl: fundingRequest.bioUrl || null,
    coverLetter: fundingRequest.coverLetter,
    personalizedEducationalProject: fundingRequest.personalizedEducationalProject,
  };
};
