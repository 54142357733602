import { createReducer } from "@reduxjs/toolkit";
import { BudgetsRetrievalState } from "@redux/app-state";
import { retrieveBudgets } from "./retrieve-budgets";

const initialState: BudgetsRetrievalState = { fetching: "idle", data: [] };

export const retrieveBudgetsReducer = createReducer<BudgetsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveBudgets.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveBudgets.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveBudgets.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
