import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Progress } from "@shared-kernel/primary/shared/shadcn/ui/progress";
import clsx from "clsx";
import { ChevronDown } from "lucide-react";
import { formatDateToLocale } from "@utils/formatting";
import { Nullable } from "@shared-kernel/core/types/nullable";

interface Props {
  convention: { student: string; hours: number; hoursDone: number; paymentThreshold: number; paymentDate: Nullable<string> };
}

const setPaymentLabel = (paymentDate: string | null, toPay: boolean): string => {
  if (paymentDate) return `Payée le ${paymentDate}`;
  if (toPay) return "À payer";
  return "Paiement";
};

export const OldConventionCard = ({ convention }: Props) => {
  const { student, hours, hoursDone, paymentThreshold, paymentDate } = convention;

  // This is done to handle when there is only 1 hour
  const threshold = Math.max(paymentThreshold, 1);
  const toPay = hoursDone >= threshold;
  const paymentLabel = setPaymentLabel(paymentDate ? formatDateToLocale(paymentDate) : null, toPay);
  const hasBeenPaid = Boolean(paymentDate);

  return (
    <Card className="bg-primary-foreground text-white w-full dark:bg-primary-foreground dark:text-white mt-2">
      <CardHeader className="border-b border-gray-400 p-2">
        <CardTitle className="text-sm">Ancienne convention: {student}</CardTitle>
      </CardHeader>
      <CardContent className="flex h-[100px] py-0 px-2 items-center">
        <div className="h-[8px] relative w-full">
          <div
            className={clsx("absolute flex flex-col items-center translate-x-[-50%] translate-y-[-100%]", hasBeenPaid && "text-[#2ab514]")}
            style={{ left: `${(threshold / hours) * 100}%`, top: 0 }}
          >
            <span className="text-xs">{paymentLabel}</span>
            <ChevronDown height={15} />
          </div>
          <Progress value={(hoursDone / hours) * 100} indicatorClassName={clsx(hasBeenPaid && "bg-[#2ab514]")} />
        </div>
        <div className="pl-1 flex flex-col items-center justify-center w-[60px] ml-4">
          <span className="text-2xl">
            {hoursDone}/{hours}
          </span>
          <span className="text-xs text-center">heures prises</span>
        </div>
      </CardContent>
    </Card>
  );
};
