import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const submitFundingRequestToAfdas = createAsyncThunk<
  void,
  { id: string; hasBeenSubmitted: boolean },
  { extra: Partial<Dependencies> }
>(
  "fundingRequests/submitFundingRequestToAfdas",
  async ({ id, hasBeenSubmitted }: { id: string; hasBeenSubmitted: boolean }, { extra: { fundingRequestGateway } }) => {
    return fundingRequestGateway!.submitToAfdas(id, { hasBeenSubmitted });
  }
);
