import React from "react";
import "react-phone-number-input/style.css";
import ReactPhoneInput, { Value } from "react-phone-number-input";
import { cn } from "@components/utils/utils";

interface Props {
  phone: string;
  onChange: (value?: Value) => void;
  disabled?: boolean;
}

export const PhoneInput = ({ phone, onChange, disabled = false }: Props) => {
  return (
    <ReactPhoneInput
      value={phone}
      defaultCountry="FR"
      onChange={onChange}
      className={cn(
        "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        disabled ? "text-gray-400 cursor-not-allowed" : "text-black"
      )}
      disabled={disabled}
    />
  );
};
