import React, { PropsWithChildren, ReactNode } from "react";
import {
  Tooltip as ComponentTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@shared-kernel/primary/shared/shadcn/ui/tooltip";
import { HelpCircle } from "lucide-react";

interface Props {
  tooltipContent: ReactNode;
}

export const Tooltip = ({ tooltipContent, children }: PropsWithChildren<Props>) => {
  return (
    <TooltipProvider>
      <ComponentTooltip>
        <TooltipTrigger asChild>
          <div className="flex">
            {children}
            <HelpCircle size={14} className="ml-1" color="gray" />
          </div>
        </TooltipTrigger>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </ComponentTooltip>
    </TooltipProvider>
  );
};
