import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateStudentBody, UpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { Student } from "@user-management-context/read/domain/types/admin/student";
import {
  AdminStudentFormInputs,
  schema,
  defaultValues,
  adminFormatFormDataToBodyData,
  formatStudentDataToFormData,
} from "./form-validation/student";
import SelectTeachersModal from "../../shared/select-teachers-modal/select-teachers-modal";
import { LostDataModal } from "../../../../shared-kernel/primary/shared/lost-data-modal/modal";
import { PhoneInput } from "../../../../user-management-context/primary/shared/phone-input/phone-input";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import {
  resetUploadProfilePicture,
  uploadProfilePicture,
} from "../../../../user-management-context/write/application/use-cases/student/profile-picture-upload/upload-profile-picture";
import { ERROR_MESSAGE, IMAGE_TYPE, isValidImageFile } from "../../../write/domain/constants/shared";
import { resetRetrieveStudent } from "../../../../user-management-context/read/application/use-cases/admin/student-retrieval/retrieve-student";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import ImageUploadButton from "../../../../shared-kernel/primary/shared/upload-button/image-upload-button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Pen, Save, X } from "lucide-react";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { BackButton } from "../../../../shared-kernel/primary/shared/back-button/back-button";
import { isEmailUsed } from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";

interface Props {
  onSubmit: (body: CreateStudentBody | UpdateStudentBody) => void;
  student?: Student;
}
export const StudentForm = ({ student, onSubmit }: Props) => {
  const dispatch = useAppDispatch();
  const methods = useForm<AdminStudentFormInputs>({ resolver: yupResolver(schema), defaultValues, context: { validation: "admin" } });
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);
  const { profilePictureUrl, processing } = useAppSelector((state: AppState) => state.profilePictureUpload);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const {
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    return () => {
      dispatch(resetUploadProfilePicture());
      dispatch(resetRetrieveStudent());
    };
  }, [dispatch]);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (profilePictureUrl) {
      setValue("profilePictureUrl", profilePictureUrl, { shouldDirty: true });
    }
  }, [profilePictureUrl, setValue]);

  const email = watch("email");

  // This part is used to check if user account is already registered
  useEffect(() => {
    if (email) dispatch(isEmailUsed(email));
  }, [dispatch, email]);

  useEffect(() => {
    const condition = student ? student.email !== email : email;
    if (doesExist && condition) {
      setError("email", { message: "Cet email est déjà utilisé", type: "custom" });
      setDisableSubmit(true);
    } else {
      clearErrors("email");
      setDisableSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, doesExist]);

  const resetForm = () => {
    if (student) reset(formatStudentDataToFormData(student));
    dispatch(resetUploadProfilePicture());
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSubmit = async (formBody: AdminStudentFormInputs) => {
    // Should not block navigation on creation otherwise modal opens up since we redirect user to newly created student
    if (!student) setShouldBlockNavigation(false);
    const body = adminFormatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const handleProfilePictureChange = (picture: File) => {
    clearErrors("profilePictureUrl");
    dispatch(uploadProfilePicture({ picture }));
  };

  const handleProfilePictureError = () => {
    setError("profilePictureUrl", { type: "manual", message: ERROR_MESSAGE });
  };

  const onValidateFavoriteTeachers = (teachers: string[]) => {
    setValue("favoriteTeachers", teachers, { shouldDirty: true });
  };

  const favoriteTeachers = watch("favoriteTeachers") ? watch("favoriteTeachers") : [];

  const phone = watch("mobile");

  const pictureFromForm = watch("profilePictureUrl");
  const profilePicture = profilePictureUrl || pictureFromForm;

  return (
    <>
      <BackButton link="/students" label="Retour à la liste" />
      <CustomCard title="Informations">
        <LostDataModal shouldBlockNavigation={shouldBlockNavigation} />
        <Form {...methods}>
          <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="space-y-8 grid grid-cols-4 gap-4 w-full">
            <div className="col-span-2">
              <FormField
                control={methods.control}
                name="profilePictureUrl"
                render={() => (
                  <FormItem className=" grid grid-cols-2 gap-4">
                    <div className="">
                      <ImageUploadButton
                        processing={processing}
                        label="Photo de profil"
                        onChange={handleProfilePictureChange}
                        onError={handleProfilePictureError}
                        types={IMAGE_TYPE}
                        value={profilePicture || null}
                        validatorFunc={isValidImageFile}
                      />
                      <FormMessage />
                    </div>
                    <ul className="mt-2 ml-4 list-disc text-sm">
                      <li>140*100px minimum</li>
                      <li>10mo max</li>
                      <li>JPG ou PNG</li>
                    </ul>
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-4">
              <FormField
                control={methods.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Prénom</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={methods.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nom de famille</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={methods.control}
                name="blackListed"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Blacklister l'élève</FormLabel>
                    <FormControl>
                      <div>
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={methods.control}
                name="canRequestARefundOnce"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Demande de remboursement</FormLabel>
                    <FormControl>
                      <div>
                        <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="space-y-4">
              <FormField
                control={methods.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={methods.control}
                name="mobile"
                render={() => (
                  <FormItem>
                    <FormLabel>Téléphone</FormLabel>
                    <FormControl>
                      <PhoneInput
                        phone={phone}
                        onChange={value => {
                          setValue("mobile", value as string, { shouldDirty: true });
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormItem>
                <FormLabel>Professeurs</FormLabel>
                <FormControl>
                  <div>
                    <Button type="button" onClick={() => setIsModalOpen(true)} className="w-[100px]">
                      <Pen size={14} className="mr-2" />
                      Editer
                    </Button>
                  </div>
                </FormControl>
              </FormItem>
            </div>
            <div className="flex justify-end col-span-4 mt-4">
              <Button onClick={resetForm} disabled={!isDirty} variant="outline">
                <X className="mr-2 h-4 w-4" />
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty || disableSubmit} className="ml-2">
                <Save className="mr-2 h-4 w-4" />
                Sauvegarder
              </Button>
            </div>
          </form>
        </Form>
        <SelectTeachersModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
          onSubmit={(teachers: string[]) => {
            onValidateFavoriteTeachers(teachers);
            onCloseModal();
          }}
          favoriteTeachers={favoriteTeachers}
        />
      </CustomCard>
    </>
  );
};
