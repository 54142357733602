import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../redux/store";
import { CreateTeacherBody } from "@user-management-context/write/domain/types/admin/teacher";

export const createTeacher = createAsyncThunk<string, CreateTeacherBody, { extra: Partial<Dependencies> }>(
  "teachers/createTeacher",
  async (teacher: CreateTeacherBody, { extra: { adminTeacherGateway } }) => {
    return adminTeacherGateway!.create(teacher);
  }
);

export const resetCreateTeacher = createAction("teachers/resetCreateTeacher");
