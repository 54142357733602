import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { boolean, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { Nullable } from "../../../../../../shared-kernel/core/types/nullable";
import { AnyObject } from "immer/dist/internal";
import { AFDAS_FUNDER } from "@academy-context/primary/admin/funding-request-detail/form-validation/afdas-funding-request";

export type FormInputs = {
  obtentionDate: string;
  internshipEndDate: string;
  student: string;
  funder: { label: string; value: string };
  allocatedAmount: number;
  operaOffCommission: number;
  expirationTimeInMonths: Nullable<number>;
  commentary: string;
  externalFundingRequestId: Nullable<string>;
  fundingRequestId: string;
  transmitNotificationToAdmin: boolean;
};

export const defaultValues = {
  obtentionDate: "",
  internshipEndDate: "",
  student: "",
  funder: { value: "", label: "" },
  allocatedAmount: 2400,
  operaOffCommission: 550,
  expirationTimeInMonths: null,
  commentary: "",
  externalFundingRequestId: null,
  fundingRequestId: "",
  transmitNotificationToAdmin: false,
};

const requiredWhenAFDAS = (funder: { value: string }, schema: AnyObject) =>
  funder?.value === AFDAS_FUNDER
    ? schema["typeError"]("Une valeur est nécessaire").min(1, "minimum 1 mois").max(20, "maximum 20 mois").required(REQUIRED_FIELD)
    : schema["nullable"](true);

export const schema = object().shape({
  obtentionDate: string().max(255).required(REQUIRED_FIELD),
  internshipEndDate: string().max(255).required(REQUIRED_FIELD),
  student: string().max(255).required(REQUIRED_FIELD),
  funder: object().shape({
    label: string().max(255).required(REQUIRED_FIELD),
    value: string().max(255).required(REQUIRED_FIELD),
  }),
  allocatedAmount: number().typeError("Une valeur est nécessaire").min(1, "minimum 1€").max(4000, "maximum 4000€").required(REQUIRED_FIELD),
  operaOffCommission: number()
    .typeError("Une valeur est nécessaire")
    .min(0, "minimum 0€")
    .max(4000, "maximum 4000€")
    .required(REQUIRED_FIELD),
  expirationTimeInMonths: number().when("funder", requiredWhenAFDAS),
  commentary: string().max(240),
  externalFundingRequestId: string().max(20).nullable().when("funder", requiredWhenAFDAS),
  fundingRequestId: string().max(255),
  transmitNotificationToAdmin: boolean().required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (budget: FormInputs): CreateBudgetBody => {
  return {
    obtentionDate: budget.obtentionDate,
    internshipEndDate: budget.internshipEndDate,
    studentId: budget.student,
    funder: budget.funder.value,
    allocatedAmount: budget.allocatedAmount,
    operaOffCommission: budget.operaOffCommission,
    expirationTimeInMonths: budget.funder.value === AFDAS_FUNDER ? budget.expirationTimeInMonths : null,
    commentary: budget.commentary || null,
    externalFundingRequestId: budget.externalFundingRequestId || null,
    fundingRequestId: budget.fundingRequestId || null,
    transmitNotificationToAdmin: budget.transmitNotificationToAdmin,
  };
};
