import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "src/redux/store";

export const uploadInvoice = createAsyncThunk<string, { uploadId: string; invoice: File }, { extra: Partial<Dependencies> }>(
  "invoices/uploadInvoice",
  async ({ invoice }: { invoice: File }, { extra: { refundRequestGateway } }) => {
    return refundRequestGateway!.uploadInvoice(invoice);
  }
);

export const resetUploadInvoice = createAction("invoices/resetUploadInvoice");
export const resetUploadInvoiceForId = createAction<string>("invoices/resetUploadInvoiceForId");
