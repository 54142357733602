import { createReducer } from "@reduxjs/toolkit";
import { ReviewedFundingRequestsRetrievalState } from "../../../../../../../redux/app-state";
import { retrieveReviewedFundingRequests } from "./retrieve-reviewed-funding-requests";

const initialState: ReviewedFundingRequestsRetrievalState = {
  fetching: "idle",
  data: [],
};

export const retrieveReviewedFundingRequestsReducer = createReducer<ReviewedFundingRequestsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveReviewedFundingRequests.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveReviewedFundingRequests.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveReviewedFundingRequests.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
