import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveProviderPublicProfile } from "./retrieve-provider-public-profile";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<Provider>> = { fetching: "idle", data: null };

export const retrieveProviderPublicProfileReducer = createReducer<RetrievalState<Nullable<Provider>>>(initialState, builder => {
  builder.addCase(retrieveProviderPublicProfile.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveProviderPublicProfile.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveProviderPublicProfile.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
