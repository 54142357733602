import { createReducer } from "@reduxjs/toolkit";
import { BioUploadState } from "@redux/app-state";
import { resetUploadBio, uploadBio } from "./upload-bio";

const initialState: BioUploadState = { processing: "idle", bioUrl: null };

export const uploadBioReducer = createReducer<BioUploadState>(initialState, builder => {
  builder.addCase(uploadBio.fulfilled, (state, { payload }) => {
    return {
      ...state,
      bioUrl: payload,
      processing: "success",
    };
  });
  builder.addCase(uploadBio.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(uploadBio.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUploadBio, () => {
    return initialState;
  });
});
