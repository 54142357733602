import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectConventionsRetrievalForList } from "../../../read/application/use-cases/admin/conventions-retrieval/selectors/conventions-list-selectors";
import { useNavigate } from "react-router-dom";
import { retrieveConventions } from "../../../read/application/use-cases/admin/conventions-retrieval/retrieve-conventions";
import { AdminConventionListVM } from "../../../read/domain/types/admin/convention";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { CancelConventionModal } from "./cancel-convention-modal";
import { useConventionCancellation } from "./use-convention-cancelled";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@shared-kernel/primary/shared/shadcn/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { EndConventionModal } from "./end-convention-modal";
import { useConventionEnding } from "./use-convention-ended";
import { computeConventionStatus } from "@academy-context/read/domain/services/convention";

const columns: ColumnDef<AdminConventionListVM>[] = [
  {
    accessorKey: "prematureEndDate",
    header: () => "Statut",
    meta: {
      title: "Statut",
    },
    cell: ({ row }) => {
      const { prematureEndDate, hoursLeft } = row.original;
      return computeConventionStatus(prematureEndDate, hoursLeft);
    },
    size: 40,
  },
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    size: 90,
    enableSorting: true,
    meta: {
      title: "Enregistré le",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: info => formatDateToLocale(info.row.original.date),
    size: 90,
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "hours",
    header: () => "Heures",
    size: 40,
    meta: {
      title: "Heures",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire",
    cell: info => formatToCurrency(info.row.original.hourlyPrice),
    size: 40,
    meta: {
      title: "Tarif horaire",
    },
  },
  {
    accessorKey: "hoursLeft",
    header: () => "Heures restantes",
    size: 40,
    meta: {
      title: "Heures restantes",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    meta: {
      title: "Commentaire",
    },
  },
];

export const ConventionsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: conventions } = useAppSelector(selectConventionsRetrievalForList);
  const [dataColumns, setDataColumns] = useState<ColumnDef<AdminConventionListVM>[]>([...columns]);
  const currentSelectedLineInfo = useRef<Nullable<AdminConventionListVM>>(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isEndedModalOpen, setIsEndedModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(retrieveConventions());
  }, [dispatch]);

  const onNewConvention = () => {
    navigate("/conventions");
  };

  useEffect(() => {
    const cancelColumn: ColumnDef<AdminConventionListVM> = {
      accessorKey: "id",
      header: () => "Actions",
      size: 90,
      enableSorting: false,
      meta: {
        title: "Actions",
      },
      cell: ({ row }) => {
        const isRunningConvention = row.original.hoursLeft > 0;
        const hasBeenEnded = Boolean(row.original.prematureEndDate);
        if (!isRunningConvention || hasBeenEnded) {
          return <></>;
        } else
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsVerticalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => {
                    setIsCancelModalOpen(true);
                    currentSelectedLineInfo.current = row.original;
                  }}
                >
                  Supprimer convention
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => {
                    setIsEndedModalOpen(true);
                    currentSelectedLineInfo.current = row.original;
                  }}
                >
                  Terminer prématurément
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
      },
    };
    const [statusColumn, ...rest] = columns;
    setDataColumns([...rest, cancelColumn, statusColumn!]);
  }, [conventions]);

  useConventionCancellation();
  useConventionEnding();

  return (
    <CustomCard title="Convention" headerChildren={<Button onClick={onNewConvention}>Nouvelle convention</Button>}>
      {currentSelectedLineInfo.current && (
        <>
          <CancelConventionModal
            convention={currentSelectedLineInfo.current}
            isOpen={isCancelModalOpen}
            onClose={() => setIsCancelModalOpen(false)}
          />
          <EndConventionModal
            convention={currentSelectedLineInfo.current}
            isOpen={isEndedModalOpen}
            onClose={() => setIsEndedModalOpen(false)}
          />
        </>
      )}
      <DataTable
        columns={dataColumns}
        data={conventions}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher une convention"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
