import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminTrainingVM } from "../../../../domain/types/training";

export const adminRetrieveTraining = createAsyncThunk<AdminTrainingVM, string, { extra: Partial<Dependencies> }>(
  "trainings/adminRetrieveTraining",
  async (id: string, { extra: { trainingGateway } }) => {
    return trainingGateway!.adminGetOne(id);
  }
);
