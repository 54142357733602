export enum PROJECT_OBJECTIVE {
  TRAVAIL_DE_TECHNIQUE_VOCALE = "TRAVAIL DE TECHNIQUE VOCALE",
  TRAVAIL_SUR_LA_PRÉPARATION_DE_ROLE_PRÉVU_À_MON_AGENDA = "TRAVAIL SUR LA PRÉPARATION DE ROLE PRÉVU À MON AGENDA",
  TRAVAIL_DE_PRÉPARATION_AUX_AUDITIONS_ET_CONCOURS = "TRAVAIL DE PRÉPARATION AUX AUDITIONS ET CONCOURS",
  TRAVAIL_DE_CONSTITUTION_D_UN_RÉPERTOIRE_D_AUDITION = "TRAVAIL DE CONSTITUTION D'UN RÉPERTOIRE D'AUDITION",
  TRAVAIL_DE_PRÉPARATION_D_ENREGISTREMENT_VIDEO = "TRAVAIL DE PRÉPARATION D'ENREGISTREMENT VIDEO",
  TRAVAIL_DE_CONSTITUTION_DE_NOUVEAUX_RÉPERTOIRES_ROLES = "TRAVAIL DE CONSTITUTION DE NOUVEAUX RÉPERTOIRES/ROLES",
  FORMATION_ET_TRAINING_DOUBLAGE_ET_VOIX_OFF = "FORMATION ET TRAINING DOUBLAGE ET VOIX OFF",
  RECHERCHE_D_AGENT = "RECHERCHE D'AGENT",
}
