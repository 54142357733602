import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherConventionListVM } from "../../../../domain/types/teacher/convention";

export const retrieveConventions = createAsyncThunk<TeacherConventionListVM[], void, { extra: Partial<Dependencies> }>(
  "conventions/retrieveTeacherConventions",
  async (_: void, { extra: { teacherConventionGateway } }) => {
    return teacherConventionGateway!.all();
  }
);
