import { createReducer } from "@reduxjs/toolkit";
import { ProfilePictureUploadState } from "@redux/app-state";
import { resetUploadProfilePicture, uploadProfilePicture } from "./upload-profile-picture";

const initialState: ProfilePictureUploadState = { processing: "idle", profilePictureUrl: null };

export const uploadProfilePictureReducer = createReducer<ProfilePictureUploadState>(initialState, builder => {
  builder.addCase(uploadProfilePicture.fulfilled, (state, { payload }) => {
    return {
      ...state,
      profilePictureUrl: payload,
      processing: "success",
    };
  });
  builder.addCase(uploadProfilePicture.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(uploadProfilePicture.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUploadProfilePicture, () => {
    return initialState;
  });
});
