import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { FormInputs, schema, defaultValues } from "./form-validation/user-password";
import { UserPassword } from "../../../../write/domain/types/user";
import { PasswordInput } from "../../../shared/password-input/password-input.components";
import { Form } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";

interface Props {
  title: string;
  onSubmit: (body: UserPassword) => void;
  useFunction: () => void;
}

export const PasswordForm = ({ title, onSubmit, useFunction }: Props) => {
  let [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const form = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    formState: { isDirty },
  } = form;

  const handleOnSubmit = (formBody: FormInputs) => {
    if (userId && token) {
      const body = { ...formBody, userId, token };
      onSubmit(body);
    }
  };

  useFunction();

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
        <div className="mt-3">
          <span>{title}</span>
          <br />
          <br />
          <span>{email}</span>
        </div>
        <PasswordInput control={form.control} password={watch("password")} />
        <div className="col-span-2 flex justify-end">
          <Button type="submit" disabled={!isDirty} className="ml-2">
            Sauvegarder
          </Button>
        </div>
      </form>
    </Form>
  );
};
