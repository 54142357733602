import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";
import { resetUpdateProviderProfile } from "@user-management-context/write/application/use-cases/provider/provider-profile-update/update-provider-profile-";
import { retrieveProviderProfile } from "@user-management-context/read/application/use-cases/provider/provider-profile-retrieval/retrieve-provider";

const selectProviderUpdate = (state: AppState) => state.providerProfileUpdate;

export const useProviderProfileUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useSelector(selectProviderUpdate);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Mise à jour effectuée avec succès!" });
      dispatch(resetUpdateProviderProfile());
      dispatch(retrieveProviderProfile());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour", variant: "destructive" });
    }
  }, [processing, dispatch, toast]);
};
