import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@components/ui/form";
import {
  ProviderStudentInvitationFormInputs,
  providerStudentInvitationSchema,
  providerStudentInvitationDefaultValues,
  formatFormDataToBodyData,
} from "@user-management-context/primary/provider/student-invitation-list/form-validation/provider-student-invitation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PhoneInput } from "@user-management-context/primary/shared/phone-input/phone-input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import {
  resetRetrieveProviderStudentByEmail,
  retrieveProviderStudentByEmail,
} from "@user-management-context/read/application/use-cases/provider/student-retrieval-by-email/retrieve-student-by-email";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { ProviderStudentInternshipInvitationBody } from "@user-management-context/shared/application/ports/provider-repository";
import {
  isEmailUsed,
  resetIsEmailUsed,
} from "@user-management-context/read/application/use-cases/admin/user-email-existence/is-email-used";
import { cn } from "@components/utils/utils";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  trainingOptions: ComboboxOptions[];
  onSubmit: (body: ProviderStudentInternshipInvitationBody) => void;
}

export const InviteProviderStudentModal = ({ isOpen, onClose, trainingOptions, onSubmit }: Props) => {
  // TODO: Eviter de l'utiliser avec les selectors
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.providerStudentRetrievalByEmail);
  const { doesExist } = useAppSelector((state: AppState) => state.userEmailExistence);
  const form = useForm<ProviderStudentInvitationFormInputs>({
    resolver: yupResolver(providerStudentInvitationSchema),
    defaultValues: providerStudentInvitationDefaultValues,
  });
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    formState: { errors },
    clearErrors,
  } = form;
  const [doesStudentAlreadyExist, setDoesStudentAlreadyExist] = useState(false);
  const retrieveStudent = useCallback((email: string) => dispatch(retrieveProviderStudentByEmail(email)), [dispatch]);

  const resetForm = useCallback(() => {
    reset();
    dispatch(resetRetrieveProviderStudentByEmail());
    dispatch(resetIsEmailUsed());
  }, [dispatch, reset]);

  const handleOnSubmit = async (formBody: ProviderStudentInvitationFormInputs) => {
    onClose();
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
    resetForm();
  };

  // Open / Close the modal
  useEffect(() => {
    resetForm();
  }, [isOpen, resetForm]);

  const phone = watch("mobile");
  const email = watch("email");
  const today = new Date().toISOString();

  // This part is used to check if student account is already registered or email is already used
  useEffect(() => {
    if (email) {
      dispatch(isEmailUsed(email));
      retrieveStudent(email);
    }
  }, [dispatch, email, retrieveStudent]);

  useEffect(() => {
    const isExistingStudent = data;
    const isOtherRoleEmail = !isExistingStudent && doesExist;
    setValue("id", null);
    if (isOtherRoleEmail) {
      setValue("isValidEmail", false);
      setError("isValidEmail", { message: "Cet email est déjà utilisé par un autre type d'utilisateur", type: "custom" });
    } else {
      setValue("isValidEmail", true);
      clearErrors("isValidEmail");
      if (isExistingStudent) {
        setValue("id", data.id);
        setValue("name", data.name);
        setValue("lastName", data.lastName);
        setValue("mobile", data.mobile);
        setDoesStudentAlreadyExist(true);
      } else {
        setDoesStudentAlreadyExist(false);
      }
    }
  }, [clearErrors, data, doesExist, setError, setValue]);

  const trainingId = watch("trainingId");

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Pré-inscription nouvel Eleve</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid gap-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="email" className="text-right">
                  Email
                </Label>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="email" {...field} />
                      </FormControl>
                      <FormMessage />
                      {errors.isValidEmail?.message && (
                        <p className={cn("text-sm font-medium text-destructive")}>{errors.isValidEmail?.message}</p>
                      )}
                    </FormItem>
                  )}
                />
              </div>
              {doesStudentAlreadyExist && (
                <span className="text-green-400 text-sm">Cet élève existe déjà. Les champs ont été remplis automatiquement.</span>
              )}
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Prénom
                </Label>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled={doesStudentAlreadyExist} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="lastName" className="text-right">
                  Nom
                </Label>
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Input type="text" {...field} disabled={doesStudentAlreadyExist} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="trainingTitle" className="text-right">
                  Téléphone
                </Label>
                <FormField
                  control={form.control}
                  name="mobile"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <PhoneInput
                          phone={phone}
                          onChange={value => {
                            setValue("mobile", value as string, { shouldDirty: true });
                          }}
                          disabled={doesStudentAlreadyExist}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="trainingTitle" className="text-right">
                  Formation demandée
                </Label>
                <FormField
                  control={form.control}
                  name="trainingId"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <FormControl>
                        <Combobox
                          options={trainingOptions}
                          value={trainingId}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner formation..."
                          search={{
                            notFoundText: "Pas de formation trouvée.",
                            commandInputPlaceHolder: "Chercher formation...",
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="internshipIntervalStart" className="text-right">
                  Date de début de la formation
                </Label>
                <FormField
                  control={form.control}
                  name="internshipInterval.start"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-col col-span-3">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="internshipIntervalEnd" className="text-right">
                  Date de fin de la formation
                </Label>
                <FormField
                  control={form.control}
                  name="internshipInterval.end"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-col col-span-3">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <DialogFooter className="mt-4">
              <Button onClick={onClose} variant="outline">
                Annuler
              </Button>
              <Button type="submit">Pré-inscrire élève</Button>
            </DialogFooter>
          </form>
        </Form>
        {/* <div className="space-y-4">
          <div className="grid grid-cols-7 items-center gap-4">
            <div className="col-span-2">
              <Label htmlFor="student" className="text-right">
                Email de l'élève à ajouter :
              </Label>
            </div>
            <div className="col-span-3">
              <Input type="text" onChange={e => setEmail(e.target.value)} value={email} disabled={hasAddedStudent} />
            </div>
            <div className="col-span-2">{message}</div>
          </div>
          {hasAddedStudent && (
            <div className="flex justify-center">
              <Button type="submit" onClick={requestNewCollaboration}>
                Rechercher un autre élève
              </Button>
            </div>
          )}
        </div> */}
      </DialogContent>
    </Dialog>
  );
};
