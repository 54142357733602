import { createReducer } from "@reduxjs/toolkit";
import { resetAcceptProviderStudentInvitation, acceptProviderStudentInvitation } from "./accept-provider-student-invitation";
import { UpdateState } from "src/redux/app-state";

const initialState: UpdateState = { justUpdatedId: null, processing: "idle" };

export const acceptProviderStudentInvitationReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(acceptProviderStudentInvitation.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.id,
      processing: "success",
    };
  });
  builder.addCase(acceptProviderStudentInvitation.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(acceptProviderStudentInvitation.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetAcceptProviderStudentInvitation, () => {
    return initialState;
  });
});
