import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentLesson } from "../../../../domain/types/student/lesson";

export const studentRetrieveLessons = createAsyncThunk<StudentLesson[], void, { extra: Partial<Dependencies> }>(
  "lessons/studentRetrieveLessons",
  async (_: void, { extra: { studentLessonGateway } }) => {
    return studentLessonGateway!.all();
  }
);
