import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { BackButton } from "../../../../shared-kernel/primary/shared/back-button/back-button";
import { AppState } from "@redux/app-state";
import { teacherRetrieveTraining } from "../../../read/application/use-cases/teacher/training-retrieval/retrieve-training";
import { DownloadableElement } from "../../shared/training/downloadable-element";
import { LinkTrainingElement } from "../../shared/training/link-element";
import { TrainingDetailsHeader } from "../../shared/training/header";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";

export const TeacherTrainingDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: training } = useAppSelector((state: AppState) => state.teacherTrainingRetrieval);

  useEffect(() => {
    if (id) dispatch(teacherRetrieveTraining(id));
  }, [dispatch, id]);

  if (!training) return <></>;

  return (
    <div className="flex justify-center">
      <div className="w-[90%]">
        <BackButton link="/trainings" label="Suivi QUALIOPI de vos élèves" />
        <TrainingDetailsHeader title={`${training.title} | ${training.student}`} date={training.conventionDate} />
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Emargements</h2>
            <span className="ml-3 leading-8">(cliquer sur la zone pour ouvrir la page et le module d’émargement)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement title="Emargements" status={training.attendanceSheetsStatus} link={`/trainings/${id}/sessions`} />
        </div>
        <div className="mt-5">
          <div className="flex items-baseline">
            <h2 className="text-2xl font-semibold text-theme">Projet pédagogique</h2>
            <span className="ml-3 leading-8">(Apparait lorsque la convention est finie ou 3 mois après la date de fin du stage)</span>
          </div>
          <Separator className="mt-2" />
          <LinkTrainingElement
            title="Projet pédagogique"
            status={training.summaryStatus}
            link={["available", "done"].includes(training.summaryStatus) ? `/trainings/${id}/objectives` : undefined}
          />
        </div>
        {training.convention.signed ? (
          <div className="mt-5">
            <div className="flex items-baseline">
              <h2 className="text-2xl font-semibold text-theme">Documents à télécharger</h2>
            </div>
            <Separator className="mt-2" />
            <DownloadableElement title="Convention signée" url={training.convention.url} />
          </div>
        ) : (
          <div className="mt-5">
            <div className="flex items-baseline">
              <h2 className="text-2xl font-semibold text-theme">
                Document(s) à signer et à retourner à cette adresse mail :{" "}
                <a href="mailto:gestion.operaoff@gmail.com" target="_blank" rel="noreferrer" className="text-theme">
                  gestion.operaoff@gmail.com
                </a>
              </h2>
            </div>
            <Separator className="mt-2" />
            <DownloadableElement title="Convention à signer" url={training.convention.url} action={true} />
          </div>
        )}
      </div>
    </div>
  );
};
