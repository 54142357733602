import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingSession } from "../../../../domain/types/training";

export const retrieveTrainingSessions = createAsyncThunk<TrainingSession[], string, { extra: Partial<Dependencies> }>(
  "trainingSessions/retrieveTrainingSessions",
  async (trainingId: string, { extra: { trainingGateway } }) => {
    return trainingGateway!.getTrainingSessions(trainingId);
  }
);
