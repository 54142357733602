import { createReducer } from "@reduxjs/toolkit";
import { AdminTrainingRetrievalState } from "@redux/app-state";
import { adminRetrieveTraining } from "./retrieve-training";
import { deleteSignedConvention } from "../../../../../write/application/use-cases/admin/signed-convention-deletion/delete-signed-convention";

const initialState: AdminTrainingRetrievalState = { fetching: "idle", data: null };

export const adminRetrieveTrainingReducer = createReducer<AdminTrainingRetrievalState>(initialState, builder => {
  builder.addCase(adminRetrieveTraining.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveTraining.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveTraining.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(deleteSignedConvention.fulfilled, state => {
    if (state.data)
      return {
        ...state,
        data: {
          ...state.data,
          teacher: {
            ...state.data.teacher,
            convention: {
              ...state.data.teacher.convention,
              signedUrl: null,
            },
          },
        },
      };

    return { ...state };
  });
});
