import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectBudgetsRetrievalForList } from "../../../read/application/use-cases/admin/budgets-retrieval/selectors/budgets-list-selectors";
import { useNavigate } from "react-router-dom";
import { retrieveBudgets } from "../../../read/application/use-cases/admin/budgets-retrieval/retrieve-budgets";
import { ColumnDef } from "@tanstack/react-table";
import { AdminBudgetListVM } from "../../../read/domain/types/admin/budget";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";

const columns: ColumnDef<AdminBudgetListVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    size: 90,
    enableSorting: true,
    meta: {
      title: "Enregistré le",
    },
  },
  {
    accessorKey: "internshipEndDate",
    header: () => "Fin de stage",
    cell: info => formatDateToLocale(info.row.original.internshipEndDate),
    size: 90,
    enableSorting: true,
    meta: {
      title: "Fin de stage",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "funder",
    header: () => "Financeur",
    size: 100,
    meta: {
      title: "Financeur",
    },
  },
  {
    accessorKey: "allocatedAmount",
    header: () => "Montant total",
    cell: info => formatToCurrency(info.row.original.allocatedAmount),
    size: 100,
    meta: {
      title: "Montant total",
    },
  },
  {
    accessorKey: "operaOffCommission",
    header: () => "OPERA Off",
    cell: info => formatToCurrency(info.row.original.operaOffCommission),
    size: 100,
    meta: {
      title: "OPERA Off",
    },
  },
  {
    accessorKey: "totalAmount",
    header: () => "Total crédité",
    cell: info => formatToCurrency(info.row.original.totalAmount),
    size: 100,
    meta: {
      title: "Total crédité",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    meta: {
      title: "Commentaire",
    },
  },
  {
    accessorKey: "externalFundingRequestId",
    header: () => "Module",
    size: 100,
    meta: {
      title: "Module",
    },
  },
];

export const BudgetsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: budgets } = useAppSelector(selectBudgetsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveBudgets());
  }, [dispatch]);

  const onNewBudget = () => {
    navigate("/budgets");
  };

  return (
    <CustomCard title="Budgets" headerChildren={<Button onClick={onNewBudget}>Nouveau budget</Button>}>
      <DataTable
        columns={columns}
        data={budgets}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un budget"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
