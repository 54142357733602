import { Nullable } from "../../../../../../../../shared-kernel/core/types/nullable";
import { number, object, string } from "yup";
import { StandardPayment, ConventionPaymentVM } from "../../../../../../../read/domain/types/admin/payment";
import { UpdatePaymentBody } from "../../../../../../../write/domain/types/admin/payment";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";

export type FormInputs = {
  reference: string;
  status: PaymentStatus;
  paymentDate: Nullable<string>;
  amountPaid: number | undefined;
};

export const defaultValues = {
  reference: "",
  status: PaymentStatus.PENDING,
  paymentDate: new Date().toISOString(),
  amountPaid: undefined,
};

export const schema = object().shape({
  reference: string().max(255),
  status: string().required(REQUIRED_FIELD).oneOf(Object.values(PaymentStatus)),
  paymentDate: string().max(255).nullable(),
  amountPaid: number().typeError("Minimum 0 €").min(0, "Minimum 0 €").nullable(),
});

export const formatTransferDataToFormData = (transfer: StandardPayment | ConventionPaymentVM): FormInputs => {
  const { paymentDate, reference, status, amountPaid, amount } = transfer;
  let formattedAmountPaid = undefined;

  if (status === "PAID") {
    formattedAmountPaid = amountPaid ?? undefined;
  } else {
    formattedAmountPaid = amount;
  }

  return {
    ...defaultValues,
    paymentDate,
    reference: reference ?? "",
    status,
    amountPaid: formattedAmountPaid,
  };
};

export const formatFormDataToBodyData = (transfer: FormInputs): Omit<UpdatePaymentBody, "type"> => {
  const { amountPaid, reference, paymentDate, status } = transfer;
  if (status === PaymentStatus.PAID) {
    return {
      reference: reference || null,
      paymentDate: new Date(paymentDate!).toISOString(),
      status: PaymentStatus.PAID,
      amountPaid: amountPaid ?? null,
    };
  }
  return {
    reference: null,
    paymentDate: null,
    status: transfer.status,
    amountPaid: null,
  };
};
