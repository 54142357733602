const config = {
  local: { url: "http://localhost:8010" },
  dev: { url: "https://dev-opera-off.netlify.app" },
  production: { url: "https://connect.operaoff.fr" },
  test: { url: "test.com" },
  demo: { url: "http://localhost:8010" },
};

if (!import.meta.env["VITE_SERVICE_ENV"]) {
  throw new Error("VITE_SERVICE_ENV is undefined.");
}

type ENV = "local" | "production" | "test" | "dev" | "demo";
export default config[import.meta.env["VITE_SERVICE_ENV"] as ENV];
