import { createReducer } from "@reduxjs/toolkit";
import { retrieveApprovedFundingRequests } from "./retrieve-approved-funding-requests";
import { ApprovedFundingRequestsRetrievalState } from "../../../../../../../redux/app-state";

const initialState: ApprovedFundingRequestsRetrievalState = {
  fetching: "idle",
  data: [],
};

export const retrieveApprovedFundingRequestsReducer = createReducer<ApprovedFundingRequestsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveApprovedFundingRequests.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveApprovedFundingRequests.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveApprovedFundingRequests.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
