import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";

export const retrieveProviderProfile = createAsyncThunk<Provider, void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderProfile",
  async (_: void, { extra: { providerRepository } }) => {
    return providerRepository!.profile();
  }
);
