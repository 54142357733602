import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Admin } from "@user-management-context/read/domain/types/admin/admin";

export const retrieveAdminPublicProfile = createAsyncThunk<Admin, string, { extra: Partial<Dependencies> }>(
  "admins/retrieveAdminPublicProfile",
  async (userId: string, { extra: { userGateway } }) => {
    return userGateway!.publicAdminProfile(userId);
  }
);
