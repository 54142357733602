import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

export const adminDeleteProviderTraining = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "providerTrainings/deleteProviderTraining",
  async (trainingId: string, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.deleteTraining(trainingId);
  }
);

export const adminResetDeleteProviderTraining = createAction("providerTrainings/resetDeleteProviderTraining");
