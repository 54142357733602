import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateStudentAccountBody } from "@user-management-context/write/domain/types/admin/student";

export const createStudentAccount = createAsyncThunk<void, CreateStudentAccountBody, { extra: Partial<Dependencies> }>(
  "users/createStudentAccount",
  async (body: CreateStudentAccountBody, { extra: { userGateway } }) => {
    return userGateway!.createStudentAccount(body);
  }
);
