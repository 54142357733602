import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ConfirmInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { Dependencies } from "src/redux/store";

export const confirmProviderInternship = createAsyncThunk<void, ConfirmInternshipBody, { extra: Partial<Dependencies> }>(
  "providerInternships/confirmProviderInternship",
  async (body: ConfirmInternshipBody, { extra: { providerRepository } }) => {
    return providerRepository!.confirmInternship(body);
  }
);

export const resetConfirmProviderInternship = createAction("providerInternships/resetConfirmProviderInternship");
