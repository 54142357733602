import { createReducer } from "@reduxjs/toolkit";
import { resetUserPassword } from "./reset-user-password";
import { ResetUserPasswordState } from "@redux/app-state";

const initialState: ResetUserPasswordState = { fetching: "idle" };

export const resetUserPasswordReducer = createReducer<ResetUserPasswordState>(initialState, builder => {
  builder.addCase(resetUserPassword.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(resetUserPassword.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(resetUserPassword.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
