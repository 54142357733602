import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { ProviderInternshipListSkeleton } from "@academy-context/primary/shared/provider-internship/provider-internship-list.skeleton";
import { adminRetrieveProviderInternships } from "@academy-context/read/application/use-cases/admin/provider-internships-retrieval/retrieve-provider-internships";
import { adminSelectProviderInternshipsRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-internships-retrieval/selectors/provider-internship-list-selectors";
import { adminRetrieveProviderTrainings } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { adminSelectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import {
  AttachInvoiceBody,
  ConfirmInternshipBody,
  UpdateInternshipBody,
} from "@user-management-context/shared/application/ports/admin-provider-repository";
import {
  adminConfirmProviderInternship,
  adminResetConfirmProviderInternship,
} from "@academy-context/write/application/use-cases/admin/provider-internship-confirmation/confirm-provider-internship";
import { ProviderInternshipListComponent } from "@academy-context/primary/shared/provider-internship/provider-internship-list.components";
import {
  adminResetUpdateProviderInternship,
  adminUpdateProviderInternship,
} from "@academy-context/write/application/use-cases/admin/provider-internship-update/update-provider-internship";
import { useProviderInternshipUpdated } from "@academy-context/primary/admin/provider-internship/use-internship-updated";

export const AdminProviderInternshipListContainer = () => {
  const dispatch = useAppDispatch();
  const { isLoading, internships } = useAppSelector(adminSelectProviderInternshipsRetrievalForList);
  const { trainings } = useAppSelector(adminSelectProviderTrainingsRetrievalForList);
  const [selectedProviderId, setSelectedProviderId] = useState("");

  useEffect(() => {
    dispatch(adminRetrieveProviderInternships());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(adminResetConfirmProviderInternship());
      dispatch(adminResetUpdateProviderInternship());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedProviderId) dispatch(adminRetrieveProviderTrainings(selectedProviderId));
  }, [dispatch, selectedProviderId]);

  const onConfirmInternship = (body: ConfirmInternshipBody) => {
    dispatch(adminConfirmProviderInternship(body));
  };

  const onUpdateInternship = (body: UpdateInternshipBody) => {
    dispatch(adminUpdateProviderInternship(body));
  };

  // const onCancelInternship = (id: string) => {
  //   console.log("🚀 ~ onCancelInternship ~ id:", id);
  //   // TODO: this
  //   // dispatch(onCancelInternship(id));
  // };

  const onAttachInvoice = (body: AttachInvoiceBody) => {
    console.log("🚀 ~ ProviderInternshipListContainer ~ body:", body);
    // TODO
    // dispatch(attachInvoice())
  };

  useProviderInternshipUpdated();

  return (
    <CustomCard title="Dossiers Elèves Prestataire">
      {isLoading ? (
        <ProviderInternshipListSkeleton />
      ) : (
        <ProviderInternshipListComponent
          providerInternships={internships}
          setSelectedProviderId={setSelectedProviderId}
          providerTrainings={trainings}
          onConfirmInternship={onConfirmInternship}
          onUpdateInternship={onUpdateInternship}
          onAttachInvoice={onAttachInvoice}
        />
      )}
    </CustomCard>
  );
};
