import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CollaborationRequest } from "@academy-context/read/domain/types/admin/collaboration-request";

export const retrieveCollaborationRequestList = createAsyncThunk<CollaborationRequest[], void, { extra: Partial<Dependencies> }>(
  "collaborationRequests/retrieveCollaborationRequestList",
  async (_: void, { extra: { collaborationRequestGateway } }) => {
    return collaborationRequestGateway!.list();
  }
);
