import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetCreateStudent } from "../../../../user-management-context/write/application/use-cases/admin/admin-student-creation/create-student";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";

const selectStudentCreate = (state: AppState) => state.studentCreate;

export const useStudentCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedStudentId, fetching } = useAppSelector(selectStudentCreate);

  useEffect(() => {
    if (justCreatedStudentId) {
      navigate(`/students/${justCreatedStudentId}`);
      toast({ description: "Nouvel élève crée!" });
      dispatch(resetCreateStudent());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la création de l'élève", variant: "destructive" });
    }
  }, [justCreatedStudentId, dispatch, fetching, navigate, toast]);
};
