import React from "react";
import { Skeleton } from "@shared-kernel/primary/shared/shadcn/ui/skeleton";

export const InvitationsListSkeleton = () => {
  return (
    <>
      <Skeleton className="h-[40px] mt-5" />
      <Skeleton className="h-[220.5px] mt-2" />
    </>
  );
};
