import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

export const USER_TIMEZONE = "Europe/Paris";

export interface User {
  id: string;
  createdAt: string;
  name: string;
  lastName: string;
  email: string;
  role: ROLES;
}

export type UserWithAccessToken = User & { accessToken: string };

export type UserWithPassword = User & { password: string };
