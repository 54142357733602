import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TokenStatus } from "../../../../shared/domain/types/enums/token";

export const verifyTokenValidity = createAsyncThunk<
  { status: TokenStatus },
  { userId: string; token: string },
  { extra: Partial<Dependencies> }
>("users/verifyTokenValidity", async ({ userId, token }: { userId: string; token: string }, { extra: { userGateway } }) => {
  return userGateway!.verifyTokenValidity(userId, token);
});
