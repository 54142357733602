import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const toggleTrainingDunningsStatus = createAsyncThunk<
  void,
  { id: string; action: "pause" | "resume" },
  { extra: Partial<Dependencies> }
>(
  "trainings/toggleTrainingDunningsStatus",
  async ({ id, action }: { id: string; action: "pause" | "resume" }, { extra: { trainingGateway } }) => {
    return trainingGateway!.toggleTrainingDunningProcessStatus({ trainingId: id, action });
  }
);
