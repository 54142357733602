import { createReducer } from "@reduxjs/toolkit";
import { AdminProfileRetrievalState } from "@redux/app-state";
import { retrieveAdminPublicProfile } from "./retrieve-admin-public-profile";

const initialState: AdminProfileRetrievalState = { fetching: "idle", data: null };

export const retrieveAdminPublicProfileReducer = createReducer<AdminProfileRetrievalState>(initialState, builder => {
  builder.addCase(retrieveAdminPublicProfile.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveAdminPublicProfile.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveAdminPublicProfile.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
