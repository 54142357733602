import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "@redux/app-state";
import { Outlet, Navigate } from "react-router-dom";

const PrivateOutlet = () => {
  const { data: user } = useSelector((state: AppState) => state.userLogin);

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateOutlet;
