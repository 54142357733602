import { Nullable } from "../../../../../shared-kernel/core/types/nullable";
import { FUNDING_REQUEST_STATUS } from "../enums/funding-request-status";
import { OPERA_OFF_TRAINING } from "../../../../shared/domain/types/enums/funding-request-trainings";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";

interface FundingRequestBase {
  id: string;
  student: {
    id: string;
    name: string;
    phone: string;
  };
  educationalAdvisor: {
    id: string;
    type: EDUCATIONAL_ADVISOR;
    name: string;
  };
  funder: string;
  error: Nullable<FUNDING_REQUEST_ERROR_REASONS>;
  duplicateOriginId: Nullable<string>;
}

export type ToReviewFundingRequestVM = FundingRequestBase & {
  status: "to_review";
  creationDate: string;
  reviewDate: Nullable<string>;
  submissionDate: Nullable<string>;
};

export type SubmittedToFunderFundingRequestVM = FundingRequestBase & {
  status: "submitted_to_funder";
  creationDate: string;
  reviewDate: string;
  submissionDate: string;
  training: { endDate: string; price: number };
};

export type ReviewedFundingRequestVM = FundingRequestBase & {
  id: number;
  status: "reviewed";
  creationDate: string;
  reviewDate: string;
  submissionDate: string;
};

export type ApprovedFundingRequestVM = FundingRequestBase & {
  id: number;
  status: "approved";
  creationDate: string;
  reviewDate: string;
  submissionDate: string;
};

export type FundingRequestsVM = (
  | ToReviewFundingRequestVM
  | SubmittedToFunderFundingRequestVM
  | ReviewedFundingRequestVM
  | ApprovedFundingRequestVM
)[];

export interface TrainingDays {
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  day7: string;
  day8: string;
  day9: string;
  day10: string;
}

export interface FundingRequestVM {
  student: { fullName: string; id: string };
  educationalAdvisor: {
    type: EDUCATIONAL_ADVISOR;
    id: string;
  };
  afdasId: string;
  afdasPassword: string;
  resumeUrl: Nullable<string>;
  bioUrl: Nullable<string>;
  coverLetter: string;
  personalizedEducationalProject: string;
  trainingName: Nullable<OPERA_OFF_TRAINING>;
  trainingPrice: Nullable<number>;
  trainingDays: Nullable<TrainingDays>;
  address: Nullable<string>;
  additionalAddress: Nullable<string>;
  reviewedCoverLetter: Nullable<string>;
  reviewedPersonalizedEducationalProject: Nullable<{ title: string; description: string }[]>;
  status: FUNDING_REQUEST_STATUS;
}

export enum FUNDING_REQUEST_ERROR_REASONS {
  PASSWORD = "password",
  WAITING_PERIOD = "waiting_period",
  OTHER = "other",
  AFDAS_REFUSAL = "afdas_refusal",
  NO_ERROR = "no_error",
}
