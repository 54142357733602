import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectUserInvitationsRetrievalForList } from "../../../../read/application/use-cases/admin/user-invitations-retrieval/selectors/user-invitation-list-selectors";
import { InvitationsListComponent } from "@user-management-context/primary/admin/user-invitations/list/invitation-list.components";
import { retrieveUserInvitations } from "@user-management-context/read/application/use-cases/admin/user-invitations-retrieval/retrieve-user-invitations";
import { InvitationsListSkeleton } from "@user-management-context/primary/admin/user-invitations/list/invitation-list.skeleton";

export const InvitationsListContainer = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(retrieveUserInvitations());
  }, [dispatch]);

  const { valid, expired, isLoading } = useAppSelector(selectUserInvitationsRetrievalForList);

  if (isLoading) return <InvitationsListSkeleton />;

  return <InvitationsListComponent valid={valid} expired={expired} />;
};
