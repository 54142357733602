import {
  PROVIDER_STUDENT_INVITATION_STATUS,
  ProviderInvitationListItem,
} from "@user-management-context/read/domain/types/provider/invitation";
import { providerId } from "src/test/utils/common";

const defaultProviderInvitation: ProviderInvitationListItem = {
  id: providerId,
  student: {
    email: "user@example.com",
    name: "Marc",
    lastName: "Siebert",
    mobile: "+33656352678",
    isAlreadyExisting: false,
  },
  status: PROVIDER_STUDENT_INVITATION_STATUS.REGISTERED,
  training: "TVi1",
  lastInternship: {
    interval: {
      start: "2022-03-05T10:00:00.000Z",
      end: "2022-04-05T10:00:00.000Z",
    },
  },
  creationDate: new Date(),
};

export const ProviderInvitationBuilder = (data: ProviderInvitationListItem = defaultProviderInvitation) => {
  return {
    withId: (id: ProviderInvitationListItem["id"]) => ProviderInvitationBuilder({ ...data, id }),
    withStudent: (student: ProviderInvitationListItem["student"]) => ProviderInvitationBuilder({ ...data, student }),
    withTraining: (training: ProviderInvitationListItem["training"]) => ProviderInvitationBuilder({ ...data, training }),
    withLastInternship: (lastInternship: ProviderInvitationListItem["lastInternship"]) =>
      ProviderInvitationBuilder({ ...data, lastInternship }),
    build: (): ProviderInvitationListItem => data,
  };
};
