import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { retrieveReviewedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/reviewed-funding-request-list-retrieval/retrieve-reviewed-funding-requests";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-afdas/retrieve-submitted-to-afdas-funding-requests";
import { AppState } from "src/redux/app-state";
import { retrieveToReviewFundingRequests } from "@academy-context/read/application/use-cases/admin/funding-request-list/to-review-funding-request-list-retrieval/retrieve-to-review-funding-requests";
import { AFDAS_FUNDER } from "@academy-context/primary/admin/funding-request-detail/form-validation/afdas-funding-request";

const selectFundingRequestSubmissionToAfdas = (state: AppState) => state.fundingRequestSubmissionToAfdas;
const selectFundingRequestErrorUpdate = (state: AppState) => state.fundingRequestErrorUpdate;
const selectFundingRequestCancelAndUpdate = (state: AppState) => state.fundingRequestCancelAndDuplicate;
const selectFundingRequestDeletion = (state: AppState) => state.fundingRequestDeletion;

export const useFundingRequestUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useAppSelector(selectFundingRequestSubmissionToAfdas);
  const { processing } = useAppSelector(selectFundingRequestErrorUpdate);
  const { processing: cancelAndUpdateProcessing } = useAppSelector(selectFundingRequestCancelAndUpdate);
  const { fetching: deleteProcessing } = useAppSelector(selectFundingRequestDeletion);

  useEffect(() => {
    if (fetching === "success" || processing === "success" || cancelAndUpdateProcessing === "success") {
      toast({ description: "Demande de financement mise à jour!" });
      dispatch(retrieveToReviewFundingRequests());
      dispatch(retrieveReviewedFundingRequests());
      dispatch(retrieveSubmittedToFunderFundingRequests({ funders: [AFDAS_FUNDER] }));
    } else if (fetching === "failed" || processing === "failed" || cancelAndUpdateProcessing === "failed") {
      toast({
        description: "Erreur lors de la mise à jour de la demande de financement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [cancelAndUpdateProcessing, dispatch, fetching, processing, toast]);

  useEffect(() => {
    if (deleteProcessing === "success") {
      toast({ description: "Demande de financement supprimée!" });
      dispatch(retrieveToReviewFundingRequests());
      dispatch(retrieveReviewedFundingRequests());
      dispatch(retrieveSubmittedToFunderFundingRequests({ funders: [AFDAS_FUNDER] }));
    } else if (deleteProcessing === "failed") {
      toast({
        description: "Erreur lors de la suppression de la demande de financement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [dispatch, deleteProcessing, toast]);
};
