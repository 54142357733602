import { TokenStorageGateway } from "../../../../../shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "../../../../../shared-kernel/secondary/http-gateway";
import config from "../../../../../config/backend";
import { AdminProviderStudentInvitation } from "@user-management-context/read/domain/types/admin/provider-student-invitation";
import {
  AdminProviderRepository,
  AdminCreateProviderTrainingBody,
  AcceptProviderStudentInvitationBody,
  AdminUpdateProviderTrainingBody,
  ConfirmInternshipBody,
  UpdateInternshipBody,
} from "@user-management-context/shared/application/ports/admin-provider-repository";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { Provider, ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { CreateProviderBody, UpdateProviderBody } from "@user-management-context/write/domain/types/admin/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";

export class AdminBackendProviderRepository extends HttpGateway implements AdminProviderRepository {
  private _route: string = "v1/admin/providers";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async trainingList(providerId: string): Promise<ProviderTraining[]> {
    const { data: trainings } = await this._instance.get(`${this._route}/${providerId}/trainings`);
    return trainings;
  }
  async acceptStudentInvitation(id: string, invitation: AcceptProviderStudentInvitationBody): Promise<void> {
    await this._instance.put(`${this._route}/invitations/${id}`, invitation);
  }
  async refuseStudentInvitation(id: string): Promise<void> {
    await this._instance.delete(`${this._route}/invitations/${id}`);
  }
  async createTraining(training: AdminCreateProviderTrainingBody): Promise<string> {
    return this._instance.post(`${this._route}/trainings`, training);
  }
  async updateTraining(training: AdminUpdateProviderTrainingBody): Promise<void> {
    await this._instance.put(`${this._route}/trainings/${training.trainingId}`, training);
  }

  async deleteTraining(trainingId: string): Promise<void> {
    await this._instance.delete(`${this._route}/trainings/${trainingId}`);
  }

  async studentInvitationList(): Promise<AdminProviderStudentInvitation[]> {
    const route = this._route + "/invitations";
    const { data: invitations } = await this._instance.get(route);
    return invitations;
  }

  async getById(id: string): Promise<Nullable<Provider>> {
    const { data: providers } = await this._instance.get(`${this._route}/${id}`);
    return providers;
  }

  async list(): Promise<ProviderListItem[]> {
    const { data: providers } = await this._instance.get(this._route);
    return providers;
  }

  async create(provider: CreateProviderBody): Promise<string> {
    const { data: providerId } = await this._instance.post(this._route, provider);
    return providerId;
  }

  async update(provider: UpdateProviderBody): Promise<void> {
    await this._instance.put(`${this._route}/${provider.id}`, provider);
  }

  async internshipList(): Promise<ProviderInternship[]> {
    const route = this._route + "/internships";
    const { data: internships } = await this._instance.get(route);
    return internships;
  }

  async confirmInternship(internship: ConfirmInternshipBody): Promise<void> {
    await this._instance.put(`${this._route}/internships/${internship.id}:confirm`, internship);
  }

  async updateInternship(internship: UpdateInternshipBody): Promise<void> {
    // await this._instance.put(`${this._route}/trainings/${training.trainingId}`, training);
    throw new Error("Method not implemented.");
  }
}
