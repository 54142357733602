import { Teacher } from "../../../../../domain/types/admin/teacher";
import { AppState } from "@redux/app-state";

export const selectTeacherProfileRetrieval = (state: AppState) => {
  const { data, fetching } = state.teacherProfileRetrieval;

  let teacher: Teacher = {} as Teacher;
  if (fetching === "success") {
    teacher = data!;
  }

  return teacher;
};
