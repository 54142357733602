import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Student } from "../../../../domain/types/admin/student";

export const retrieveStudent = createAsyncThunk<Student, string, { extra: Partial<Dependencies> }>(
  "students/retrieveStudent",
  async (id: string, { extra: { adminStudentGateway } }) => {
    return adminStudentGateway!.one(id);
  }
);

export const resetRetrieveStudent = createAction("students/resetRetrieveStudent");
