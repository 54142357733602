import React from "react";
import { ProviderForm } from "./provider-form.component";
import { CreateProviderBody } from "@user-management-context/write/domain/types/admin/provider";

interface Props {
  saveProvider: (provider: CreateProviderBody) => void;
  doesEmailExist: boolean;
  checkIfEmailUsed: (email: string) => void;
}

export const ProviderCreateComponent = ({ saveProvider, doesEmailExist, checkIfEmailUsed }: Props) => {
  return <ProviderForm onSubmit={saveProvider} doesEmailExist={doesEmailExist} checkIfEmailUsed={checkIfEmailUsed} provider={null} />;
};
