import React from "react";
import { StudentAdminDetail } from "./academy-context/primary/admin/student-detail/student-admin-detail.components";
import { StudentCreate } from "./academy-context/primary/admin/student-detail/student-create.components";
import { StudentsList } from "./academy-context/primary/admin/student-list/student-list.components";
import { TeacherCreate } from "./academy-context/primary/admin/teacher-detail/teacher-create.components";
import { TeacherAdminDetail } from "./academy-context/primary/admin/teacher-detail/teacher-admin-detail.components";
import { TeachersList } from "./academy-context/primary/admin/teacher-list/teacher-list.components";
import { BudgetAdminDetail } from "./academy-context/primary/admin/budget-detail/budget-admin-detail.components";
import { BudgetsList } from "./academy-context/primary/admin/budget-list/budget-list.components";
import { UserDetail } from "./user-management-context/primary/user/user-detail/user-detail.components";
import { LessonAdminDetail } from "./academy-context/primary/admin/lesson-admin-detail/lesson-admin-detail.components";
import { LessonsList } from "./academy-context/primary/admin/lesson-list/lesson-list.components";
import { TransfersList } from "./academy-context/primary/admin/transfers-list/transfers-list.components";
import { LessonTeacherDetail } from "./academy-context/primary/teacher/lesson-teacher-detail/lesson-teacher-detail.components";
import { InvitationsPage } from "./pages/admin/user-invitations.page";
import { TransfersSummary } from "./academy-context/primary/admin/transfers-summary/transfers-summary.components";
import { TeacherUserDetail } from "./user-management-context/primary/teacher/teacher-user-detail/teacher-user-detail.components";
import { TeacherLessonsList } from "./academy-context/primary/teacher/lesson-list/lesson-list.components";
import { StudentUserDetail } from "./user-management-context/primary/student/student-user-detail/student-user-detail.components";
import { StudentLessonList } from "./academy-context/primary/student/lesson-list/lesson-list.components";
import { ExpenseAdminDetail } from "./academy-context/primary/admin/expense-detail/expense-admin-detail.components";
import { ExpensesList } from "./academy-context/primary/admin/expense-list/expense-list.components";
import { StudentExpenseList } from "./academy-context/primary/student/expense-list/expense-list.components";
import { ConventionAdminDetail } from "./academy-context/primary/admin/convention-detail/convention-admin-detail.components";
import { ConventionsList } from "./academy-context/primary/admin/convention-list/convention-list.components";
import { ConventionTransfersList } from "./academy-context/primary/admin/convention-transfers-list/convention-transfers-list.components";
import { TeacherConventionList } from "./academy-context/primary/teacher/convention-list/convention-list.components";
import { StudentConventionList } from "./academy-context/primary/student/convention-list/convention-list.components";
import { StudentBudgetsList } from "./academy-context/primary/student/budget-list/budget-list.components";
import { StudentSummaryContainer } from "./academy-context/primary/student/student-summary/student-summary-container.components";
import { FundingRequestHomeScreen } from "./academy-context/primary/student/funding-request/home-screen.components";
import { FundingRequestCreate } from "./academy-context/primary/student/funding-request/funding-request-create.components";
import { FundingRequestSubmissionSuccess } from "./academy-context/primary/student/funding-request/submission-success-screen.components";
import { FundingRequestList } from "./academy-context/primary/admin/funding-request-list/funding-request-list.components";
import { FundingRequestDetail } from "./academy-context/primary/admin/funding-request-detail/funding-request-detail.components";
import { StudentAttendanceSheetForm } from "./academy-context/primary/shared/attendance-sheet-signature-form/student-attendance-sheet-form";
import { TeacherTrainingList } from "./academy-context/primary/teacher/training-list/training-list.components";
import { StudentTrainingDetail } from "./academy-context/primary/student/training-detail/training-detail.components";
import { TrainingSurvey } from "./academy-context/primary/student/training-detail/training-survey.components";
import { StudentTrainingList } from "./academy-context/primary/student/training-list/training-list.components";
import { TeacherTrainingDetail } from "./academy-context/primary/teacher/training-detail/training-detail.components";
import { TeacherAttendanceSheetForm } from "./academy-context/primary/shared/attendance-sheet-signature-form/teacher-attendance-sheet-form";
import { StudentTrainingObjectives } from "./academy-context/primary/student/training-detail/student-training-summary";
import { TeacherTrainingObjectives } from "./academy-context/primary/teacher/training-detail/teacher-training-summary";
import { TrainingList } from "./academy-context/primary/admin/training-list/training-list.components";
import { AdminTrainingDetail } from "./academy-context/primary/admin/training-detail/training-detail.components";
import { AdminTrainingObjectives } from "./academy-context/primary/admin/training-detail/admin-training-objectives";
import { OldConventionsList } from "./academy-context/primary/admin/convention-list/old-convention-list.components";
import { RefundRequestDetail } from "@academy-context/primary/student/refund-request-detail/refund-request-detail.components";
import { RefundRequestSuccess } from "@academy-context/primary/student/refund-request-detail/refund-request-success.components";
import { CollaborationRequests } from "@academy-context/primary/admin/collaboration-request-list/collaboration-request-list";
import { AdminProviderTrainingsPage } from "@pages/admin/provider-trainings.page";
import { ProviderListPage } from "@pages/admin/provider-list.page";
import { ProviderCreatePage } from "@pages/admin/provider-create.page";
import { ProviderDetailsPage } from "@pages/admin/provider-details.page";
import { AdminProviderInternshipListPage } from "@pages/admin/provider-internship-list.page";
import { ProviderStudentInvitationListPage } from "@pages/provider/provider-student-invitation-list.page";
import { ProviderUserDetailContainer } from "@user-management-context/primary/provider/provider-user-detail/provider-user-detail.container";
import { ProviderTrainingsPage } from "@pages/provider/provider-trainings.page";
import { ProviderInternshipListPage } from "@pages/provider/provider-internship-list.page";

export interface AppRoute {
  path: string;
  name?: string;
  element: JSX.Element;
  end?: boolean;
}
export const TRAININGS_ROUTE_PATH = "trainings";
export const REFUND_REQUEST_ROUTE_PATH = "refund-requests";

const adminRoutes: AppRoute[] = [
  {
    path: `lessons`,
    element: <LessonAdminDetail />,
    name: "Déclarer des heures",
  },
  {
    path: `lessons/list`,
    element: <LessonsList />,
  },
  {
    path: `budgets`,
    element: <BudgetAdminDetail />,
    name: "Ajouter un budget AFDAS à un élève",
  },
  {
    path: `budgets/list`,
    element: <BudgetsList />,
  },
  {
    path: `expenses`,
    element: <ExpenseAdminDetail />,
    name: "Masterclass / Frais",
  },
  {
    path: `expenses/list`,
    element: <ExpensesList />,
  },
  {
    path: `conventions`,
    element: <ConventionAdminDetail />,
    name: "Nouvelles conventions",
  },
  {
    path: `conventions/list`,
    element: <ConventionsList />,
  },
  {
    path: `oldConventions/list`,
    element: <OldConventionsList />,
    name: "Anciennes conventions",
  },
  {
    path: `collaboration-requests`,
    element: <CollaborationRequests />,
    name: "Demandes d'ajout d'élève",
  },
  {
    path: `invitations`,
    element: <InvitationsPage />,
    name: "Invitations",
  },
  {
    path: `providers`,
    element: <ProviderListPage />,
    name: "Prestataires",
  },
  {
    path: `providers/create`,
    element: <ProviderCreatePage />,
  },
  {
    path: `providers/:id`,
    element: <ProviderDetailsPage />,
  },
  {
    path: `provider-internships`,
    element: <AdminProviderInternshipListPage />,
    name: "Dossiers Elèves Prestataire",
  },
  {
    path: `provider-trainings`,
    element: <AdminProviderTrainingsPage />,
    name: "Formations Prestataire",
  },
  {
    path: `students`,
    element: <StudentsList />,
    name: "Elèves",
  },
  {
    path: `students/create`,
    element: <StudentCreate />,
  },
  {
    path: `students/:id`,
    element: <StudentAdminDetail />,
  },
  {
    path: `teachers`,
    element: <TeachersList />,
    name: "Professeurs",
  },
  {
    path: `teachers/create`,
    element: <TeacherCreate />,
  },
  {
    path: `teachers/:id`,
    element: <TeacherAdminDetail />,
  },
  {
    path: `/transfers`,
    element: <TransfersSummary />,
    name: "Paiements à effectuer",
    end: true,
  },
  {
    path: `/transfers/list`,
    element: <TransfersList />,
    name: "Historique des paiements",
  },
  {
    path: `/convention-transfers`,
    element: <ConventionTransfersList />,
    name: "Paiements des anciennes conventions",
    end: true,
  },
  {
    path: `fundingRequests`,
    element: <FundingRequestList />,
    name: "Demandes de financement AFDAS",
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <TrainingList />,
    name: "Suivi de formation",
  },
  {
    path: `trainings/:id`,
    element: <AdminTrainingDetail />,
  },
  {
    path: `trainings/:id/sessions/student`,
    element: <StudentAttendanceSheetForm isAdmin />,
  },
  {
    path: `trainings/:id/sessions/teacher`,
    element: <TeacherAttendanceSheetForm isAdmin />,
  },
  {
    path: `trainings/:id/pretrainingsurvey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/posttrainingsurvey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <AdminTrainingObjectives />,
  },
  {
    path: `fundingRequests/:id`,
    element: <FundingRequestDetail />,
  },
  {
    path: `profile`,
    element: <UserDetail />,
    name: "Profil",
  },
];

export const STUDENT_FUNDING_REQUEST_MENU_LABEL = "Constitution de votre dossier de financement AFDAS";
const studentRoutes: AppRoute[] = [
  {
    path: `dashboard`,
    element: <StudentSummaryContainer />,
    name: "Tableau de bord",
  },
  {
    path: `fundingRequest`,
    element: <FundingRequestHomeScreen />,
    name: STUDENT_FUNDING_REQUEST_MENU_LABEL,
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <StudentTrainingList />,
    name: "Suivi QUALIOPI de votre formation",
  },
  {
    path: REFUND_REQUEST_ROUTE_PATH,
    element: <RefundRequestDetail />,
    name: "Remboursement de frais",
  },
  {
    path: "refund-requests/success",
    element: <RefundRequestSuccess />,
  },
  {
    path: `trainings/:id`,
    element: <StudentTrainingDetail />,
  },
  {
    path: `trainings/:id/sessions`,
    element: <StudentAttendanceSheetForm />,
  },
  {
    path: `trainings/:id/pretrainingsurvey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/posttrainingsurvey`,
    element: <TrainingSurvey />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <StudentTrainingObjectives />,
  },
  {
    path: `lessons`,
    element: <StudentLessonList />,
    name: "Historique des cours",
  },
  {
    path: `expenses`,
    element: <StudentExpenseList />,
    name: "Historique des Masterclass/Frais",
  },
  {
    path: `budgets`,
    element: <StudentBudgetsList />,
    name: "Historique des budgets",
  },
  {
    path: `conventions/list`,
    element: <StudentConventionList />,
    name: "Historique des conventions",
  },
  {
    path: `fundingRequest/new`,
    element: <FundingRequestCreate />,
  },
  {
    path: `fundingRequest/success`,
    element: <FundingRequestSubmissionSuccess />,
  },
  {
    path: `profile`,
    element: <StudentUserDetail />,
    name: "Profil",
  },
];

const teacherRoutes: AppRoute[] = [
  {
    path: `dashboard`,
    element: <LessonTeacherDetail />,
    name: "Tableau de bord",
    end: true,
  },
  {
    path: TRAININGS_ROUTE_PATH,
    element: <TeacherTrainingList />,
    name: "Suivi QUALIOPI de vos élèves",
  },
  {
    path: `trainings/:id`,
    element: <TeacherTrainingDetail />,
  },
  {
    path: `trainings/:id/sessions`,
    element: <TeacherAttendanceSheetForm />,
  },
  {
    path: `trainings/:id/objectives`,
    element: <TeacherTrainingObjectives />,
  },
  {
    path: `lessons/list`,
    element: <TeacherLessonsList />,
    name: "Historique des cours",
  },
  {
    path: `conventions/list`,
    element: <TeacherConventionList />,
    name: "Historique des conventions",
  },
  {
    path: `profile`,
    element: <TeacherUserDetail />,
    name: "Profil",
  },
];

const providerRoutes: AppRoute[] = [
  {
    path: `dashboard`,
    element: <ProviderInternshipListPage />,
    name: "Tableau de bord",
    end: true,
  },
  {
    path: `provider-trainings`,
    element: <ProviderTrainingsPage />,
    name: "Formations Prestataire",
  },
  {
    path: `students`,
    element: <ProviderStudentInvitationListPage />,
    name: "Elèves",
  },
  {
    path: `profile`,
    element: <ProviderUserDetailContainer />,
    name: "Profil",
  },
];

export { studentRoutes, adminRoutes, teacherRoutes, providerRoutes };
