import { ConventionPaymentExtended } from "../../../../../domain/types/admin/payment";
import { AppState } from "@redux/app-state";

export const selectConventionTransfersRetrievalForList = (state: AppState) => {
  const {
    conventionTransferListRetrieval: { data, fetching },
  } = state;

  const transfersExtended: ConventionPaymentExtended[] = data.map(t => ({
    ...t,
    paymentDateLocale: t.paymentDate ? new Date(t.paymentDate).toLocaleDateString("fr-FR") : null,
    creationDateLocale: new Date(t.creationDate).toLocaleDateString("fr-FR"),
  }));

  return {
    all: transfersExtended,
    toPay: transfersExtended.filter(t => t.status === "PENDING"),
    fetching,
  };
};
