import { useAppDispatch } from "@redux/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useNavigate } from "react-router-dom";

const selectFundingRequestReview = (state: AppState) => state.fundingRequestReview;

export const useFundingRequestReviewed = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { fetching } = useSelector(selectFundingRequestReview);

  useEffect(() => {
    if (fetching === "success") {
      navigate(`/fundingRequests`);
      toast({ description: "Demande de financement validée!" });
    } else if (fetching === "failed") {
      toast({
        description: "Erreur lors de la validation de la demande de financement. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [navigate, dispatch, fetching, toast]);
};
