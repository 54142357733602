import { FetchingState } from "src/redux/app-state";
import { v4 as uuidv4 } from "uuid";

export const getNormalizedString = (s: string) => {
  return s
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

const generateRandomLetter = (): string => String.fromCharCode(97 + Math.floor(Math.random() * 26));
export const generateRandomString = (length: number): string => {
  let res = "";
  for (let index = 0; index < length; index++) {
    res += generateRandomLetter();
  }
  return res;
};

export const urlToBloB = (url: string) => fetch(url).then(response => response.blob());

export function assertArrLength<T>(array: T[], expectedLength: number): asserts array is [T, T, T, T, T, T, T, T, T, T] {
  if (array.length !== expectedLength) {
    throw new Error("Array must have exactly 10 items");
  }
}

export function isLoadingState(state: FetchingState) {
  return ["idle", "pending"].includes(state);
}

export const generateNewId = () => uuidv4();

export const sortAlphabetically = (aString: string, bString: string) => {
  const a = getNormalizedString(aString);
  const b = getNormalizedString(bString);
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};
