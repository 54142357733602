import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectBudgetsRetrievalForList } from "../../../read/application/use-cases/student/budgets-retrieval/selectors/budgets-list-selectors";
import { StudentBudgetExtended } from "../../../read/application/use-cases/student/budgets-retrieval/selectors/shared";
import { retrieveBudgets } from "../../../read/application/use-cases/student/budgets-retrieval/retrieve-budgets";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";

const columns: ColumnDef<StudentBudgetExtended>[] = [
  {
    accessorKey: "createdAt",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.createdAt),
    size: 90,
    enableSorting: true,
    meta: {
      title: "Enregistré le",
    },
  },
  {
    accessorKey: "funder",
    header: () => "Financeur",
    size: 100,
    meta: {
      title: "Financeur",
    },
  },
  {
    accessorKey: "totalAmount",
    header: () => "Montant total",
    cell: ({ row }) => formatToCurrency(row.original.totalAmount),
    size: 70,
    meta: {
      title: "Montant total",
    },
  },
];

export const StudentBudgetsList = () => {
  const dispatch = useAppDispatch();
  const { data: budgets } = useAppSelector(selectBudgetsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveBudgets());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES BUDGETS">
      <DataTable columns={columns} data={budgets} sortField="createdAt" order="desc" searchPlaceHolder="Rechercher un budget" />
    </CustomCard>
  );
};
