import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

export const selectUnsubscribedUserList = (state: AppState) => {
  const {
    unsubscribedUserListRetrieval: { users, fetching },
  } = state;

  const base = [...users]
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .map(u => {
      const label = u.name && u.lastName ? `${u.name} ${u.lastName} | ${u.email}` : u.email;
      return { label, value: u.email, role: u.role };
    });

  const teacherEmails: { label: string; value: string }[] = [];
  const studentEmails: { label: string; value: string }[] = [];
  base.forEach(u => {
    if (u.role === ROLES.TEACHER) teacherEmails.push(u);
    if (u.role === ROLES.STUDENT) studentEmails.push(u);
  });

  return {
    teacherEmails,
    studentEmails,
    isLoading: isLoadingState(fetching),
  };
};
