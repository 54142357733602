import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Student } from "../../../../domain/types/student/student";

export const retrieveStudentProfile = createAsyncThunk<Student, void, { extra: Partial<Dependencies> }>(
  "students/retrieveStudentProfile",
  async (_: void, { extra: { studentGateway } }) => {
    return studentGateway!.profile();
  }
);
