import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";

export const adminRetrieveProviders = createAsyncThunk<ProviderListItem[], void, { extra: Partial<Dependencies> }>(
  "providers/adminRetrieveProviders",
  async (_: void, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.list();
  }
);
