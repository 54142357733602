import { createReducer } from "@reduxjs/toolkit";
import { TeacherTrainingRetrievalState } from "@redux/app-state";
import { teacherRetrieveTraining } from "./retrieve-training";

const initialState: TeacherTrainingRetrievalState = { fetching: "idle", data: null };

export const teacherRetrieveTrainingReducer = createReducer<TeacherTrainingRetrievalState>(initialState, builder => {
  builder.addCase(teacherRetrieveTraining.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(teacherRetrieveTraining.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(teacherRetrieveTraining.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
