import { createReducer } from "@reduxjs/toolkit";
import { adminCreateProviderTraining, adminResetCreateProviderTraining } from "./create-provider-training";
import { CreationState } from "@redux/app-state";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const adminCreateProviderTrainingReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(adminCreateProviderTraining.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(adminCreateProviderTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminCreateProviderTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetCreateProviderTraining, () => {
    return initialState;
  });
});
