import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { adminRetrieveProviderInternships } from "@academy-context/read/application/use-cases/admin/provider-internships-retrieval/retrieve-provider-internships";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";

const initialState: RetrievalState<ProviderInternship[]> = { data: [], fetching: "idle" };

export const adminRetrieveProviderInternshipsReducer = createReducer<RetrievalState<ProviderInternship[]>>(initialState, builder => {
  builder.addCase(adminRetrieveProviderInternships.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveProviderInternships.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveProviderInternships.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
