import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

export const deleteProviderTraining = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "providerTrainings/deleteProviderTraining",
  async (trainingId: string, { extra: { providerRepository } }) => {
    return providerRepository!.deleteTraining(trainingId);
  }
);

export const resetDeleteProviderTraining = createAction("providerTrainings/resetDeleteProviderTraining");
