import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateExpenseBody } from "@academy-context/write/domain/types/admin/expense";

export const createExpense = createAsyncThunk<string, CreateExpenseBody, { extra: Partial<Dependencies> }>(
  "expenses/createExpense",
  async (expense: CreateExpenseBody, { extra: { adminExpenseGateway } }) => {
    return adminExpenseGateway!.create(expense);
  }
);

export const resetCreateExpense = createAction("expenses/resetCreateExpense");
