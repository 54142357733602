import { DateInterval } from "@shared-kernel/core/types/date-interval";

export interface ProviderInvitationListItem {
  id: string;
  student: {
    email: string;
    name: string;
    lastName: string;
    mobile: string;
    isAlreadyExisting: boolean;
  };
  status: PROVIDER_STUDENT_INVITATION_STATUS;
  training: string;
  lastInternship: { interval: DateInterval };
  creationDate: Date;
}

export enum PROVIDER_STUDENT_INVITATION_STATUS {
  SUBMITTED_TO_ADMIN = "submitted_to_admin",
  REFUSED_BY_ADMIN = "refused_by_admin",
  ACCEPTED_BY_ADMIN = "accepted_by_admin",
  REGISTERED = "registered",
}
