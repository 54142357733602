import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { createProviderAccount } from "./create-provider-account";

const initialState: SimpleState = { processing: "idle" };

export const createProviderAccountReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(createProviderAccount.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(createProviderAccount.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(createProviderAccount.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
