import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Provider } from "@user-management-context/read/domain/types/admin/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";

export const retrieveProvider = createAsyncThunk<Nullable<Provider>, string, { extra: Partial<Dependencies> }>(
  "providers/retrieveProvider",
  async (id: string, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.getById(id);
  }
);
