import { createReducer } from "@reduxjs/toolkit";
import { PaymentLessonListState } from "@redux/app-state";
import { retrievePaymentLessonList } from "./retrieve-payment-lesson-list";

const initialState: PaymentLessonListState = { fetching: "idle", data: [] };

export const retrievePaymentLessonListReducer = createReducer<PaymentLessonListState>(initialState, builder => {
  builder.addCase(retrievePaymentLessonList.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrievePaymentLessonList.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrievePaymentLessonList.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
