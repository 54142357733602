import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveProviderInternships } from "@academy-context/read/application/use-cases/admin/provider-internships-retrieval/retrieve-provider-internships";

const selectProviderInternshipConfirmation = (state: AppState) => state.adminProviderInternshipConfirmation;
const selectProviderInternshipUpdated = (state: AppState) => state.adminProviderInternshipUpdate;

export const useProviderInternshipUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId: justConfirmedId, processing: confirmationProcessing } = useAppSelector(selectProviderInternshipConfirmation);
  const { justUpdatedId, processing } = useAppSelector(selectProviderInternshipUpdated);

  useEffect(() => {
    if ((processing === "success" && justUpdatedId) || (confirmationProcessing === "success" && justConfirmedId)) {
      toast({ description: "Dossier élève mis à jour !" });
      dispatch(adminRetrieveProviderInternships());
    } else if (processing === "failed" || confirmationProcessing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du dossier élève", variant: "destructive" });
    }
  }, [confirmationProcessing, dispatch, justConfirmedId, justUpdatedId, processing, toast]);
};
