import { TestContext, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ConfirmInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { AnyObject } from "yup/lib/types";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";

export type InternshipFormInputs = {
  id: string;
  training: string;
  price: number | undefined;
  startDate: string;
  endDate: string;
};

export const defaultInternshipValues = {
  id: "",
  training: "",
  price: undefined,
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
};

const validateIntervalCoherence = (end: string | undefined, context: TestContext<AnyObject>): boolean => {
  const {
    parent: { startDate },
  } = context;

  if (!end || !startDate) {
    return true;
  }

  return new Date(end).getTime() >= new Date(startDate).getTime();
};

export const internshipSchema = object().shape({
  id: string().max(255).required(REQUIRED_FIELD),
  training: string().max(255).required(REQUIRED_FIELD),
  startDate: string().max(255).required(REQUIRED_FIELD),
  endDate: string()
    .max(255)
    .required(REQUIRED_FIELD)
    .test("end is after start", "La date de fin doit être après la date de début", validateIntervalCoherence),
  price: number().typeError("Minimum 0 €").min(0, "Minimum 0 €").max(3000, "Maximum 3000 €").required(REQUIRED_FIELD),
});

export const formatInternshipDataToFormData = (providerInternship: ProviderInternship): InternshipFormInputs => {
  const { id, details } = providerInternship;

  return {
    id,
    training: details.training,
    price: details.price ?? defaultInternshipValues.price,
    startDate: details.interval ? details.interval.start : defaultInternshipValues.startDate,
    endDate: details.interval ? details.interval.end : defaultInternshipValues.endDate,
  };
};

export const formatFormDataToBodyData = (internship: InternshipFormInputs): ConfirmInternshipBody => {
  const { id, training, price, startDate, endDate } = internship;

  return {
    id,
    training,
    price: price ?? 0,
    interval: {
      start: new Date(startDate).toISOString(),
      end: new Date(endDate).toISOString(),
    },
  };
};
