import { AdminLessonExtended } from "@academy-context/read/domain/types/admin/lesson";
import { AppState } from "@redux/app-state";

export const selectLessonsRetrievalForSummmaryList = (state: AppState) => {
  const {
    adminLastLessonsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.creationDate).getTime();
    const dateBInMs = new Date(b.creationDate).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  const lessons: AdminLessonExtended[] = sortedData.slice(0, 10).map(l => {
    return {
      ...l,
      student: `${l.studentName} ${l.studentLastName}`,
    };
  });

  return {
    data: lessons,
    fetching,
  };
};
