import { createReducer } from "@reduxjs/toolkit";
import { UserProfileRetrievalState } from "@redux/app-state";
import { updateStudentProfile } from "../../../../write/application/use-cases/student/update-profile/update-student-profile";
import { updateTeacherUser } from "../../../../write/application/use-cases/teacher/teacher-update/update-teacher";
import { resetRetrieveUserProfile, retrieveUserProfile } from "./retrieve-user-profile";

const initialState: UserProfileRetrievalState = { fetching: "idle", data: null };

export const retrieveUserProfileReducer = createReducer<UserProfileRetrievalState>(initialState, builder => {
  builder.addCase(retrieveUserProfile.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveUserProfile.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveUserProfile.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(
    updateStudentProfile.fulfilled,
    (
      state,
      {
        meta: {
          arg: { lastName, name },
        },
      }
    ) => {
      return {
        ...state,
        data: { ...state.data!, lastName, name },
      };
    }
  );
  builder.addCase(
    updateTeacherUser.fulfilled,
    (
      state,
      {
        meta: {
          arg: { lastName, name },
        },
      }
    ) => {
      return {
        ...state,
        data: { ...state.data!, lastName, name },
      };
    }
  );
  builder.addCase(resetRetrieveUserProfile, () => {
    return initialState;
  });
});
