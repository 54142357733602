import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { getPlaceName } from "../../../write/domain/services/locations";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { SimpleTable } from "../../../../shared-kernel/primary/shared/simple-table";
import clsx from "clsx";
import { AdminLessonExtended } from "@academy-context/read/domain/types/admin/lesson";

const headers = [
  { title: null, classname: "w-[10px]" },
  { title: "Enregistré le" },
  { title: "Date du cours" },
  { title: "Elève" },
  { title: "Professeur" },
  { title: "Nombre d'heures" },
  { title: "Tarif horaire" },
  { title: "Studio" },
  { title: "Tarif horaire studio" },
  { title: "Montant imputé sur le solde de l'élève" },
  { title: "Règlement professeur" },
  { title: "Commentaire", classname: "w-[150px]" },
];

interface Props {
  lessons: AdminLessonExtended[];
}

export const LessonListSummary = ({ lessons }: Props) => {
  const entries = useMemo(
    () =>
      lessons.map(l => {
        return [
          { value: null, classname: clsx(l.isUnderConvention && "bg-primary-foreground") },
          { value: formatDateToLocale(l.creationDate) },
          { value: formatDateToLocale(l.date) },
          { value: l.student },
          { value: l.teacher },
          { value: l.hours },
          { value: formatToCurrency(l.hourlyPrice) },
          { value: getPlaceName(l.place.paidByTeacher, l.place.name) },
          { value: formatToCurrency(l.place.price ?? 0) },
          { value: formatToCurrency(l.amountChargedOnStudentBalance) },
          { value: formatToCurrency(l.paidToTeacher) },
          { value: l.commentary },
        ];
      }),
    [lessons]
  );

  return (
    <CustomCard
      title="Historique des derniers cours"
      headerChildren={
        <Link to={"/lessons/list"}>
          <Button>Voir tous les cours</Button>
        </Link>
      }
    >
      <SimpleTable headers={headers} entries={entries} />
    </CustomCard>
  );
};
