import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ArrowBigLeftIcon } from "lucide-react";

interface Props {
  link: string;
  label: string;
}

export const BackButton = ({ link, label }: Props) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(link);
  };

  return (
    <div className="flex items-center text-theme mb-4">
      <Button variant="outline" onClick={onClick} className="hover:text-theme">
        <ArrowBigLeftIcon fill="#831b1b" />
      </Button>
      <h1 className="text-3xl font-bold ml-4">{label}</h1>
    </div>
  );
};
