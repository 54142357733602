import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ProviderStudentInvitationListSkeleton } from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/provider-student-invitation-list.skeleton";
import { adminSelectProviderStudentInvitationsRetrievalForList } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/selectors/provider-student-invitation-list-selectors";
import { adminRetrieveProviderStudentInvitations } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/retrieve-provider-student-invitations";
import { AcceptProviderStudentInvitationBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { acceptProviderStudentInvitation } from "@user-management-context/write/application/use-cases/admin/provider-student-invitation-acceptation/accept-provider-student-invitation";
import { refuseProviderStudentInvitation } from "@user-management-context/write/application/use-cases/admin/provider-student-invitation-refusal/refuse-provider-student-invitation";
import { useProviderStudentInvitationRefusal } from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/use-provider-student-invitation-refusal";
import { ProviderStudentInvitationListComponent } from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/provider-student-invitation-list.components";
import { useProviderStudentInvitationAcceptation } from "@user-management-context/primary/admin/user-invitations/provider-student-invitation-list/use-provider-student-invitation-acceptation";

export const ProviderStudentInvitationListContainer = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(adminRetrieveProviderStudentInvitations());
  }, [dispatch]);

  const { invitations, isLoading } = useAppSelector(adminSelectProviderStudentInvitationsRetrievalForList);

  const onSubmit = (data: { id: string; body: AcceptProviderStudentInvitationBody }) => dispatch(acceptProviderStudentInvitation(data));
  const onDelete = (id: string) => dispatch(refuseProviderStudentInvitation(id));

  useProviderStudentInvitationAcceptation();
  useProviderStudentInvitationRefusal();

  if (isLoading) return <ProviderStudentInvitationListSkeleton />;

  return <ProviderStudentInvitationListComponent pendingProviderStudentInvitations={invitations} onSubmit={onSubmit} onDelete={onDelete} />;
};
