import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectConventionsRetrievalForList } from "../../../read/application/use-cases/student/conventions-retrieval/selectors/conventions-list-selectors";
import { retrieveConventions } from "../../../read/application/use-cases/student/conventions-retrieval/retrieve-conventions";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { StudentConventionExtended } from "../../../read/application/use-cases/student/conventions-retrieval/selectors/shared";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { computeConventionStatus } from "@academy-context/read/domain/services/convention";

const columns: ColumnDef<StudentConventionExtended>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
    },
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "hours",
    header: () => "Nombre d'heures",
    meta: {
      title: "Nombre d'heures",
    },
  },
  {
    accessorKey: "hoursDone",
    header: () => "Heures consommées",
    meta: {
      title: "Heures consommées",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire (€/heure)",
    meta: {
      title: "Tarif horaire (€/heure)",
    },
    cell: ({ row }) => formatToCurrency(row.original.hourlyPrice),
  },
  {
    accessorKey: "prematureEndDate",
    header: () => "Statut",
    meta: {
      title: "Statut",
    },
    cell: ({ row }) => {
      const { prematureEndDate, hoursLeft } = row.original;
      return computeConventionStatus(prematureEndDate, hoursLeft);
    },
    size: 40,
  },
];

export const StudentConventionList = () => {
  const dispatch = useAppDispatch();
  const { data: conventions } = useAppSelector(selectConventionsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveConventions());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES CONVENTIONS">
      <DataTable
        columns={columns}
        data={conventions}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher une convention"
        displayDataViewOptions
      />
    </CustomCard>
  );
};
