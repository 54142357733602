import React, { ReactElement } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { formatDateToLocale } from "@utils/formatting";
import {
  PROVIDER_STUDENT_INVITATION_STATUS,
  ProviderInvitationListItem,
} from "@user-management-context/read/domain/types/provider/invitation";
import { MailCheck, PhoneMissed, UserCheck, UserX } from "lucide-react";

const invitationStatusLabelMap: Record<PROVIDER_STUDENT_INVITATION_STATUS, ReactElement> = {
  [PROVIDER_STUDENT_INVITATION_STATUS.SUBMITTED_TO_ADMIN]: (
    <div className="flex justify-center items-center">
      <PhoneMissed className="mr-2" />
      Pas encore pris contact
    </div>
  ),
  [PROVIDER_STUDENT_INVITATION_STATUS.ACCEPTED_BY_ADMIN]: (
    <div className="flex justify-center items-center">
      <MailCheck className="mr-2" />
      Invitation envoyée
    </div>
  ),
  [PROVIDER_STUDENT_INVITATION_STATUS.REFUSED_BY_ADMIN]: (
    <div className="flex justify-center items-center">
      <UserX className="mr-2" color="red" />
      Elève refusé
    </div>
  ),
  [PROVIDER_STUDENT_INVITATION_STATUS.REGISTERED]: (
    <div className="flex justify-center items-center">
      <UserCheck className="mr-2" color="green" />
      Inscrit sur OPERA Off
    </div>
  ),
};

const columns: ColumnDef<ProviderInvitationListItem>[] = [
  {
    accessorKey: "student.name",
    header: () => "Prénom Nom",
    cell: info => {
      return `${info.row.original.student.name} ${info.row.original.student.lastName}`;
    },
    enableSorting: true,
    meta: {
      title: "Nom",
    },
  },
  {
    accessorKey: "status",
    header: () => "Statut",
    cell: info => {
      return invitationStatusLabelMap[info.row.original.status];
    },
    enableSorting: true,
    meta: {
      title: "Statut",
    },
  },
  {
    accessorKey: "training",
    header: () => "Dernière formation",
    enableSorting: true,
    meta: {
      title: "Dernière formation",
    },
  },
  {
    accessorKey: "lastInternship.interval.start",
    id: "interval",
    header: () => "Dernière formation",
    cell: ({
      row: {
        original: {
          lastInternship: { interval },
        },
      },
    }) => {
      return `${formatDateToLocale(interval.start)} -> ${formatDateToLocale(interval.end)}`;
    },
    enableSorting: true,
    meta: {
      title: "Dernière formation",
    },
  },
  {
    accessorKey: "creationDate",
    id: "creationDate",
  },
];

interface Props {
  invitations: ProviderInvitationListItem[];
}

export const ProviderStudentInvitationListComponent = ({ invitations }: Props) => {
  return (
    <DataTable
      columns={columns}
      data={invitations}
      sortField="creationDate"
      order="asc"
      searchPlaceHolder="Rechercher un élève"
      pageSize={50}
      invisibleColumns={{ creationDate: false }}
    />
  );
};
