import { createReducer } from "@reduxjs/toolkit";
import { AdminTrainingsRetrievalState } from "@redux/app-state";
import { adminRetrieveTrainings } from "./retrieve-trainings";

const initialState: AdminTrainingsRetrievalState = { fetching: "idle", data: [] };

export const adminRetrieveTrainingsReducer = createReducer<AdminTrainingsRetrievalState>(initialState, builder => {
  builder.addCase(adminRetrieveTrainings.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveTrainings.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveTrainings.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
