import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { ProviderTrainingBuilder } from "@academy-context/test-utils/provider-training-builder";

const providerTraining: ProviderTraining = ProviderTrainingBuilder().build();
const providerTraining2: ProviderTraining = ProviderTrainingBuilder()
  .withId("training-2")
  .withCreationDate("2022-10-02T03:00:00Z")
  .withTitle("La formation de Ysf")
  .withObjective("Se former à jouer avec Ysf")
  .withDescription("blablabla")
  .build();
const providerTraining3: ProviderTraining = ProviderTrainingBuilder()
  .withId("training-3")
  .withCreationDate("2022-10-03T03:00:00Z")
  .withTitle("La formation de Jean Claude la renault")
  .withObjective("Se former à la renault")
  .withDescription("Apprendre à conduire des renault")
  .build();
const providerTraining4: ProviderTraining = ProviderTrainingBuilder()
  .withId("training-4")
  .withProviderId("provider-3")
  .withCreationDate("2022-10-03T03:00:00Z")
  .withTitle("La formation de Moez la merco")
  .withObjective("Se former à la merco")
  .withDescription("Apprendre à conduire des merco")
  .build();

export const providerTrainings = [providerTraining3, providerTraining, providerTraining2, providerTraining4];
