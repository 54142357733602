import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminConventionListVM } from "../../../../domain/types/admin/convention";

export const retrieveConventions = createAsyncThunk<AdminConventionListVM[], void, { extra: Partial<Dependencies> }>(
  "conventions/retrieveConventions",
  async (_: void, { extra: { adminConventionGateway } }) => {
    return adminConventionGateway!.all();
  }
);
