import { object, string } from "yup";
import { User } from "../../../../read/domain/types/user";
import { UpdateUserBody } from "../../../../write/domain/types/user";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export type FormInputs = {
  name: string;
  lastName: string;
};

export const defaultValues = {
  name: "",
  lastName: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
});

export const formatUserDataToFormData = (user: User): FormInputs => {
  return {
    ...defaultValues,
    name: user.name,
    lastName: user.lastName,
  };
};

export const formatFormDataToBodyData = (user: FormInputs): UpdateUserBody => {
  return {
    name: user.name,
    lastName: user.lastName,
  };
};
