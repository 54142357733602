import React, { useEffect, useState } from "react";
import { ArrowUpRightFromCircle } from "lucide-react";
import DocumentUploadButton from "@shared-kernel/primary/shared/upload-button/document-upload-button";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { INVOICE_FILETYPE, isValidInvoiceFile } from "@academy-context/write/domain/constants/shared";
import InputError from "@shared-kernel/primary/shared/input-error";
import { AppState } from "src/redux/app-state";
import { uploadInvoice } from "@academy-context/write/application/use-cases/student/invoice-upload/upload-invoice";

interface Props {
  invoice: { id: string; url: Nullable<string> };
  onInvoiceUpload: (url: string, uploadId: string) => void;
  onDeleteInvoice: (id: string) => void;
}

const selectInvoiceCreation = (id: string) => (state: AppState) => state.invoiceUploads[id] || { processing: "idle", invoiceUrl: null };

export const InvoiceUpload = ({ invoice: { id, url }, onInvoiceUpload, onDeleteInvoice }: Props) => {
  const dispatch = useAppDispatch();
  const { processing, invoiceUrl } = useAppSelector(selectInvoiceCreation(id));
  const [error, setError] = useState<Nullable<string>>(null);

  const handleInvoiceUrlChange = (invoice: File) => {
    setError(null);
    dispatch(uploadInvoice({ invoice, uploadId: id }));
  };

  const handleInvoiceUrlError = () => {
    setError("Veuillez uploader un fichier de 10 Mo max");
  };

  const handleInvoiceUrlDelete = () => {
    onDeleteInvoice(id);
  };

  useEffect(() => {
    if (processing === "success" && invoiceUrl) {
      onInvoiceUpload(invoiceUrl, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceUrl, id, processing]);

  return (
    <div>
      <div className="flex items-center">
        {url ? (
          <a href={url} target="_blank" rel="noreferrer" className="gap-3 flex items-center text-green-500 text-base font-bold">
            <span>Aperçu</span>
            <ArrowUpRightFromCircle size={18} />
          </a>
        ) : (
          <DocumentUploadButton
            label="Facture"
            defaultValue={url}
            onChange={handleInvoiceUrlChange}
            onError={handleInvoiceUrlError}
            types={INVOICE_FILETYPE}
            validatorFunc={isValidInvoiceFile}
            processing={processing}
            onDelete={handleInvoiceUrlDelete}
          />
        )}
      </div>
      <InputError error={error ?? undefined} />
    </div>
  );
};
