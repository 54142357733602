import React, { SyntheticEvent, useEffect, useState } from "react";
import { formatDateToLocale } from "../../../../utils/formatting";
import { ColumnDef } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { AdminTrainingListVM, AvailableActionUrgency } from "../../../read/domain/types/training";
import { DangerSign } from "../../../../shared-kernel/primary/shared/danger-sign/danger-sign";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { useAppDispatch } from "src/redux/hooks";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { useTrainingUpdated } from "@academy-context/primary/admin/training-list/use-training-updated";
import { toggleTrainingDunningsStatus } from "@academy-context/write/application/use-cases/admin/training-dunnings-status-toggle/toggle-training-dunnings-status";

const linkWrapper = (id: string, value: string) => (
  <Link to={`/trainings/${id}`}>
    <div className="w-full h-full">
      <span>{value}</span>
    </div>
  </Link>
);

const formatAvailableActionUrgencyCell = (actionUrgency: AvailableActionUrgency) => {
  if (actionUrgency === "none") return null;
  let urgencyColor: "yellow" | "orange" | "red" = "yellow";
  if (actionUrgency === "medium") urgencyColor = "orange";
  else if (actionUrgency === "high") urgencyColor = "red";
  return (
    <div className="flex justify-center">
      <DangerSign color={urgencyColor} />
    </div>
  );
};

const columns: ColumnDef<AdminTrainingListVM>[] = [
  {
    accessorKey: "conventionDate",
    header: () => "Date de convention",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.conventionDate)),
    meta: {
      title: "Date de convention",
    },
    enableSorting: true,
  },
  {
    accessorKey: "internshipEndDate",
    header: () => "Fin de stage",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.internshipEndDate)),
    meta: {
      title: "Fin de stage",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.teacher),
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "availableActionUrgency.convention",
    header: () => "Conv.",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { convention },
      } = row.original;
      return formatAvailableActionUrgencyCell(convention);
    },
    enableSorting: true,
    meta: {
      title: "Conv.",
    },
  },
  {
    accessorKey: "availableActionUrgency.teacherSession",
    header: () => "Emarg. P",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { teacherSession },
      } = row.original;
      return formatAvailableActionUrgencyCell(teacherSession);
    },
    enableSorting: true,
    meta: {
      title: "Emarg. P",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.student),
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "availableActionUrgency.studentSession",
    header: () => "Emarg. E",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { studentSession },
      } = row.original;
      return formatAvailableActionUrgencyCell(studentSession);
    },
    enableSorting: true,
    meta: {
      title: "Emarg. E",
    },
  },
  {
    accessorKey: "availableActionUrgency.surveys.preTraining",
    header: () => "Quest. Pré",
    cell: ({ row }) => {
      const {
        availableActionUrgency: {
          surveys: { preTraining },
        },
      } = row.original;
      return formatAvailableActionUrgencyCell(preTraining);
    },
    enableSorting: true,
    meta: {
      title: "Quest. Pré",
    },
  },
  {
    accessorKey: "availableActionUrgency.surveys.postTraining",
    header: () => "Quest. Post",
    cell: ({ row }) => {
      const {
        availableActionUrgency: {
          surveys: { postTraining },
        },
      } = row.original;
      return formatAvailableActionUrgencyCell(postTraining);
    },
    enableSorting: true,
    meta: {
      title: "Quest. Post",
    },
  },
  {
    accessorKey: "availableActionUrgency.studentObjectives",
    header: () => "Suivi pédago.",
    cell: ({ row }) => {
      const {
        availableActionUrgency: { studentObjectives },
      } = row.original;
      return formatAvailableActionUrgencyCell(studentObjectives);
    },
    enableSorting: true,
    meta: {
      title: "Suivi pédago.",
    },
  },
];

interface Props {
  trainings: AdminTrainingListVM[];
}

export const TrainingTable = ({ trainings }: Props) => {
  const dispatch = useAppDispatch();
  const [dataColumns, setDataColumns] = useState<ColumnDef<AdminTrainingListVM>[]>([]);

  useEffect(() => {
    const statusColumn: ColumnDef<AdminTrainingListVM> = {
      accessorKey: "isDunningProcessPaused",
      header: () => "Stop Relance",
      meta: {
        title: "Stop Relance",
      },
      enableSorting: false,
      cell: info => {
        const { row } = info;
        const training = row.original;
        const isDunningProcessPaused = info.row.original.isDunningProcessPaused;
        const action = isDunningProcessPaused ? "resume" : "pause";
        return (
          <Checkbox
            id={training.id}
            defaultChecked={isDunningProcessPaused}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              dispatch(toggleTrainingDunningsStatus({ id: training.id, action }));
            }}
          />
        );
      },
    };
    const [conventionDate, internshipEndDate, ...rest] = columns;

    setDataColumns([conventionDate!, internshipEndDate!, statusColumn, ...rest]);
  }, [dispatch, trainings]);

  useTrainingUpdated();

  return (
    <CustomCard title="Suivi de formation">
      <DataTable
        columns={dataColumns}
        data={trainings}
        sortField="internshipEndDate"
        order="desc"
        searchPlaceHolder="Rechercher une formation"
        pageSize={50}
        displayDataViewOptions
      />
    </CustomCard>
  );
};
