import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentTrainingVM } from "../../../../domain/types/training";

export const studentRetrieveTraining = createAsyncThunk<StudentTrainingVM, string, { extra: Partial<Dependencies> }>(
  "trainings/studentRetrieveTraining",
  async (id: string, { extra: { trainingGateway } }) => {
    return trainingGateway!.studentGetOne(id);
  }
);
