import { createReducer } from "@reduxjs/toolkit";
import {
  refuseProviderStudentInvitation,
  resetRefuseProviderStudentInvitation,
} from "@user-management-context/write/application/use-cases/admin/provider-student-invitation-refusal/refuse-provider-student-invitation";
import { SimpleState } from "src/redux/app-state";

const initialState: SimpleState = { processing: "idle" };

export const refuseProviderStudentInvitationReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(refuseProviderStudentInvitation.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(refuseProviderStudentInvitation.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(refuseProviderStudentInvitation.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetRefuseProviderStudentInvitation, () => {
    return initialState;
  });
});
