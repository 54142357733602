import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentListElement } from "../../../../domain/types/teacher/student";

export const retrieveTeacherStudents = createAsyncThunk<StudentListElement[], void, { extra: Partial<Dependencies> }>(
  "teachers/retrieveTeacherStudents",
  async (_: void, { extra: { teacherStudentGateway } }) => {
    return teacherStudentGateway!.all();
  }
);
