import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateStudent, updateStudent } from "./update-student";
import { StudentUpdateState } from "@redux/app-state";

const initialState: StudentUpdateState = { justUpdatedStudentId: null, fetching: "idle" };

export const updateStudentReducer = createReducer<StudentUpdateState>(initialState, builder => {
  builder.addCase(updateStudent.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedStudentId: meta.arg.id,
      fetching: "success",
    };
  });
  builder.addCase(updateStudent.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updateStudent.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetUpdateStudent, () => {
    return initialState;
  });
});
