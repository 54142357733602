import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingObjective } from "../../../../domain/types/training";

export const retrieveTrainingObjectives = createAsyncThunk<
  TrainingObjective[],
  { trainingId: string; role: "teacher" | "student" },
  { extra: Partial<Dependencies> }
>(
  "trainingObjectives/retrieveTrainingObjectives",
  async ({ trainingId, role }: { trainingId: string; role: "teacher" | "student" }, { extra: { trainingGateway } }) => {
    return trainingGateway!.getTrainingObjectives(trainingId, role);
  }
);
