import React from "react";
import { BackButton } from "../../../../shared-kernel/primary/shared/back-button/back-button";
import { ObjectiveCard } from "./objective-card.components";
import { TrainingObjective } from "../../../read/domain/types/training";

interface Props {
  objectives: TrainingObjective[];
  role: "student" | "teacher";
  trainingId: string;
}

export const TrainingObjectives = ({ objectives, role, trainingId }: Props) => {
  return (
    <div className="flex justify-center mb-5">
      <div className="w-[90%]">
        <BackButton link={`/trainings/${trainingId}`} label="Suivi qualité de votre formation" />
        <div className="space-y-5 mt-5">
          {[...objectives]
            .sort((a, b) => a.number - b.number)
            .map(o => (
              <ObjectiveCard
                key={o.id}
                title={o.title}
                description={o.description}
                done={o.done}
                trainingId={trainingId}
                objectiveId={o.id}
                role={role}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
