import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { teacherRetrieveStudent } from "./retrieve-student";
import { TeacherStudentDetails } from "@user-management-context/read/domain/types/teacher/student";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<TeacherStudentDetails>> = { fetching: "idle", data: null };

export const teacherRetrieveStudentReducer = createReducer<RetrievalState<Nullable<TeacherStudentDetails>>>(initialState, builder => {
  builder.addCase(teacherRetrieveStudent.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(teacherRetrieveStudent.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(teacherRetrieveStudent.rejected, (state, { error }) => {
    if (error.message === "Request failed with status code 404") {
      return {
        ...state,
        data: null,
        fetching: "success",
      };
    }

    return {
      ...state,
      fetching: "failed",
    };
  });
});
