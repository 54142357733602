import { createReducer } from "@reduxjs/toolkit";
import { retrieveTrainingSessions } from "./retrieve-training-sessions";
import { TrainingSessionsRetrievalState } from "@redux/app-state";

const initialState: TrainingSessionsRetrievalState = { fetching: "idle", data: [] };

export const retrieveTrainingSessionsReducer = createReducer<TrainingSessionsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTrainingSessions.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTrainingSessions.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTrainingSessions.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
