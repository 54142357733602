import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminTrainingListVM } from "../../../../domain/types/training";

export const adminRetrieveTrainings = createAsyncThunk<AdminTrainingListVM[], void, { extra: Partial<Dependencies> }>(
  "trainings/adminRetrieveTrainings",
  async (_: void, { extra: { trainingGateway } }) => {
    return trainingGateway!.adminGetAll();
  }
);
