import React, { useEffect, useRef, useState } from "react";
import { default as ReactSignatureCanvas } from "react-signature-canvas";
import { Nullable } from "../../../../shared-kernel/core/types/nullable";
import { DangerSign } from "../../../../shared-kernel/primary/shared/danger-sign/danger-sign";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Check, Save, Trash } from "lucide-react";

interface Props {
  defaultSignatureUrl: Nullable<string>;
  signatureUrl: Nullable<string>;
  scheduledDate: Date;
  onSubmit: (url: string) => void;
}

const generateTitle = (isSignatureEnabled: boolean, isAlreadySigned: boolean, scheduledDate: Date) => {
  const date = scheduledDate.toLocaleDateString("fr-FR");
  if (isSignatureEnabled)
    return (
      <span className="flex items-center">
        <DangerSign />
        <strong className="ml-2 text-red-500">{date}</strong>
      </span>
    );
  if (isAlreadySigned)
    return (
      <span className="flex">
        <Check color="green" />
        <strong className="ml-2">{date}</strong>
      </span>
    );
  return <strong>{date}</strong>;
};

export const SignatureCanvas = ({ defaultSignatureUrl, onSubmit, signatureUrl, scheduledDate }: Props) => {
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const [isTouched, setIsTouched] = useState(false);

  const isAlreadySigned = Boolean(signatureUrl);
  const now = new Date().getTime();
  const isAfterScheduledDate = scheduledDate.getTime() <= now;
  const isSignatureEnabled = !isAlreadySigned && isAfterScheduledDate;
  const isDefaultSignatureEnabled = Boolean(defaultSignatureUrl);

  useEffect(() => {
    if (isSignatureEnabled) sigCanvas.current?.on();
    else sigCanvas.current?.off();
  }, [isSignatureEnabled]);

  const clear = () => {
    sigCanvas.current?.clear();
    setIsTouched(false);
  };

  const save = () => {
    const url = sigCanvas.current?.toDataURL("image/jpeg", {}) ?? null;
    if (url) onSubmit(url);
  };

  const setDefaultSignature = () => {
    if (defaultSignatureUrl) {
      onSubmit(defaultSignatureUrl);
    }
  };

  return (
    <div className="w-[400px]">
      <div className="flex justify-center">{generateTitle(isSignatureEnabled, isAlreadySigned, scheduledDate)}</div>
      <div className="mt-2">
        {isSignatureEnabled ? (
          <>
            <div className="flex justify-center">
              <div className="w-[360px] h-[170px] shadow-xl">
                <ReactSignatureCanvas
                  ref={sigCanvas}
                  canvasProps={{ width: 360, height: 170 }}
                  backgroundColor="rgb(255,255,255)"
                  onEnd={() => setIsTouched(true)}
                />
              </div>
            </div>
            <div className="w-full mt-2 flex justify-around">
              <div className="w-[140px] leading-none">
                {defaultSignatureUrl && (
                  <Button type="submit" disabled={!isDefaultSignatureEnabled} onClick={setDefaultSignature} className="px-0">
                    Utiliser la première signature
                  </Button>
                )}
              </div>
              <Button disabled={!isTouched} onClick={save} type="submit" className="px-1">
                <Save className="mr-2" size={16} />
                Enregistrer
              </Button>
              <Button disabled={!isTouched} onClick={clear} className="px-1">
                <Trash className="mr-2" size={16} />
                Effacer
              </Button>
            </div>
          </>
        ) : (
          <div className="w-full flex justify-center">
            <div className="w-[360px] h-[170px] shadow-xl">
              {isAlreadySigned ? (
                <img src={signatureUrl ?? undefined} alt="signature" className="w-full h-full" />
              ) : (
                <div className="w-full h-full bg-[#8080808e]"></div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
