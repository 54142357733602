import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateTransfer, updateTransfer } from "./update-transfer";
import { PaymentUpdateState } from "@redux/app-state";

const initialState: PaymentUpdateState = { justUpdatedPaymentId: null, fetching: "idle" };

export const updateTransferReducer = createReducer<PaymentUpdateState>(initialState, builder => {
  builder.addCase(updateTransfer.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedPaymentId: meta.arg.id,
      fetching: "success",
    };
  });
  builder.addCase(updateTransfer.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updateTransfer.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetUpdateTransfer, () => {
    return initialState;
  });
});
