import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { Dependencies } from "src/redux/store";

export const updateProviderInternship = createAsyncThunk<void, UpdateInternshipBody, { extra: Partial<Dependencies> }>(
  "providerInternships/updateProviderInternship",
  async (body: UpdateInternshipBody, { extra: { providerRepository } }) => {
    return providerRepository!.updateInternship(body);
  }
);

export const resetUpdateProviderInternship = createAction("providerInternships/resetUpdateProviderInternship");
