import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Student } from "../../../../domain/types/student/student";

export const retrieveStudentPublicProfile = createAsyncThunk<Student, string, { extra: Partial<Dependencies> }>(
  "students/retrieveStudentPublicProfile",
  async (userId: string, { extra: { userGateway } }) => {
    return userGateway!.publicStudentProfile(userId);
  }
);
