import { createReducer } from "@reduxjs/toolkit";
import { LessonCreateState } from "@redux/app-state";
import { createLesson, resetCreateLesson } from "./create-lesson";

const initialState: LessonCreateState = { justCreatedLessonId: null, fetching: "idle" };

export const createLessonReducer = createReducer<LessonCreateState>(initialState, builder => {
  builder.addCase(createLesson.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedLessonId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createLesson.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createLesson.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateLesson, () => {
    return initialState;
  });
});
