import { boolean, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { ProviderStudentInternshipInvitationBody } from "@user-management-context/shared/application/ports/provider-repository";
import { Nullable } from "@shared-kernel/core/types/nullable";

export type ProviderStudentInvitationFormInputs = {
  id: Nullable<string>;
  email: string;
  name: string;
  lastName: string;
  mobile: string;
  trainingId: string;
  internshipInterval: { start: string; end: string };
  isValidEmail: boolean;
};

export const providerStudentInvitationDefaultValues = {
  id: null,
  email: "",
  name: "",
  lastName: "",
  mobile: "",
  trainingId: "",
  internshipInterval: { start: "", end: "" },
  isValidEmail: true,
};

export const providerStudentInvitationSchema = object().shape({
  id: string().nullable(),
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  trainingId: string().max(255).required(REQUIRED_FIELD),
  internshipInterval: object().shape({
    start: string().required(REQUIRED_FIELD),
    end: string().required(REQUIRED_FIELD),
  }),
  isValidEmail: boolean().isTrue("Cet email est déjà utilisé par un autre utilisateur"),
});

export const formatFormDataToBodyData = (invitation: ProviderStudentInvitationFormInputs): ProviderStudentInternshipInvitationBody => {
  const {
    internshipInterval: { start, end },
    trainingId,
    name,
    lastName,
    mobile,
    email,
    id,
  } = invitation;

  const student =
    id != null
      ? {
          id,
          email: null,
          name: null,
          lastName: null,
          mobile: null,
        }
      : {
          id: null,
          email,
          name,
          lastName,
          mobile,
        };

  return {
    student,
    trainingId,
    internship: {
      interval: { start: new Date(start).toISOString(), end: new Date(end).toISOString() },
    },
  };
};
