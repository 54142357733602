import { TokenStorageGateway } from "../application/ports/token-storage-gateway";
import axios, { AxiosError, AxiosInstance } from "axios";
import { LoginTimeoutError, UnauthenticatedError, UnauthorizedError } from "../domain/errors/errors";

export abstract class HttpGateway {
  protected _instance: AxiosInstance;

  constructor(baseURL: string, private tokenStorageGateway: TokenStorageGateway) {
    HttpGateways.addGateway(this);
    this._instance = this.createInstance(baseURL);
  }

  private createInstance(baseURL: string): AxiosInstance {
    const instance = axios.create({ baseURL });
    this.setBearerToken(instance);
    this.addLogoutTokenInterceptor(instance);
    return instance;
  }

  public setBearerToken(instance: AxiosInstance = this._instance): void {
    const token = this.tokenStorageGateway.retrieveAccessToken();
    if (token) instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  private addLogoutTokenInterceptor(instance: AxiosInstance) {
    instance.interceptors.response.use(
      res => res,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          throw new UnauthenticatedError();
        }
        if (error.response?.status === 403) {
          throw new UnauthorizedError();
        }
        if (error.response?.status === 440) {
          throw new LoginTimeoutError();
        }
        throw error;
      }
    );
  }
}

export class HttpGateways {
  private static _gateways: HttpGateway[] = [];

  public static addGateway(gateway: HttpGateway): void {
    this._gateways.push(gateway);
  }

  public static setBearerToken() {
    for (const gateway of this._gateways) {
      gateway.setBearerToken();
    }
  }
}
