import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UserBody } from "../../../../write/domain/types/user";
import { UserWithAccessToken } from "../../../../read/domain/types/user";

export const loginUser = createAsyncThunk<UserWithAccessToken, UserBody, { extra: Partial<Dependencies> }>(
  "users/loginUser",
  async (user: UserBody, { extra: { userGateway } }) => {
    return userGateway!.login(user);
  }
);

export const logout = createAction<string | null>("users/logout");
