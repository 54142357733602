import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Progress } from "@shared-kernel/primary/shared/shadcn/ui/progress";

interface Props {
  convention: { student: string; hours: number; hoursDone: number };
}

export const ConventionCard = ({ convention }: Props) => {
  const { student, hours, hoursDone } = convention;

  return (
    <Card className="bg-primary-foreground text-white w-full dark:bg-primary-foreground dark:text-white mt-2">
      <CardHeader className="border-b border-gray-400 p-2">
        <CardTitle className="text-sm text-yellow-400">Nouvelle convention: {student}</CardTitle>
      </CardHeader>
      <CardContent className="flex py-3 px-2 items-center">
        <div className="w-full flex flex-col justify-center">
          <p className="text-xs mb-3">Les heures des nouvelles conventions sont payées en fin de mois</p>
          <Progress value={(hoursDone / hours) * 100} />
        </div>
        <div className="pl-1 flex flex-col items-center justify-center w-[60px] ml-4">
          <span className="text-2xl">
            {hoursDone}/{hours}
          </span>
          <span className="text-xs text-center">heures prises</span>
        </div>
      </CardContent>
    </Card>
  );
};
