import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UserVM } from "@user-management-context/read/domain/types/admin/user";

export const retrieveUnsubscribedUserList = createAsyncThunk<UserVM[], void, { extra: Partial<Dependencies> }>(
  "users/retrieveUnsubscribedUserList",
  async (_: void, { extra: { userGateway } }) => {
    return userGateway!.retrieveUnsubscribedUserList();
  }
);
