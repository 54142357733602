import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UseFormReset } from "react-hook-form";
import { FormInputs } from "./form-validation/convention";
import { resetCreateConvention } from "../../../write/application/use-cases/admin/convention-creation/create-convention";
import { retrieveConventions } from "../../../read/application/use-cases/admin/conventions-retrieval/retrieve-conventions";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveApprovedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/approved/retrieve-approved-funding-requests";

export const selectConventionCreate = (state: AppState) => state.conventionCreate;

interface Props {
  reset: UseFormReset<FormInputs>;
}

export const useConventionCreate = ({ reset }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedConventionId, fetching } = useAppSelector(selectConventionCreate);

  useEffect(() => {
    if (justCreatedConventionId) {
      toast({ description: "Convention ajouté!" });
      reset();
      dispatch(resetCreateConvention());
      dispatch(retrieveConventions());
      dispatch(retrieveStudents());
      dispatch(retrieveApprovedFundingRequests());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de l'ajout de la convention", variant: "destructive" });
    }
  }, [justCreatedConventionId, fetching, dispatch, toast, reset]);
};
