import { createReducer } from "@reduxjs/toolkit";
import { CancelAndDuplicateFundingRequestState } from "@redux/app-state";
import { cancelAndDuplicateFundingRequest } from "./cancel-and-duplicate-funding-request";

const initialState: CancelAndDuplicateFundingRequestState = { processing: "idle", duplicatedFundingRequestId: null };

export const cancelAndDuplicateFundingRequestReducer = createReducer<CancelAndDuplicateFundingRequestState>(initialState, builder => {
  builder.addCase(cancelAndDuplicateFundingRequest.fulfilled, (state, { payload }) => {
    return {
      ...state,
      duplicatedFundingRequestId: payload,
      processing: "success",
    };
  });
  builder.addCase(cancelAndDuplicateFundingRequest.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(cancelAndDuplicateFundingRequest.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
