import { ProviderInvitationListItem } from "@user-management-context/read/domain/types/provider/invitation";
import { ProviderInvitationBuilder } from "@user-management-context/test-utils/provider-student-invitation-builder";

const providerStudentInvitation: ProviderInvitationListItem = ProviderInvitationBuilder().build();
const providerStudentInvitation2: ProviderInvitationListItem = ProviderInvitationBuilder()
  .withId("student-2")
  .withStudent({ name: "Youssef", lastName: "F", email: "F@gmail.com", mobile: "+33656352678", isAlreadyExisting: false })
  .withTraining("TVi2")
  .withLastInternship({
    interval: {
      start: "2022-02-05T10:00:00.000Z",
      end: "2022-04-05T10:00:00.000Z",
    },
  })
  .build();
const providerStudentInvitation3: ProviderInvitationListItem = ProviderInvitationBuilder()
  .withId("student-3")
  .withStudent({ name: "Clélia", lastName: "T", email: "T@gmail.com", mobile: "+33656352678", isAlreadyExisting: false })
  .withTraining("TVi3")
  .withLastInternship({
    interval: {
      start: "2022-03-05T10:00:00.000Z",
      end: "2022-06-05T10:00:00.000Z",
    },
  })
  .build();
const providerStudentInvitation4: ProviderInvitationListItem = ProviderInvitationBuilder()
  .withId("student-4")
  .withStudent({ name: "Méz", lastName: "I", email: "I@gmail.com", mobile: "+33656352678", isAlreadyExisting: false })
  .withTraining("TVi4")
  .withLastInternship({
    interval: {
      start: "2022-01-05T10:00:00.000Z",
      end: "2022-04-05T10:00:00.000Z",
    },
  })
  .build();

export const providerStudentInvitations = [
  providerStudentInvitation3,
  providerStudentInvitation,
  providerStudentInvitation2,
  providerStudentInvitation4,
];
