import { createReducer } from "@reduxjs/toolkit";
import { StudentTrainingsRetrievalState } from "@redux/app-state";
import { resetStudentRetrieveTrainings, studentRetrieveTrainings } from "./retrieve-trainings";

const initialState: StudentTrainingsRetrievalState = { fetching: "idle", data: [] };

export const studentRetrieveTrainingsReducer = createReducer<StudentTrainingsRetrievalState>(initialState, builder => {
  builder.addCase(studentRetrieveTrainings.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveTrainings.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveTrainings.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetStudentRetrieveTrainings, () => {
    return initialState;
  });
});
