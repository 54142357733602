import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateProviderTraining, updateProviderTraining } from "./update-provider-training";
import { UpdateState } from "@redux/app-state";

const initialState: UpdateState = { justUpdatedId: null, processing: "idle" };

export const updateProviderTrainingReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(updateProviderTraining.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.trainingId,
      processing: "success",
    };
  });
  builder.addCase(updateProviderTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateProviderTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateProviderTraining, () => {
    return initialState;
  });
});
