import { createReducer } from "@reduxjs/toolkit";
import { LessonsRetrievalState } from "@redux/app-state";
import { retrieveLessons } from "./retrieve-lessons";

const initialState: LessonsRetrievalState = { fetching: "idle", data: [] };

export const retrieveLessonsReducer = createReducer<LessonsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveLessons.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveLessons.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveLessons.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
