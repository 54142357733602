import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { adminRetrieveProviderStudentInvitations } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/retrieve-provider-student-invitations";
import { AdminProviderStudentInvitation } from "@user-management-context/read/domain/types/admin/provider-student-invitation";

const initialState: RetrievalState<AdminProviderStudentInvitation[]> = { data: [], fetching: "idle" };

export const adminRetrieveProviderStudentInvitationsReducer = createReducer<RetrievalState<AdminProviderStudentInvitation[]>>(
  initialState,
  builder => {
    builder.addCase(adminRetrieveProviderStudentInvitations.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(adminRetrieveProviderStudentInvitations.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(adminRetrieveProviderStudentInvitations.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
