import { createReducer } from "@reduxjs/toolkit";
import { FundingRequestEligibilityRetrievalState } from "@redux/app-state";
import { resetRetrieveFundingRequestEligibility, retrieveFundingRequestEligibility } from "./retrieve-funding-request-eligibility";

const initialState: FundingRequestEligibilityRetrievalState = { fetching: "idle", data: null };

export const retrieveFundingRequestEligibilityReducer = createReducer<FundingRequestEligibilityRetrievalState>(initialState, builder => {
  builder.addCase(retrieveFundingRequestEligibility.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveFundingRequestEligibility.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveFundingRequestEligibility.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetRetrieveFundingRequestEligibility, () => {
    return initialState;
  });
});
