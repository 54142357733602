import { UpdatePaymentBody } from "@academy-context/write/domain/types/admin/payment";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const updateTransfer = createAsyncThunk<string, { id: string; body: UpdatePaymentBody }, { extra: Partial<Dependencies> }>(
  "transfers/updateTransfer",
  async ({ id, body }: { id: string; body: UpdatePaymentBody }, { extra: { transferGateway } }) => {
    return transferGateway!.update(id, body);
  }
);

export const resetUpdateTransfer = createAction("transfers/resetUpdateTransfer");
