import React from "react";
import clsx from "clsx";
import { has8Characters, hasANumber, hasUppercaseAndLowercase } from "../../../write/domain/services/password";
import { Check, X } from "lucide-react";

interface Props {
  password: string;
}

export const PasswordCreationValidation = ({ password }: Props) => {
  const rule1 = has8Characters(password);
  const rule2 = hasUppercaseAndLowercase(password);
  const rule3 = hasANumber(password);

  return (
    <ul className="space-y-1">
      <li className={clsx("flex items-center", rule1 && "text-[#4f8f46]")}>
        {rule1 ? <Check /> : <X />}
        <span className="font-semibold text-sm ml-2">8 caractères</span>
      </li>
      <li className={clsx("flex items-center", rule2 && "text-[#4f8f46]")}>
        {rule2 ? <Check /> : <X />}
        <span className="font-semibold text-sm ml-2">Majuscule et minuscule</span>
      </li>
      <li className={clsx("flex items-center", rule3 && "text-[#4f8f46]")}>
        {rule3 ? <Check /> : <X />}
        <span className="font-semibold text-sm ml-2">1 chiffre</span>
      </li>
    </ul>
  );
};
