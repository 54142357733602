import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Teacher } from "../../../../domain/types/admin/teacher";

export const retrieveTeacherPublicProfile = createAsyncThunk<Teacher, string, { extra: Partial<Dependencies> }>(
  "teachers/retrieveTeacherPublicProfile",
  async (userId: string, { extra: { userGateway } }) => {
    return userGateway!.publicTeacherProfile(userId);
  }
);
