import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UpdateStudentBody } from "@user-management-context/write/domain/types/student/student";

export const updateStudentProfile = createAsyncThunk<string, UpdateStudentBody, { extra: Partial<Dependencies> }>(
  "students/updateStudentProfile",
  async (body: UpdateStudentBody, { extra: { studentGateway } }) => {
    return studentGateway!.update(body);
  }
);

export const resetUpdateStudentProfile = createAction("students/resetUpdateStudentProfile");
