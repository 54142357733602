import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";
import { retrieveProviderStudentInvitations } from "@user-management-context/read/application/use-cases/provider/student-invitations-retrieval/retrieve-provider-student-invitations";

const selectStudentInternshipInvitation = (state: AppState) => state.studentInternshipInvitation;

export const useInternshipInvitationSent = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useSelector(selectStudentInternshipInvitation);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Invitation envoyée!" });
      dispatch(retrieveProviderStudentInvitations());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'envoi de l'invitation", variant: "destructive" });
    }
  }, [processing, dispatch, toast]);
};
