import { createReducer } from "@reduxjs/toolkit";
import { StudentRetrievalState } from "@redux/app-state";
import { resetRetrieveStudent, retrieveStudent } from "./retrieve-student";

const initialState: StudentRetrievalState = { fetching: "idle", data: null };

export const retrieveStudentReducer = createReducer<StudentRetrievalState>(initialState, builder => {
  builder.addCase(retrieveStudent.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveStudent.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveStudent.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetRetrieveStudent, () => {
    return initialState;
  });
});
