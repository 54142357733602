import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";

export const retrieveProviderInternships = createAsyncThunk<ProviderInternship[], void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderInternships",
  async (_, { extra: { providerRepository } }) => {
    return providerRepository!.internshipList();
  }
);
