import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AcceptProviderStudentInvitationBody } from "@user-management-context/shared/application/ports/admin-provider-repository";
import { Dependencies } from "src/redux/store";

export const acceptProviderStudentInvitation = createAsyncThunk<
  void,
  { id: string; body: AcceptProviderStudentInvitationBody },
  { extra: Partial<Dependencies> }
>(
  "providerStudentInvitations/acceptProviderStudentInvitation",
  async ({ id, body }: { id: string; body: AcceptProviderStudentInvitationBody }, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.acceptStudentInvitation(id, body);
  }
);

export const resetAcceptProviderStudentInvitation = createAction("providerStudentInvitations/resetAcceptProviderStudentInvitation");
