import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { StandardPayment } from "../../../../../read/domain/types/admin/payment";
import { UpdateTransferModal } from "./update-transfer-modal";
import { formatDateToLocale, formatToCurrency } from "../../../../../../utils/formatting";
import { LessonListModal } from "./lesson-list-modal";
import { retrievePaymentLessonList } from "../../../../../read/application/use-cases/admin/payment-lesson-list-retrieval/retrieve-payment-lesson-list";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectPaymentLessonListRetrieval } from "../../../../../read/application/use-cases/admin/payment-lesson-list-retrieval/selectors/payment-lesson-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { CustomCard } from "../../../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { Eye } from "lucide-react";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";

const columns: ColumnDef<StandardPayment>[] = [
  {
    accessorKey: "from",
    header: () => "Du",
    cell: ({ row }) => formatDateToLocale(row.original.from),
    size: 40,
    meta: {
      title: "Du",
    },
    enableSorting: true,
  },
  {
    accessorKey: "to",
    header: () => "Au",
    cell: ({ row }) => formatDateToLocale(row.original.to),
    size: 40,
    meta: {
      title: "Au",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "amount",
    header: () => "Montant à verser",
    cell: ({ row }) => formatToCurrency(row.original.amount),
    size: 40,
    meta: {
      title: "Montant à verser",
    },
    enableSorting: true,
  },
  {
    accessorKey: "amountPaid",
    header: () => "Montant versé",
    cell: ({ row }) => (row.original.amountPaid != null ? formatToCurrency(row.original.amountPaid) : ""),
    size: 40,
    meta: {
      title: "Montant versé",
    },
    enableSorting: true,
  },
  {
    accessorKey: "reference",
    header: () => "Référence du virement",
    size: 120,
  },
  {
    accessorKey: "paymentDate",
    header: () => "Date du virement",
    cell: ({ row }) => {
      const value = row.original.paymentDate;
      return value ? formatDateToLocale(value) : null;
    },
    size: 40,
    meta: {
      title: "Date du virement",
    },
    enableSorting: true,
  },
];

interface Props {
  transfers: StandardPayment[];
  title: string;
  cardClassname?: string;
  displayFromTo?: boolean;
}

export const TransfersTable = ({ displayFromTo = true, transfers, title, cardClassname }: Props) => {
  const dispatch = useAppDispatch();
  const [dataColumns, setDataColumns] = useState<any[]>([...columns]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const currentSelectedLineInfo = useRef<StandardPayment | null>(null);
  const currentSelectedLineIsChecked = useRef<boolean>(false);
  const [isLessonListModalOpen, setIsLessonListModalOpen] = useState<boolean>(false);
  const selectedPaymentId = useRef<string | null>(null);
  const { data: lessons } = useAppSelector(selectPaymentLessonListRetrieval);

  useEffect(() => {
    if (isLessonListModalOpen && selectedPaymentId.current) {
      dispatch(retrievePaymentLessonList(selectedPaymentId.current));
    } else {
      selectedPaymentId.current = null;
    }
  }, [dispatch, isLessonListModalOpen]);

  useEffect(() => {
    const statusColumn: ColumnDef<StandardPayment> = {
      accessorKey: "status",
      header: () => "Virement effectué",
      size: 40,
      meta: {
        title: "Virement effectué",
      },
      enableSorting: true,
      cell: ({ row }) => {
        const transfer = row.original;
        const isPaid = row.original.status === PaymentStatus.PAID;
        return (
          <Checkbox
            id={transfer.id}
            defaultChecked={isPaid}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              setIsModalOpen(true);
              currentSelectedLineInfo.current = transfer;
              currentSelectedLineIsChecked.current = isPaid;
            }}
          />
        );
      },
    };

    const lessonListPreviewColumn: ColumnDef<StandardPayment> = {
      accessorKey: "id",
      header: () => "Cours",
      size: 90,
      enableSorting: false,
      cell: ({ row }) => (
        <div className="flex justify-center">
          <Eye
            className="px-1 py-0 cursor-pointer"
            onClick={() => {
              selectedPaymentId.current = row.original.id;
              setIsLessonListModalOpen(true);
            }}
          />
        </div>
      ),
    };
    setDataColumns([...columns, statusColumn, lessonListPreviewColumn]);
  }, [transfers]);

  const c = displayFromTo ? dataColumns : dataColumns.filter(c => c.dataField !== "from" && c.dataField !== "to");
  return (
    <CustomCard title={title}>
      {currentSelectedLineInfo.current && (
        <UpdateTransferModal
          transfer={currentSelectedLineInfo.current}
          isPaid={currentSelectedLineIsChecked.current}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <LessonListModal lessons={lessons} isOpen={isLessonListModalOpen} onClose={() => setIsLessonListModalOpen(false)} />
      <DataTable columns={c} data={transfers} sortField="from" order="desc" searchPlaceHolder="Rechercher un paiement" pageSize={50} />
    </CustomCard>
  );
};
