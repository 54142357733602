import { CreateLessonBody } from "@academy-context/write/domain/types/teacher/lesson";
import { AnySchema, mixed, number, object, string } from "yup";
import {
  MAX_HOURLY_PRICE,
  MAX_LESSON_HOURS,
  TEACHER_MIN_HOURLY_PRICE,
  MIN_LESSON_HOURS,
  ADMIN_MIN_HOURLY_PRICE,
  MIN_STUDIO_PRICE,
  MAX_STUDIO_PRICE,
} from "../../../../write/domain/constants/shared";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { PLACE_VALUE } from "../../../../read/domain/services/locations";
import { AnyObject } from "yup/lib/types";
import { Nullable } from "../../../../../shared-kernel/core/types/nullable";
import { STUDIO_CHOICES } from "@academy-context/read/domain/types/admin/lesson";

export type FormInputs = {
  date: string;
  student: string;
  hours: number;
  hourlyPrice: number;
  placeOption: STUDIO_CHOICES;
  placeName: Nullable<PLACE_VALUE>;
  placePrice: number;
  commentary: string;
};

export const defaultValues: FormInputs = {
  date: "",
  student: "",
  hours: 1,
  hourlyPrice: TEACHER_MIN_HOURLY_PRICE,
  commentary: "",
  placeOption: STUDIO_CHOICES.FREE,
  placeName: PLACE_VALUE.PERSO,
  placePrice: 0,
};

const setMaxHours = (hoursLeftOnConvention: number | null, schema: AnyObject) =>
  hoursLeftOnConvention != null && hoursLeftOnConvention < MAX_LESSON_HOURS
    ? schema["max"](hoursLeftOnConvention, `Maximum ${hoursLeftOnConvention}h`)
    : schema["max"](MAX_LESSON_HOURS, `Maximum ${MAX_LESSON_HOURS}h`);

const setMinHourlyPrice = (hoursLeftOnConvention: number | null, schema: AnyObject) =>
  !hoursLeftOnConvention
    ? schema["min"](TEACHER_MIN_HOURLY_PRICE, `Minimum ${TEACHER_MIN_HOURLY_PRICE}€`)
    : schema["min"](ADMIN_MIN_HOURLY_PRICE, `Minimum ${ADMIN_MIN_HOURLY_PRICE}€`);

export const schema = object().shape({
  date: string().max(255).required(REQUIRED_FIELD),
  student: string().max(255).required(REQUIRED_FIELD),
  hours: number()
    .typeError("Minimum 0,25h")
    .min(MIN_LESSON_HOURS, "Minimum 0,25h")
    .when("$hoursLeftOnConvention", setMaxHours)
    .required(REQUIRED_FIELD),
  hourlyPrice: number()
    .typeError(`Minimum ${TEACHER_MIN_HOURLY_PRICE}€`)
    .positive(`Minimum ${TEACHER_MIN_HOURLY_PRICE}€`)
    .when("$hoursLeftOnConvention", setMinHourlyPrice)
    .max(MAX_HOURLY_PRICE, `Maximum ${MAX_HOURLY_PRICE}€`)
    .required(REQUIRED_FIELD),
  placeOption: mixed<STUDIO_CHOICES>().oneOf(Object.values(STUDIO_CHOICES)),
  placeName: string()
    .max(255)
    .when("placeOption", {
      is: (value: STUDIO_CHOICES) => {
        return value !== STUDIO_CHOICES.PAID_BY_TEACHER;
      },
      then: (schema: AnySchema) => schema.required(REQUIRED_FIELD),
      otherwise: (schema: AnySchema) => schema.nullable(),
    }),
  placePrice: number()
    .typeError(`Minimum ${MIN_STUDIO_PRICE}€`)
    .positive(`Minimum ${MAX_STUDIO_PRICE}€`)
    .min(MIN_STUDIO_PRICE, `Minimum ${MIN_STUDIO_PRICE}€`)
    .max(MAX_STUDIO_PRICE, `Maximum ${MAX_STUDIO_PRICE}€`)
    .when("placeOption", {
      is: (value: STUDIO_CHOICES) => {
        return value === STUDIO_CHOICES.PAID_BY_TEACHER;
      },
      then: (schema: AnySchema) => schema.required("Le prix ou la valeur doit être remplie"),
      otherwise: (schema: AnySchema) => schema.nullable(),
    }),
  commentary: string().max(240),
});

export const formatFormDataToBodyData = (lesson: FormInputs, isUnderOldConvention: boolean): CreateLessonBody => {
  let place: {
    id: Nullable<PLACE_VALUE>;
    price: Nullable<number>;
  };
  if (lesson.placeOption === STUDIO_CHOICES.FREE) {
    place = { id: PLACE_VALUE.PERSO, price: null };
  } else if (lesson.placeOption === STUDIO_CHOICES.PAID_BY_TEACHER) {
    place = { id: null, price: lesson.placePrice };
  } else if (lesson.placeOption === STUDIO_CHOICES.PAID_BY_OPERA_OFF && lesson.placeName) {
    place = { id: lesson.placeName, price: null };
  } else {
    throw new Error("Wrong studio choice");
  }
  return {
    date: lesson.date,
    studentId: lesson.student,
    hours: { quantity: lesson.hours, hourlyPrice: isUnderOldConvention ? null : lesson.hourlyPrice },
    commentary: lesson.commentary,
    place,
  };
};
