import { Nullable } from "../../../../shared-kernel/core/types/nullable";
import { placeById, PLACE_VALUE } from "../../../read/domain/services/locations";

export const findLocation = (location: PLACE_VALUE): Nullable<string> => {
  const res = placeById.get(location);
  return res?.label ?? null;
};

export const getLocationPrice = (location: PLACE_VALUE): Nullable<number> => {
  const res = placeById.get(location);
  return res?.price ?? null;
};

export const getPlaceName = (paidByTeacher: boolean, name: Nullable<PLACE_VALUE>): string => {
  if (paidByTeacher || name === null) {
    if (name === PLACE_VALUE.PERSO) return findLocation(PLACE_VALUE.PERSO)!;
    else return "Payé par le professeur";
  } else {
    return findLocation(name)!;
  }
};
