import { Dependencies } from "@redux/store";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateProviderTrainingBody } from "@user-management-context/shared/application/ports/provider-repository";

export const updateProviderTraining = createAsyncThunk<void, UpdateProviderTrainingBody, { extra: Partial<Dependencies> }>(
  "providerTrainings/updateProviderTraining",
  async (body: UpdateProviderTrainingBody, { extra: { providerRepository } }) => {
    return providerRepository!.updateTraining(body);
  }
);

export const resetUpdateProviderTraining = createAction("providerTrainings/resetUpdateProviderTraining");
