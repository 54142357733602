import { AFDAS_FUNDER } from "@academy-context/primary/admin/funding-request-detail/form-validation/afdas-funding-request";
import { Nullable } from "../../../../../shared-kernel/core/types/nullable";
import {
  instantFunderMap,
  instantFunderOptions,
} from "@academy-context/primary/admin/budget-detail/form-validation/funding-request/instant-funding-request";
import { INSTANT_FUNDERS } from "@academy-context/shared/domain/types/enums/instant-funders";

export const funderOptions: { label: string; value: string }[] = [
  {
    label: "AFDAS",
    value: AFDAS_FUNDER,
  },
  ...instantFunderOptions,
];

export const getFunderLabel = (funder: INSTANT_FUNDERS | typeof AFDAS_FUNDER | string): string => {
  const isAfdasFunder = funder === AFDAS_FUNDER;
  if (isAfdasFunder) return "AFDAS";
  const label = instantFunderMap[funder as INSTANT_FUNDERS]?.label;
  if (label) return label;
  return funder;
};

export interface Budget {
  id: string;
  obtentionDate: string;
  internshipEndDate: string;
  studentId: string;
  funder: INSTANT_FUNDERS | typeof AFDAS_FUNDER | string;
  allocatedAmount: number;
  operaOffCommission: number;
  expirationTimeInMonths: Nullable<number>;
  createdAt: string;
  commentary: Nullable<string>;
  externalFundingRequestId: Nullable<string>;
  fundingRequestId: Nullable<string>;
}

export interface AdminBudgetListVM {
  id: string;
  creationDate: string;
  internshipEndDate: string;
  student: string;
  funder: INSTANT_FUNDERS | typeof AFDAS_FUNDER | string;
  allocatedAmount: number;
  operaOffCommission: number;
  totalAmount: number;
  commentary: string;
  externalFundingRequestId: Nullable<string>;
}

export type AdminBudgetExtended = AdminBudgetListVM & { localeDate: string };
