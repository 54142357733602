import { createReducer } from "@reduxjs/toolkit";
import { retrieveUserInvitations } from "./retrieve-user-invitations";
import { UserInvitationsRetrievalState } from "@redux/app-state";

const initialState: UserInvitationsRetrievalState = { data: [], fetching: "idle" };

export const retrieveUserInvitationsReducer = createReducer<UserInvitationsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveUserInvitations.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveUserInvitations.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveUserInvitations.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
