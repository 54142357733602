import { AnySchema, object, string } from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { validatePassword } from "../../../../write/domain/services/password";
import { Student } from "../../../../read/domain/types/student/student";
import { CreateStudentAccountBody } from "@user-management-context/write/domain/types/admin/student";

export type StudentAccountCreationValidation = "student" | "admin";

export type StudentAccountCreationFormInputs = {
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  password: string;
};

export const defaultValues: StudentAccountCreationFormInputs = {
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
};

const requiredWhenAdminValidation = {
  is: (value: StudentAccountCreationValidation) => {
    return value === "admin";
  },
  then: (schema: AnySchema) => schema.required(REQUIRED_FIELD),
  otherwise: (schema: AnySchema) => schema.nullable(),
};

const requiredPasswordWhenStudentValidation = {
  is: (value: StudentAccountCreationValidation) => {
    return value === "student";
  },
  then: (schema: AnySchema) =>
    schema.required(REQUIRED_FIELD).test("Mot de passe valide", "Le mot de passe n'est pas valide", (password: string) => {
      return validatePassword(password);
    }),
  otherwise: (schema: AnySchema) => schema.nullable(),
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  email: string().email("Email incorrect").when("$validation", requiredWhenAdminValidation),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  password: string().when("$validation", requiredPasswordWhenStudentValidation),
});

export const studentAccountCreationFormatDataToFormData = (student: Student): StudentAccountCreationFormInputs => {
  return {
    ...defaultValues,
    name: student.name ?? "",
    lastName: student.lastName ?? "",
    email: student.email,
    mobile: student.mobile ?? "",
  };
};

export const studentAccountCreationFormatFormDataToBodyData = (
  student: StudentAccountCreationFormInputs,
  userId: string,
  token: string
): CreateStudentAccountBody => {
  return {
    userId,
    token,
    name: student.name,
    lastName: student.lastName,
    mobile: student.mobile,
    password: student.password,
  };
};
