import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminProviderStudentInvitation } from "@user-management-context/read/domain/types/admin/provider-student-invitation";

export const adminRetrieveProviderStudentInvitations = createAsyncThunk<
  AdminProviderStudentInvitation[],
  void,
  { extra: Partial<Dependencies> }
>("users/adminRetrieveProviderStudentInvitations", async (_: void, { extra: { adminProviderRepository } }) => {
  return adminProviderRepository!.studentInvitationList();
});
