import { AdminProviderStudentInvitation } from "@user-management-context/read/domain/types/admin/provider-student-invitation";
import { AdminProviderStudentInvitationBuilder } from "@user-management-context/test-utils/admin-provider-student-invitation-builder";

const providerStudentInvitation: AdminProviderStudentInvitation = AdminProviderStudentInvitationBuilder().build();
const providerStudentInvitation2: AdminProviderStudentInvitation = AdminProviderStudentInvitationBuilder()
  .withId("invitation-2")
  .withCreationDate("2022-10-02T03:00:00Z")
  .withStudent({
    name: "Youssef",
    lastName: "Farkhani",
    email: "yf@gmail.com",
    mobile: "0614366624",
    isAlreadyExisting: false,
  })
  .build();
const providerStudentInvitation3: AdminProviderStudentInvitation = AdminProviderStudentInvitationBuilder()
  .withId("invitation-3")
  .withCreationDate("2022-10-03T03:00:00Z")
  .withStudent({
    name: "Clélia",
    lastName: "Timsit",
    email: "ct@gmail.com",
    mobile: "0614366625",
    isAlreadyExisting: false,
  })
  .build();

export const adminProviderStudentInvitations = [providerStudentInvitation3, providerStudentInvitation, providerStudentInvitation2];
