import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CollaborationRequestBody } from "@academy-context/write/domain/types/teacher/collaboration-request";

export const requestCollaboration = createAsyncThunk<void, CollaborationRequestBody, { extra: Partial<Dependencies> }>(
  "collaborations/requestCollaboration",
  async (collaborationRequest: CollaborationRequestBody, { extra: { collaborationRequestGateway } }) => {
    return collaborationRequestGateway!.request(collaborationRequest);
  }
);

export const resetRequestCollaboration = createAction("collaborations/resetRequestCollaboration");
