import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues } from "./form-validation/user-login";
import { useUserLogin } from "./use-user-login";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Eye, Loader2, EyeOff, LogIn } from "lucide-react";
import { FetchingState } from "src/redux/app-state";
import clsx from "clsx";

interface Props {
  onSubmit: (formBody: FormInputs) => void;
  onDisplayResetPasswordForm: () => void;
  fetching: FetchingState;
}

const LoginForm = ({ onSubmit, onDisplayResetPasswordForm, fetching }: Props) => {
  useUserLogin();
  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const {
    formState: { isDirty },
  } = methods;

  const EyeIcon = showPassword ? Eye : EyeOff;

  const LoginIcon = fetching === "pending" ? Loader2 : LogIn;

  return (
    <Form {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full">
        <div className="space-y-2">
          <FormField
            control={methods.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} className="text-black" type="email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={methods.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Mot de passe</FormLabel>
                <FormControl>
                  <div className="relative">
                    <EyeIcon
                      className="absolute right-2 top-[50%] translate-y-[-50%] cursor-pointer"
                      color="black"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                    <Input {...field} className="text-black" type={showPassword ? "text" : "password"} />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center justify-between gap-x-2">
            <div className="w-[100px] lg:w-auto">
              <a href="#link" onClick={onDisplayResetPasswordForm} role="button" className="text-sm hover:font-semibold">
                Mot de passe oublié?
              </a>
            </div>
            <Button disabled={!isDirty} type="submit">
              <LoginIcon className={clsx("mr-2 h-4 w-4", fetching === "pending" && "animate-spin")} />
              Se connecter
            </Button>
          </div>
        </div>
        <div className="mt-8">
          <p className="text-yellow-300">
            * Safari peut présenter des soucis d'affichage du site, préférez les navigateurs Chrome ou Firefox.
          </p>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
