import { CreateExpenseBody } from "@academy-context/write/domain/types/admin/expense";
import { number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export const MAX_EXPENSE_DESCRIPTION_CHARACTERS = 255;
export const MAX_EXPENSE_PRICE = 4000;
export const MIN_EXPENSE_PRICE = 0;

export type FormInputs = {
  date: string;
  student: string;
  type: { label: string; value: string };
  price: number;
  description: string;
};

export const defaultValues = {
  date: "",
  student: "",
  type: { value: "", label: "" },
  price: 0,
  description: "",
};

export const schema = object().shape({
  date: string().max(255).required(REQUIRED_FIELD),
  student: string().max(255).required(REQUIRED_FIELD),
  type: object().shape({
    label: string().max(255).required(REQUIRED_FIELD),
    value: string().max(255).required(REQUIRED_FIELD),
  }),
  price: number()
    .typeError("Une valeur est nécessaire")
    .min(MIN_EXPENSE_PRICE, `minimum ${MIN_EXPENSE_PRICE}€`)
    .max(MAX_EXPENSE_PRICE, `maximum ${MAX_EXPENSE_PRICE}€`)
    .required(REQUIRED_FIELD),
  description: string().max(MAX_EXPENSE_DESCRIPTION_CHARACTERS),
});

export const formatFormDataToBodyData = (expense: FormInputs): CreateExpenseBody => {
  return {
    date: expense.date,
    studentId: expense.student,
    type: expense.type.value,
    price: expense.price,
    description: expense.description,
  };
};
