import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateUser, updateUser } from "./update-user";
import { UserUpdateState } from "@redux/app-state";

const initialState: UserUpdateState = { fetching: "idle" };

export const updateUserReducer = createReducer<UserUpdateState>(initialState, builder => {
  builder.addCase(updateUser.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(updateUser.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updateUser.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetUpdateUser, () => {
    return initialState;
  });
});
