import { isLoadingState } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { AdminProviderStudentInvitation } from "@user-management-context/read/domain/types/admin/provider-student-invitation";

export interface ProviderStudentInvitationRow {
  id: string;
  student: {
    fullName: string;
    email: string;
  };
  providerTag: string;
  training: string;
  internship: { interval: string };
  creationDate: string;
  formattedCreationDate: string;
  originalData: AdminProviderStudentInvitation;
}

export const adminSelectProviderStudentInvitationsRetrievalForList = (state: AppState) => {
  const {
    adminProviderStudentInvitationsRetrieval: { data, fetching },
  } = state;

  const formattedData: ProviderStudentInvitationRow[] = [...data]
    .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
    .map(i => ({
      id: i.id,
      creationDate: i.creationDate,
      formattedCreationDate: new Date(i.creationDate).toLocaleDateString("fr-FR"),
      student: {
        email: i.student.email,
        fullName: `${i.student.name} ${i.student.lastName}`,
      },
      providerTag: i.providerTag,
      training: i.training,
      internship: {
        interval: `${formatToDayAndMonth(i.lastInternship.interval.start)} - ${formatToDayAndMonth(i.lastInternship.interval.end)}`,
      },
      originalData: i,
    }));

  return {
    invitations: formattedData,
    isLoading: isLoadingState(fetching),
  };
};

const formatToDayAndMonth = (date: string): string => {
  return new Date(date).toLocaleDateString("fr-FR");
};
