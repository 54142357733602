import React from "react";
import { formatToCurrency } from "../../../../../../utils/formatting";
import { Dialog, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ScrollableDialogContent } from "../../../../../../shared-kernel/primary/shared/scrollable-dialog.tsx/scrollable-dialog";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@shared-kernel/primary/shared/shadcn/ui/hover-card";
import { SimpleTable } from "@shared-kernel/primary/shared/simple-table";

interface Props {
  invoices: Refund["invoices"];
  isOpen: boolean;
  onClose: () => void;
}

interface TableProps {
  data: Refund["invoices"];
}

const InvoiceTable = ({ data }: TableProps) => {
  const headers = [{ title: "Montant" }, { title: "URL" }];
  const entries = data.map(d => {
    return [
      { value: formatToCurrency(d.amount) },
      {
        value: (
          <HoverCard>
            <HoverCardTrigger asChild>
              <a href={d.url} target="_blank" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" rel="noreferrer">
                Facture
              </a>
            </HoverCardTrigger>
            <HoverCardContent side="left" align="end" className="w-[500px] h-[690px]">
              <div className="w-full h-full">
                <iframe src={`${d.url}#navpanes=0&scrollbar=0`} height="100%" width="100%" title="Facture" />
              </div>
            </HoverCardContent>
          </HoverCard>
        ),
      },
    ];
  });

  return <SimpleTable headers={headers} entries={entries} />;
};

export const InvoiceListModal = ({ invoices, isOpen, onClose }: Props) => {
  return (
    <Dialog open={isOpen}>
      <ScrollableDialogContent onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Liste des factures</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <InvoiceTable data={invoices} />
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Fermer
          </Button>
        </DialogFooter>
      </ScrollableDialogContent>
    </Dialog>
  );
};
