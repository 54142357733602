import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import {
  InternshipFormInputs,
  defaultInternshipValues,
  internshipSchema,
  formatFormDataToBodyData,
  formatInternshipDataToFormData,
} from "@academy-context/primary/shared/provider-internship/form-validation/internship/internship";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { ConfirmInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

interface Props {
  internship: ProviderInternship;
  isOpen: boolean;
  onClose: () => void;
  providerTrainings: { id: string; title: string }[];
  onConfirmInternship: (body: ConfirmInternshipBody) => void;
  onUpdateInternship: (body: ConfirmInternshipBody) => void;
}

export const ConfirmInternshipModal = ({
  internship,
  isOpen,
  onClose,
  providerTrainings,
  onConfirmInternship,
  onUpdateInternship,
}: Props) => {
  const form = useForm<InternshipFormInputs>({ resolver: yupResolver(internshipSchema), defaultValues: defaultInternshipValues });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = form;
  // This means it already has been filled
  const isUpdateForm = Boolean(internship.details.price);

  const handleOnSubmit = async (formBody: InternshipFormInputs) => {
    onClose();
    const body = formatFormDataToBodyData(formBody);
    if (isUpdateForm) onUpdateInternship(body);
    else onConfirmInternship(body);
  };

  useEffect(() => {
    reset(formatInternshipDataToFormData(internship));
  }, [internship, reset, isOpen]);

  const today = new Date().toISOString();

  const studentLabel = `${internship.student.name} ${internship.student.lastName}`;

  const trainingTitleOptions: ComboboxOptions[] = providerTrainings.map(t => ({
    label: t.title,
    value: t.title,
  }));

  const doesTrainingBelongToProvider = providerTrainings.some(training => training.title === internship.details.training);
  if (!doesTrainingBelongToProvider)
    trainingTitleOptions.push({
      label: internship.details.training,
      value: internship.details.training,
    });

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...form}>
        <Dialog open={isOpen}>
          <DialogContent className="sm:min-w-[425px]" onPointerDownOutside={onClose}>
            <DialogHeader>
              <DialogTitle>Confirmer stage</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Elève
                </Label>
                <Input id="teacher" value={studentLabel} className="col-span-3" disabled />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amountPaid" className="text-right">
                  Formation
                </Label>
                <FormField
                  control={form.control}
                  name="training"
                  render={({ field }) => (
                    <FormItem className="flex flex-col col-span-3">
                      <FormControl>
                        <Combobox
                          options={trainingTitleOptions}
                          value={watch("training")}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner formation..."
                          search={{
                            notFoundText: "Pas de formation trouvé.",
                            commandInputPlaceHolder: "Chercher formation...",
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="amountPaid" className="text-right">
                  Prix
                </Label>
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem className="flex flex-col col-span-3">
                      <FormControl>
                        <Input type="number" min={0} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Date de début
                </Label>
                <FormField
                  control={form.control}
                  name="startDate"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-col col-span-3">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="teacher" className="text-right">
                  Date de fin
                </Label>
                <FormField
                  control={form.control}
                  name="endDate"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex flex-col col-span-3">
                        <PopoverCalendar value={field.value ?? today} onChange={value => field.onChange(value)} />
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={onClose} variant="outline">
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty} onClick={handleSubmit(d => handleOnSubmit(d))}>
                Confirmer stage
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </FormProvider>
    </form>
  );
};
