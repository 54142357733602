import { createReducer } from "@reduxjs/toolkit";
import { RefundRequestState } from "@redux/app-state";
import { requestRefund, resetRequestRefund } from "./request-refund";

const initialState: RefundRequestState = { fetching: "idle" };

export const requestRefundReducer = createReducer<RefundRequestState>(initialState, builder => {
  builder.addCase(requestRefund.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(requestRefund.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(requestRefund.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetRequestRefund, () => {
    return initialState;
  });
});
