import { getFunderLabel } from "@academy-context/read/domain/types/admin/budget";
import { AppState } from "@redux/app-state";

export const selectBudgetsRetrievalForList = (state: AppState) => {
  const {
    budgetsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.internshipEndDate).getTime();
    const dateBInMs = new Date(b.internshipEndDate).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  return {
    data: sortedData.map(b => {
      return {
        ...b,
        funder: getFunderLabel(b.funder),
        localeDate: new Date(b.internshipEndDate).toLocaleDateString("fr-FR"),
      };
    }),
    fetching,
  };
};
