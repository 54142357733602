import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherLessonListVM } from "../../../../domain/types/teacher/lesson";

export const retrieveLessons = createAsyncThunk<TeacherLessonListVM[], void, { extra: Partial<Dependencies> }>(
  "lessons/retrieveLessons",
  async (_: void, { extra: { teacherLessonGateway } }) => {
    return teacherLessonGateway!.all();
  }
);
