import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import {
  studentRetrieveProviderTrainings,
  resetStudentRetrieveProviderTrainings,
} from "@academy-context/read/application/use-cases/student/provider-trainings-retrieval/retrieve-provider-trainings";

const initialState: RetrievalState<ProviderTraining[]> = { data: [], fetching: "idle" };

export const studentRetrieveProviderTrainingsReducer = createReducer<RetrievalState<ProviderTraining[]>>(initialState, builder => {
  builder.addCase(studentRetrieveProviderTrainings.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveProviderTrainings.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveProviderTrainings.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetStudentRetrieveProviderTrainings, () => {
    return initialState;
  });
});
