import React, { useMemo } from "react";
import { AdminExpenseExtended } from "../../../read/application/use-cases/admin/expenses-retrieval/selectors/shared";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Link } from "react-router-dom";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { SimpleTable } from "../../../../shared-kernel/primary/shared/simple-table";
import { formatDateToLocale, formatToCurrency } from "@utils/formatting";

interface Props {
  expenses: AdminExpenseExtended[];
}

const headers = [
  { title: "Enregistré le" },
  { title: "Date" },
  { title: "Elève" },
  { title: "Type" },
  { title: "Prix" },
  { title: "Description" },
];

export const ExpenseListSummary = ({ expenses }: Props) => {
  const entries = useMemo(
    () =>
      expenses.map(e => {
        return [
          { value: formatDateToLocale(e.creationDate) },
          { value: formatDateToLocale(e.date) },
          { value: e.student },
          { value: e.type },
          { value: formatToCurrency(e.price) },
          { value: e.description },
        ];
      }),
    [expenses]
  );

  return (
    <CustomCard
      title="Historique des dernières masterclass / frais"
      headerChildren={
        <Link to={"/expenses/list"}>
          <Button>Voir toutes les masterclass / frais</Button>
        </Link>
      }
    >
      <SimpleTable headers={headers} entries={entries} />
    </CustomCard>
  );
};
