import { createReducer } from "@reduxjs/toolkit";
import { TeacherConventionsRetrievalState } from "@redux/app-state";
import { retrieveConventions } from "./retrieve-conventions";

const initialState: TeacherConventionsRetrievalState = { fetching: "idle", data: [] };

export const retrieveTeacherConventionsReducer = createReducer<TeacherConventionsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveConventions.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveConventions.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveConventions.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
