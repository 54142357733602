import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "@redux/app-state";
import { AdminTrainingElementStatus } from "../../../../../domain/types/training";

export const selectTrainingDetailsRetrievalForAdmin = createSelector(
  [(state: AppState) => state.adminTrainingRetrieval],
  ({ data: training }) => {
    if (!training) return { training: null };

    let globalSummaryStatus: AdminTrainingElementStatus = "not_available";
    if (training.student.summaryStatus === "available_urgent" || training.teacher.summaryStatus === "available_urgent")
      globalSummaryStatus = "available_urgent";
    else if (training.student.summaryStatus === "available" || training.teacher.summaryStatus === "available")
      globalSummaryStatus = "available";
    else if (training.student.summaryStatus === "done" && training.teacher.summaryStatus === "done") globalSummaryStatus = "done";

    return { training: { ...training, globalSummaryStatus } };
  }
);
