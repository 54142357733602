import { AppState } from "@redux/app-state";
import { isLoadingState } from "@utils/utils";

export const selectProviderProfileRetrieval = (state: AppState) => {
  const {
    providerProfileRetrieval: { data, fetching },
  } = state;

  return {
    provider: data,
    isLoading: isLoadingState(fetching),
  };
};
