import { TokenStorageGateway } from "../../../../shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../config/backend";
import { HttpGateway } from "../../../../shared-kernel/secondary/http-gateway";
import { TeacherStudentGateway } from "../../ports/teacher-student-gateway";
import { StudentListElement, TeacherStudentDetails } from "../../../read/domain/types/teacher/student";

export class BackendTeacherStudentGateway extends HttpGateway implements TeacherStudentGateway {
  private _route: string = "teacher/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StudentListElement[]> {
    const { data: students } = await this._instance.get(`${this._route}/students`);
    return students;
  }

  async getStudentByEmail(email: string): Promise<TeacherStudentDetails> {
    const { data: student } = await this._instance.get(`${this._route}/students:by-email`, {
      params: {
        email,
      },
    });
    return student;
  }
}
