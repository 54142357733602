import React from "react";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";

export const FundingRequestSubmissionSuccess = () => {
  return (
    <CustomCard title="Constitution de votre dossier de financement AFDAS">
      <p>
        L’équipe OPERA Off a bien reçu vos documents et va pouvoir constituer et mettre en ligne votre dossier. <br />
        D’ici deux ou trois semaines vous devriez avoir la réponse de l'AFDAS (mail intitulé NE PAS RÉPONDRE).
        <br /> <br /> Une fois cet accord obtenu, OPERA Off vous enverra le mail administratif qui vous donne tous les détails de gestion de
        votre dossier et vous invitera à vous connecter à votre espace personnel pour consulter votre nouveau solde.
      </p>
    </CustomCard>
  );
};
