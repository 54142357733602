import React from "react";
import { formatDateToLocale, formatToCurrency } from "../../../../../../utils/formatting";
import { getPlaceName } from "../../../../../write/domain/services/locations";
import { LessonExtended } from "../../../../../read/application/use-cases/admin/payment-lesson-list-retrieval/selectors/payment-lesson-list-selectors";
import { Dialog, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ScrollableDialogContent } from "../../../../../../shared-kernel/primary/shared/scrollable-dialog.tsx/scrollable-dialog";
import { SimpleTable } from "../../../../../../shared-kernel/primary/shared/simple-table";

interface Props {
  lessons: LessonExtended[];
  isOpen: boolean;
  onClose: () => void;
}

interface TableProps {
  data: LessonExtended[];
}

const LessonTable = ({ data }: TableProps) => {
  const headers = [
    { title: "Enregistré le" },
    { title: "Date du cours" },
    { title: "Elève" },
    { title: "Professeur" },
    { title: "Nombre d'heures" },
    { title: "Tarif horaire" },
    { title: "Studio" },
    { title: "Tarif horaire studio" },
    { title: "Règlement professeur" },
  ];
  const entries = data.map(d => {
    const oldConventionStyle = { classname: d.isUnderOldConvention ? "text-orange-400" : "" };
    return [
      { ...oldConventionStyle, value: formatDateToLocale(d.creationDate) },
      { ...oldConventionStyle, value: formatDateToLocale(d.date) },
      { ...oldConventionStyle, value: d.student },
      { ...oldConventionStyle, value: d.teacher },
      { ...oldConventionStyle, value: d.hours },
      { ...oldConventionStyle, value: formatToCurrency(d.hourlyPrice ?? 0) },
      { ...oldConventionStyle, value: getPlaceName(d.place.paidByTeacher, d.place.name) },
      { ...oldConventionStyle, value: formatToCurrency(d.place.price ?? 0) },
      { ...oldConventionStyle, value: formatToCurrency(d.paidToTeacher) },
    ];
  });
  return <SimpleTable headers={headers} entries={entries} />;
};

export const LessonListModal = ({ lessons, isOpen, onClose }: Props) => {
  const underConventionLessons: LessonExtended[] = [];
  const standardLessons: LessonExtended[] = [];
  for (const lesson of lessons) {
    if (lesson.isUnderConvention) underConventionLessons.push(lesson);
    else standardLessons.push(lesson);
  }
  lessons.filter(l => l.hourlyPrice === null);
  return (
    <Dialog open={isOpen}>
      <ScrollableDialogContent onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>Liste des cours</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <LessonTable data={standardLessons} />
        </div>
        <DialogHeader>
          <DialogTitle>Liste des cours sous convention</DialogTitle>
        </DialogHeader>
        <div className="">
          <LessonTable data={underConventionLessons} />
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Fermer
          </Button>
        </DialogFooter>
      </ScrollableDialogContent>
    </Dialog>
  );
};
