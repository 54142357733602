import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminConventionListVM } from "../../../../domain/types/admin/convention";

export const retrieveOldConventions = createAsyncThunk<AdminConventionListVM[], void, { extra: Partial<Dependencies> }>(
  "conventions/retrieveOldConventions",
  async (_: void, { extra: { adminConventionGateway } }) => {
    return adminConventionGateway!.oldConventions();
  }
);
