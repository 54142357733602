import { isLoadingState, sortAlphabetically } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export const selectProviderRetrievalForList = createSelector(
  (state: AppState) => state.adminProvidersRetrieval,
  providersRetrieval => {
    const { data, fetching } = providersRetrieval;

    const formattedData = [...data].sort((a, b) => sortAlphabetically(a.name, b.name));

    return {
      providers: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
