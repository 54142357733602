import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentListElement } from "../../../../domain/types/admin/student";

export const retrieveStudents = createAsyncThunk<StudentListElement[], void, { extra: Partial<Dependencies> }>(
  "students/retrieveStudents",
  async (_: void, { extra: { adminStudentGateway } }) => {
    return adminStudentGateway!.all();
  }
);
