import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateExpenseBody } from "@academy-context/write/domain/types/admin/expense";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
  MAX_EXPENSE_DESCRIPTION_CHARACTERS,
  MIN_EXPENSE_PRICE,
  MAX_EXPENSE_PRICE,
} from "./form-validation/expense";
import { selectExpenseCreate, useExpenseCreate } from "./use-expense-create";
import { useAppSelector } from "@redux/hooks";
import InputError from "../../../../shared-kernel/primary/shared/input-error";
import { CreatableSelect } from "../../../../shared-kernel/primary/shared/creatable-select/creatable-select";
import { LostDataModal } from "../../../../shared-kernel/primary/shared/lost-data-modal/modal";
import { formatNumberToLocale } from "../../../../utils/formatting";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { PopoverCalendar } from "../../../../shared-kernel/primary/shared/calendar/popover-calendar";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Combobox } from "../../../../shared-kernel/primary/shared/combobox/combobox";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";

interface Props {
  onSubmit: (body: CreateExpenseBody) => void;
}

const typeOptions = [
  {
    label: "Masterclass",
    value: "masterclass",
  },
  {
    label: "Frais",
    value: "frais",
  },
  {
    label: "Remboursement",
    value: "remboursement",
  },
];

export const ExpenseCreate = ({ onSubmit }: Props) => {
  const students = useAppSelector(selectRegisteredStudents);
  const { fetching } = useAppSelector(selectExpenseCreate);

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);

  const {
    control,
    reset,
    formState: { errors, isDirty },
    watch,
  } = form;

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  useExpenseCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const studentOptions = students.map(s => ({
    value: s.id,
    label: `${s.name} ${s.lastName} | ${formatNumberToLocale(s.balance, 2)}€`,
  }));

  return (
    <CustomCard title="Nouvelle masterclass / frais">
      <LostDataModal shouldBlockNavigation={shouldBlockNavigation} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid grid-cols-6 gap-3 w-full">
          <div className="space-y-4 col-span-2">
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="student"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Élève</FormLabel>
                  <FormControl>
                    <Combobox
                      options={studentOptions}
                      value={watch("student")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner élève..."
                      search={{
                        notFoundText: "Pas d'élève trouvé.",
                        commandInputPlaceHolder: "Chercher élève...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4 col-span-1">
            <div className="space-y-2">
              <Label>Type</Label>
              <CreatableSelect formName="type" control={control} options={typeOptions} />
              <InputError error={errors.type && errors.type.value?.message} />
            </div>
            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Prix</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      step="0.01"
                      min={MIN_EXPENSE_PRICE}
                      max={MAX_EXPENSE_PRICE}
                      value={watch("price") ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4 col-span-3">
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea maxLength={MAX_EXPENSE_DESCRIPTION_CHARACTERS} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end col-span-6 mt-2">
            <Button type="submit" disabled={!isDirty || fetching === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
