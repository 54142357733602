import { createReducer } from "@reduxjs/toolkit";
import { ExpensesRetrievalState } from "@redux/app-state";
import { retrieveExpenses } from "./retrieve-expenses";

const initialState: ExpensesRetrievalState = { fetching: "idle", data: [] };

export const retrieveExpensesReducer = createReducer<ExpensesRetrievalState>(initialState, builder => {
  builder.addCase(retrieveExpenses.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveExpenses.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveExpenses.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
