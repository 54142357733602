import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { UpdateTrainingObjectiveBody } from "@academy-context/write/domain/types/training";

export type FormInputs = {
  done: string;
};

export const defaultValues: FormInputs = {
  done: "",
};

export const schema = object().shape({
  done: string().required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (objective: FormInputs): UpdateTrainingObjectiveBody => {
  return {
    done: objective.done,
  };
};
