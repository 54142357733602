import { createReducer } from "@reduxjs/toolkit";
import { StudentsRetrievalState } from "@redux/app-state";
import { retrieveStudents } from "./retrieve-students";

const initialState: StudentsRetrievalState = { fetching: "idle", data: [] };

export const retrieveStudentsReducer = createReducer<StudentsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveStudents.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveStudents.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveStudents.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
