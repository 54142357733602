import { AppState } from "@redux/app-state";

export const selectTeachersRetrievalForList = (state: AppState) => {
  const {
    teachersRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const lastNameA = a.lastName.toLowerCase();
    const lastNameB = b.lastName.toLowerCase();

    if (lastNameB > lastNameA) {
      return -1;
    } else if (lastNameB < lastNameA) {
      return 1;
    }
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameB > nameA) {
      return -1;
    } else if (nameB < nameA) {
      return 1;
    }
    return 0;
  });

  return {
    data: sortedData,
    fetching,
  };
};
