import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StandardPayment } from "../../../../domain/types/admin/payment";

export const retrieveTransferList = createAsyncThunk<StandardPayment[], void, { extra: Partial<Dependencies> }>(
  "transfers/retrieveTransferList",
  async (_: void, { extra: { transferGateway } }) => {
    return transferGateway!.all();
  }
);
