import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveCollaborationRequestList } from "@academy-context/read/application/use-cases/admin/collaboration-request-list-retrieval/retrieve-collaboration-request-list";
import { Avatar, AvatarFallback, AvatarImage } from "@shared-kernel/primary/shared/shadcn/ui/avatar";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { approveCollaborationRequest } from "@academy-context/write/application/use-cases/admin/collaboration-request-approval/approve-collaboration-request";
import { useCollaborationRequestApproval } from "@academy-context/primary/admin/collaboration-request-list/use-collaboration-request-approval";
import { selectCollaborationRequestsRetrievalForList } from "@academy-context/read/application/use-cases/admin/collaboration-request-list-retrieval/selectors/collaboration-request-list-selectors";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { formatNumberToLocale } from "@utils/formatting";

export const CollaborationRequests = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectCollaborationRequestsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveCollaborationRequestList());
  }, [dispatch]);

  const onSubmit = (id: string, status: "approved" | "rejected") => {
    dispatch(approveCollaborationRequest({ id, status }));
  };

  useCollaborationRequestApproval();

  return (
    <CustomCard title="Demande d'ajout d'élève">
      <div className="grid w-full grid-cols-2">
        {data.map(c => (
          <div className="flex justify-center">
            <Card key={c.id} className="bg-white w-[400px] grid grid-cols-3 items-center px-2 mb-3">
              <CardContent className="flex flex-col justify-center col-span-2 p-0 space-y-2">
                <div className="flex items-center space-x-2 pt-2">
                  <Avatar>
                    <AvatarImage src={undefined} />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  <span className="text-sm text-black">{c.teacher.fullName}</span>
                </div>
                <div className="flex items-center space-x-2 pb-2">
                  <Avatar>
                    <AvatarImage src={c.student.picture ?? undefined} />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                  <div className="flex pr-2 items-center">
                    <span className="text-sm text-black">{c.student.fullName}</span>
                    <span className="text-sm text-black mx-2">|</span>
                    <span className="text-sm text-black font-bold">{formatNumberToLocale(c.student.balance, 2)}€</span>
                  </div>
                </div>
              </CardContent>
              <CardContent className="col-span-1 flex flex-col items-center p-0 gap-1">
                <Button
                  className="w-full bg-green-500 hover:bg-green-400 text-white hover:text-white"
                  onClick={() => {
                    onSubmit(c.id, "approved");
                  }}
                >
                  Approuver
                </Button>
                <Button
                  variant="destructive"
                  className="w-full"
                  onClick={() => {
                    onSubmit(c.id, "rejected");
                  }}
                >
                  Refuser
                </Button>
              </CardContent>
            </Card>
          </div>
        ))}
        {!data.length && <div className="">Pas de demande d'ajout d'élève</div>}
      </div>
    </CustomCard>
  );
};
