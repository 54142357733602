import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UserPassword } from "@user-management-context/write/domain/types/user";

export const updatePassword = createAsyncThunk<void, UserPassword, { extra: Partial<Dependencies> }>(
  "users/updatePassword",
  async (body: UserPassword, { extra: { userGateway } }) => {
    return userGateway!.updatePassword(body);
  }
);
