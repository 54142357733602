import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateTeacher, updateTeacher } from "./update-teacher";
import { TeacherUpdateState } from "@redux/app-state";

const initialState: TeacherUpdateState = { justUpdatedTeacherId: null, fetching: "idle" };

export const updateTeacherReducer = createReducer<TeacherUpdateState>(initialState, builder => {
  builder.addCase(updateTeacher.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedTeacherId: meta.arg.id,
      fetching: "success",
    };
  });
  builder.addCase(updateTeacher.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updateTeacher.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetUpdateTeacher, () => {
    return initialState;
  });
});
