import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminCreateProviderTrainingBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export const adminCreateProviderTraining = createAsyncThunk<string, AdminCreateProviderTrainingBody, { extra: Partial<Dependencies> }>(
  "providerTrainings/createProviderTraining",
  async (training: AdminCreateProviderTrainingBody, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.createTraining(training);
  }
);

export const adminResetCreateProviderTraining = createAction("providerTrainings/resetCreateProviderTraining");
