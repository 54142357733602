import { createReducer } from "@reduxjs/toolkit";
import { UserAccountExistenceState } from "@redux/app-state";
import { doesUserExist, resetDoesUserExist } from "./does-user-account-exist";

const initialState: UserAccountExistenceState = { fetching: "idle", doesExist: false };

export const doesUserExistReducer = createReducer<UserAccountExistenceState>(initialState, builder => {
  builder.addCase(doesUserExist.fulfilled, state => {
    return {
      ...state,
      doesExist: true,
      fetching: "success",
    };
  });
  builder.addCase(doesUserExist.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(doesUserExist.rejected, (state, { error }) => {
    return {
      ...state,
      ...(error.message === "Request failed with status code 404" && { doesExist: false }),
      fetching: "failed",
    };
  });
  builder.addCase(resetDoesUserExist, () => {
    return initialState;
  });
});
