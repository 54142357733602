import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Teacher } from "../../../../domain/types/admin/teacher";

export const retrieveTeacher = createAsyncThunk<Teacher, string, { extra: Partial<Dependencies> }>(
  "teachers/retrieveTeacher",
  async (id: string, { extra: { adminTeacherGateway } }) => {
    return adminTeacherGateway!.one(id);
  }
);
