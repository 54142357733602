import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentTrainingListVM } from "../../../../domain/types/training";

export const studentRetrieveTrainings = createAsyncThunk<StudentTrainingListVM[], void, { extra: Partial<Dependencies> }>(
  "trainings/studentRetrieveTrainings",
  async (_: void, { extra: { trainingGateway } }) => {
    return trainingGateway!.studentGetAll();
  }
);

export const resetStudentRetrieveTrainings = createAction("trainings/resetStudentRetrieveTrainings");
