import config from "../../../../config/backend";
import { TokenStorageGateway } from "../../../../shared-kernel/application/ports/token-storage-gateway";
import { TransferPort } from "../../ports/transfer-port";
import { ConventionPaymentVM, LessonListVM, StandardPayment, StandardPaymentSummaryVM } from "../../../read/domain/types/admin/payment";
import { UpdatePaymentBody } from "../../../write/domain/types/admin/payment";
import { HttpGateway } from "../../../../shared-kernel/secondary/http-gateway";

export class AdminBackendTransferGateway extends HttpGateway implements TransferPort {
  private _route: string = "admin/payments/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StandardPayment[]> {
    const { data: transfers } = await this._instance.get(this._route);
    return transfers;
  }

  async allConventions(): Promise<ConventionPaymentVM[]> {
    const { data: transfers } = await this._instance.get(`${this._route}/conventions`);
    return transfers;
  }

  async summary(): Promise<StandardPaymentSummaryVM> {
    const { data: transfers } = await this._instance.get(`${this._route}/summary`);
    return transfers;
  }

  async update(id: string, transfer: UpdatePaymentBody): Promise<string> {
    const { data: transfers } = await this._instance.put(`${this._route}/${id}`, transfer);
    return transfers;
  }

  async lessonList(id: string): Promise<LessonListVM> {
    const { data: lessons } = await this._instance.get(`${this._route}/${id}/lessons`);
    return lessons;
  }
}
