import React from "react";
import { Link } from "react-router-dom";
import { Nullable } from "../../../../shared-kernel/core/types/nullable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";

const linkWrapper = (id: string, value: Nullable<string>) => {
  return (
    <Link to={`/providers/${id}`}>
      <div className="w-full h-full">
        <span>{value}</span>
      </div>
    </Link>
  );
};

const columns: ColumnDef<ProviderListItem>[] = [
  {
    accessorKey: "tag",
    header: () => "Tag",
    cell: info => linkWrapper(info.row.original.id, info.row.original.tag),
    enableSorting: true,
    size: 60,
    meta: {
      title: "Tag",
    },
  },
  {
    accessorKey: "name",
    header: () => "Raison sociale",
    cell: info => linkWrapper(info.row.original.id, info.row.original.name),
    enableSorting: true,
    meta: {
      title: "Raison sociale",
    },
  },
  {
    accessorKey: "configuration.isTrainingFollowUpManagedByOperaOff",
    header: () => "Suivi ?",
    cell: info => {
      const label = info.row.original.configuration.isTrainingFollowUpManagedByOperaOff ? "OPERA Off" : "Prestataire";
      return linkWrapper(info.row.original.id, label);
    },
    enableSorting: true,
    meta: {
      title: "Suivi ?",
    },
  },
  {
    accessorKey: "configuration.areConventionSentToOperaOff",
    header: () => "Convention ?",
    cell: info => {
      const label = info.row.original.configuration.areConventionSentToOperaOff ? "OPERA Off" : "Prestataire";
      return linkWrapper(info.row.original.id, label);
    },
    enableSorting: true,
    meta: {
      title: "Convention ?",
    },
  },
  {
    accessorKey: "students",
    header: () => "Elèves",
    cell: info => linkWrapper(info.row.original.id, info.row.original.students.join(", ")),
    enableSorting: true,
    meta: {
      title: "Elèves",
    },
  },
];

interface Props {
  providers: ProviderListItem[];
}

export const ProviderListComponent = ({ providers }: Props) => {
  return (
    <DataTable columns={columns} data={providers} sortField="tag" order="asc" searchPlaceHolder="Rechercher un prestataire" pageSize={50} />
  );
};
