import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherTrainingVM } from "../../../../domain/types/training";

export const teacherRetrieveTraining = createAsyncThunk<TeacherTrainingVM, string, { extra: Partial<Dependencies> }>(
  "trainings/teacherRetrieveTraining",
  async (id: string, { extra: { trainingGateway } }) => {
    return trainingGateway!.teacherGetOne(id);
  }
);
