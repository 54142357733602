import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import {
  requestCollaboration,
  resetRequestCollaboration,
} from "@academy-context/write/application/use-cases/teacher/collaboration-request/request-collaboration";
import { useCollaborationRequestCreate } from "@academy-context/primary/teacher/lesson-teacher-detail/use-collaboration-request-create";
import { teacherRetrieveStudent } from "@user-management-context/read/application/use-cases/teacher/student-retrieval/retrieve-student";
import { retrieveTeacherStudents } from "@user-management-context/read/application/use-cases/teacher/students-retrieval/retrieve-students";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddStudentsModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const { data } = useAppSelector((state: AppState) => state.teacherStudentRetrieval);
  const { processing } = useAppSelector((state: AppState) => state.collaborationRequest);
  const [hasAddedStudent, setHasAddedStudent] = useState(false);

  useEffect(() => {
    if (email) dispatch(teacherRetrieveStudent(email));
  }, [email, dispatch]);

  const onSuccess = () => {
    setHasAddedStudent(true);
    dispatch(resetRequestCollaboration());
    dispatch(retrieveTeacherStudents());
  };
  useCollaborationRequestCreate({ onSuccess });

  const isStudentAlreadyAFavorite = data?.alreadyAFavorite;
  const canAddStudent = data && !isStudentAlreadyAFavorite && !hasAddedStudent;

  const addStudent = (email: string) => {
    dispatch(requestCollaboration({ email }));
  };

  const requestNewCollaboration = () => {
    setEmail("");
    setHasAddedStudent(false);
  };

  let message = null;
  if (hasAddedStudent)
    message = (
      <span className="text-sm">
        ✅ {data?.name} {data?.lastName} ajouté(e)
      </span>
    );
  else if (canAddStudent)
    message = (
      <Button type="submit" onClick={() => addStudent(email)} disabled={!Boolean(email) || processing === "pending"}>
        Ajouter cet élève
      </Button>
    );
  else if (isStudentAlreadyAFavorite)
    message = <span className="text-amber-500 font-semibold text-sm">Cet élève est déjà dans votre liste</span>;
  else if (email) message = <span className="text-destructive font-semibold text-sm">Cet email ne correspond à aucun élève</span>;

  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[725px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>DEMANDE D'AJOUT D'UN ELEVE</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <p>
            Pour ajouter un élève dans votre liste, veuillez saisir son email dans le champ ci-dessous.
            <br /> Si l’email correspond à un élève, un bouton “Ajouter cet élève” apparaitra. <br />
            Vous pourrez cliquer sur le bouton pour ajouter l’élève et immédiatement déclarer des heures à cet élève.
          </p>
          <div className="grid grid-cols-7 items-center gap-4">
            <div className="col-span-2">
              <Label htmlFor="student" className="text-right">
                Email de l'élève à ajouter :
              </Label>
            </div>
            <div className="col-span-3">
              <Input type="text" onChange={e => setEmail(e.target.value)} value={email} disabled={hasAddedStudent} />
            </div>
            <div className="col-span-2">{message}</div>
          </div>
          {hasAddedStudent && (
            <div className="flex justify-center">
              <Button type="submit" onClick={requestNewCollaboration}>
                Rechercher un autre élève
              </Button>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Fermer la fenêtre
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
