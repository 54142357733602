import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { providerId, trainingId } from "src/test/utils/common";

const defaultTraining: ProviderTraining = {
  id: trainingId,
  title: "Chanteurs (répertoires lyrique, baroque et contemporain) : travailler sa technique vocale et son interprétation",
  providerId,
  tag: "Chant TVi1",
  objective:
    "Le métier du chanteur l’expose à des problématiques de technique vocale qui peuvent entraîner une régression, un passage à vide, une  remise en question professionnelle. La proposition est de permettre au chanteur d’accéder à un  accompagnement personnalisé sur les deux dimensions singulières de son métier : Affirmer sa TECHNIQUE VOCALE. Sécuriser et optimiser le potentiel de la voix. Equipe de 30 professeurs de chant complétée par des thérapeutes reconnus. Définir un RÉPERTOIRE d’AUDITIONS. Travailler le parcours professionnel de la PRÉPARATION  aux AUDITIONS et CONCOURS. Effectuer la PRÉPARATION des RÔLES prévus à son agenda  avec le support de 40 pianistes Chefs de Chant spécialistes de chaque type de répertoire et/ou de langues",
  description:
    "Approfondir LA TECHNIQUE VOCALE : test et renfort de la partie musculaire de l’appareil phonatoire, diaphragme et abdominaux, travail sur l’émission globale de la voix et la respiration (posture) Etablir un bilan sur la tessiture du chanteur, et ses évolutions possibles. Ancrer une technique solide fondée sur l’autonomie en installant des réflexes vocauxTravailler l’INTERPRÉTATION des différents RÉPERTOIRES  du chanteur (l’apprentissage et le déchiffrage, la diction lyrique, la remise en perspective avec l’oeuvre, le compositeur, le contexte historique). Etudes de rôles completsContrôler l’adéquation du RÉPERTOIRE LYRIQUE choisi à l’IDENTITÉ vocale et physique du chanteur (incarnation des personnages)Constituer un répertoire d’auditions ciblé. Inventaire des “outils” de communication indispensables : CV Biographie Enregistrements/Visibilité Réseaux Sociaux",
  team: "Marc Siebert, Youssef Farkhani",
  price: 1000,
  creationDate: "2022-10-01T03:00:00Z",
};

export const ProviderTrainingBuilder = (data: ProviderTraining = defaultTraining) => {
  return {
    withId: (id: ProviderTraining["id"]) => ProviderTrainingBuilder({ ...data, id }),
    withTitle: (title: ProviderTraining["title"]) => ProviderTrainingBuilder({ ...data, title }),
    withObjective: (objective: ProviderTraining["objective"]) => ProviderTrainingBuilder({ ...data, objective }),
    withDescription: (description: ProviderTraining["description"]) => ProviderTrainingBuilder({ ...data, description }),
    withCreationDate: (creationDate: ProviderTraining["creationDate"]) => ProviderTrainingBuilder({ ...data, creationDate }),
    withProviderId: (providerId: ProviderTraining["providerId"]) => ProviderTrainingBuilder({ ...data, providerId }),
    build: (): ProviderTraining => data,
  };
};
