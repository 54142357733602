enum MONTH {
  JANUARY = "01",
  FEBRUARY = "02",
  MARCH = "03",
  APRIL = "04",
  MAY = "05",
  JUNE = "06",
  JULY = "07",
  AUGUST = "08",
  SEPTEMBER = "09",
  OCTOBER = "10",
  NOVEMBER = "11",
  DECEMBER = "12",
}

const MONTH_LABEL = {
  [MONTH.JANUARY]: "Janvier",
  [MONTH.FEBRUARY]: "Février",
  [MONTH.MARCH]: "Mars",
  [MONTH.APRIL]: "Avril",
  [MONTH.MAY]: "Mai",
  [MONTH.JUNE]: "Juin",
  [MONTH.JULY]: "Juillet",
  [MONTH.AUGUST]: "Août",
  [MONTH.SEPTEMBER]: "Septembre",
  [MONTH.OCTOBER]: "Octobre",
  [MONTH.NOVEMBER]: "Novembre",
  [MONTH.DECEMBER]: "Décembre",
};

export const convertDateToMonthAndYear = (date: string): string => {
  const d = new Date(date).toISOString();
  const month = d.slice(5, 7) as MONTH;
  const year = d.slice(0, 4);

  return `${MONTH_LABEL[month]} ${year}`;
};

export const formatNumberToLocale = (number: number, decimals = 0, locale = "fr-FR"): string => {
  return number.toLocaleString(locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
};

export const formatToCurrency = (amount: number): string => `${formatNumberToLocale(amount, 2)}€`;

export const formatDateToLocale = (date: string): string => new Date(date).toLocaleDateString("fr-FR");
