import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { retrieveStudent } from "../../../../user-management-context/read/application/use-cases/admin/student-retrieval/retrieve-student";
import { resetUpdateStudent } from "../../../../user-management-context/write/application/use-cases/admin/student-update/update-student";
import { AppState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";

const selectStudentUpdate = (state: AppState) => state.studentUpdate;

export const useStudentUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedStudentId, fetching } = useSelector(selectStudentUpdate);

  useEffect(() => {
    if (justUpdatedStudentId) {
      toast({ description: "Mise à jour effectuée avec succès!" });
      dispatch(resetUpdateStudent());
      dispatch(retrieveStudent(justUpdatedStudentId));
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la mise à jour de l'élève", variant: "destructive" });
    }
  }, [justUpdatedStudentId, fetching, dispatch, toast]);
};
