import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { studentRetrieveExpenses } from "../../../read/application/use-cases/student/expenses-retrieval/retrieve-expenses";
import { selectStudentExpensesRetrievalForList } from "../../../read/application/use-cases/student/expenses-retrieval/selectors/expenses-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { StudentExpenseListVM } from "../../../read/domain/types/student/expense";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";

const columns: ColumnDef<StudentExpenseListVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: ({ row }) => formatDateToLocale(row.original.creationDate),
    size: 40,
    enableSorting: true,
    meta: {
      title: "Enregistré le",
    },
  },
  {
    accessorKey: "type",
    header: () => "Type",
    size: 40,
    enableSorting: true,
    meta: {
      title: "Type",
    },
  },
  {
    accessorKey: "price",
    header: () => "Prix",
    cell: ({ row }) => formatToCurrency(row.original.price),
    size: 40,
    enableSorting: true,
    meta: {
      title: "Prix",
    },
  },
  {
    accessorKey: "description",
    header: () => "Description",
    meta: {
      title: "Description",
    },
  },
];

export const StudentExpenseList = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(selectStudentExpensesRetrievalForList);

  useEffect(() => {
    dispatch(studentRetrieveExpenses());
  }, [dispatch]);

  return (
    <CustomCard title="HISTORIQUE DES MASTERCLASS / FRAIS">
      <DataTable
        columns={columns}
        data={data}
        searchPlaceHolder="Rechercher une masterclass / Frais"
        sortField="creationDate"
        order="desc"
      />
    </CustomCard>
  );
};
