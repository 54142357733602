import { TokenStorageGateway } from "../../../../../shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "../../../../../shared-kernel/secondary/http-gateway";
import config from "../../../../../config/backend";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { StudentProviderRepository } from "@user-management-context/shared/application/ports/student-provider-repository";

export class StudentBackendProviderRepository extends HttpGateway implements StudentProviderRepository {
  private _route: string = "v1/student/providers";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async trainingList(providerId: string): Promise<ProviderTraining[]> {
    const { data: trainings } = await this._instance.get(`${this._route}/${providerId}/trainings`);
    return trainings;
  }
}
