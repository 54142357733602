import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderVM } from "@user-management-context/read/domain/types/student/provider";

export const retrieveProviders = createAsyncThunk<ProviderVM[], void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviders",
  async (_: void, { extra: { studentGateway } }) => {
    return studentGateway!.providerList();
  }
);
