import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useProviderTrainingCreated } from "@academy-context/primary/provider/provider-trainings/list/use-training-created";
import { useProviderTrainingUpdated } from "@academy-context/primary/provider/provider-trainings/list/use-training-updated";
import { Separator } from "@components/ui/separator";
import { useProviderTrainingDeleted } from "@academy-context/primary/provider/provider-trainings/list/use-training-deleted";
import { retrieveProviderTrainings } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings";
import { selectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import { createProviderTraining } from "@academy-context/write/application/use-cases/provider/provider-training-creation/create-provider-training";
import { updateProviderTraining } from "@academy-context/write/application/use-cases/provider/provider-training-update/update-provider-training";
import {
  CreateProviderTrainingBody,
  UpdateProviderTrainingBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { deleteProviderTraining } from "@academy-context/write/application/use-cases/provider/provider-training-deletion/delete-provider-training";
import { ProviderTrainingListSkeleton } from "@academy-context/primary/shared/provider-trainings/provider-training-list.skeleton";
import { ProviderTrainingListComponent } from "@academy-context/primary/shared/provider-trainings/provider-training-list.components";

export const ProviderTrainingListContainer = () => {
  const dispatch = useAppDispatch();
  const { isLoading, trainings } = useAppSelector(selectProviderTrainingsRetrievalForList);

  useEffect(() => {
    dispatch(retrieveProviderTrainings());
  }, [dispatch]);

  useProviderTrainingCreated();
  useProviderTrainingUpdated();
  useProviderTrainingDeleted();

  const onCreate = (body: CreateProviderTrainingBody) => {
    dispatch(createProviderTraining(body));
  };
  const onUpdate = (body: UpdateProviderTrainingBody) => {
    dispatch(updateProviderTraining(body));
  };

  const onDelete = (trainingId: string) => {
    dispatch(deleteProviderTraining(trainingId));
  };

  return (
    <>
      <h1 className="text-5xl font-bold">Formations</h1>
      <h2 className="text-xs font-light mt-2">
        Les informations de cette section sont utilisées pour générer les devis qualitatifs et quantitatifs qui seront envoyés à l’AFDAS.
      </h2>
      <Separator className="mt-3" />
      <div className="mt-3">
        {isLoading ? (
          <ProviderTrainingListSkeleton />
        ) : (
          <ProviderTrainingListComponent trainings={trainings} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
        )}
      </div>
    </>
  );
};
