import { createReducer } from "@reduxjs/toolkit";
import { retrieveUnsubscribedUserList } from "./retrieve-unsubscribed-user-list";
import { UnsubscribedUserListState } from "@redux/app-state";

const initialState: UnsubscribedUserListState = { fetching: "idle", users: [] };

export const retrieveUnsubscribedUserListReducer = createReducer<UnsubscribedUserListState>(initialState, builder => {
  builder.addCase(retrieveUnsubscribedUserList.fulfilled, (state, { payload }) => {
    return {
      ...state,
      users: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveUnsubscribedUserList.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveUnsubscribedUserList.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
