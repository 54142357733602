import { createReducer } from "@reduxjs/toolkit";
import { TokenValidityCheckState } from "@redux/app-state";
import { verifyTokenValidity } from "./verify-token-validity";

const initialState: TokenValidityCheckState = { fetching: "idle", data: null };

export const verifyTokenValidityReducer = createReducer<TokenValidityCheckState>(initialState, builder => {
  builder.addCase(verifyTokenValidity.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(verifyTokenValidity.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(verifyTokenValidity.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
