import React, { useState } from "react";
import { cn } from "@shared-kernel/primary/shared/shadcn/utils/utils";
import { ChevronsUpDown, Check } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@shared-kernel/primary/shared/shadcn/ui/popover";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "@shared-kernel/primary/shared/shadcn/ui/command";
import clsx from "clsx";

export interface ComboboxOptions {
  label: string;
  value: string;
  isDisabled?: boolean;
}

interface Props {
  options: ComboboxOptions[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  search?: {
    commandInputPlaceHolder: string;
    notFoundText: string;
  };
  disabled?: boolean;
}

export const Combobox = ({ value, options, onChange, search, placeholder, disabled = false }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button variant="outline" role="combobox" aria-expanded={open} className="justify-between w-full h-full">
          {value ? options.find(o => value === o.value)?.label : <span className="opacity-50 font-normal">{placeholder}</span>}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          {search && (
            <>
              <CommandInput placeholder={search.commandInputPlaceHolder} />
              <CommandEmpty>{search.notFoundText}</CommandEmpty>
            </>
          )}
          <CommandGroup className="max-h-[400px] overflow-y-auto">
            {options.map(o => (
              <CommandItem
                key={o.value}
                onSelect={() => {
                  onChange(o.value);
                  setOpen(false);
                }}
                disabled={o.isDisabled}
                className={clsx(o.isDisabled && "text-gray-400 cursor-not-allowed")}
              >
                <Check className={cn("mr-2 h-4 w-4 text-gray cursor-not-allowed", o.value === value ? "opacity-100" : "opacity-0")} />
                {o.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
