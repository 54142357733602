import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../redux/store";
import { CreateStudentBody } from "@user-management-context/write/domain/types/admin/student";

export const createStudent = createAsyncThunk<string, CreateStudentBody, { extra: Partial<Dependencies> }>(
  "students/createStudent",
  async (student: CreateStudentBody, { extra: { adminStudentGateway } }) => {
    return adminStudentGateway!.create(student);
  }
);

export const resetCreateStudent = createAction("students/resetCreateStudent");
