import { object, string } from "yup";
import { validatePassword } from "../../../../../write/domain/services/password";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export type FormInputs = {
  password: string;
};

export const defaultValues = {
  password: "",
};

export const schema = object().shape({
  password: string()
    .required(REQUIRED_FIELD)
    .test("Mot de passe valide", "Le mot de passe n'est pas valide", password => {
      return validatePassword(password!);
    }),
});
