import { createReducer } from "@reduxjs/toolkit";
import { ConventionEndingState } from "@redux/app-state";
import { endConvention } from "./end-convention";

const initialState: ConventionEndingState = {
  fetching: "idle",
};

export const endConventionReducer = createReducer<ConventionEndingState>(initialState, builder => {
  builder.addCase(endConvention.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(endConvention.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(endConvention.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
