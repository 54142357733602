import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";

export const adminRetrieveProviderTrainings = createAsyncThunk<ProviderTraining[], string, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderTrainings",
  async (providerId: string, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.trainingList(providerId);
  }
);

export const resetAdminRetrieveProviderTrainings = createAction("providers/resetAdminRetrieveProviderTrainings");
