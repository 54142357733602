import { AppState } from "@redux/app-state";

export const selectTransfersRetrievalForSummaryList = (state: AppState) => {
  const {
    transferSummaryRetrieval: { data, fetching },
  } = state;

  const {
    current: { data: currentTransfers },
    previous: { data: previousTransfers },
  } = data;

  const { start, end } = data.current.interval;
  const currentLabel =
    start && end
      ? `${new Date(data.current.interval.start).toLocaleDateString("fr-FR")} - ${new Date(data.current.interval.end).toLocaleDateString(
          "fr-FR"
        )}`
      : "";

  return {
    data: {
      current: {
        label: currentLabel,
        transfers: currentTransfers,
      },
      previous: { label: "Antérieurs", transfers: previousTransfers },
    },
    fetching,
  };
};
