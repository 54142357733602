import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherStudentDetails } from "@user-management-context/read/domain/types/teacher/student";

export const teacherRetrieveStudent = createAsyncThunk<TeacherStudentDetails, string, { extra: Partial<Dependencies> }>(
  "teachers/teacherRetrieveStudent",
  async (email: string, { extra: { teacherStudentGateway } }) => {
    return teacherStudentGateway!.getStudentByEmail(email);
  }
);
