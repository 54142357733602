import { createReducer } from "@reduxjs/toolkit";
import { CreateTeacherAccountState } from "@redux/app-state";
import { createTeacherAccount } from "./create-teacher-account";

const initialState: CreateTeacherAccountState = { fetching: "idle" };

export const createTeacherAccountReducer = createReducer<CreateTeacherAccountState>(initialState, builder => {
  builder.addCase(createTeacherAccount.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(createTeacherAccount.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createTeacherAccount.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
