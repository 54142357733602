import { getPlaceName } from "../../../../../../write/domain/services/locations";
import { TeacherLessonListVM } from "../../../../../domain/types/teacher/lesson";
import { AppState } from "@redux/app-state";

export const selectTeacherLessonsRetrievalForList = (state: AppState) => {
  const {
    lessonsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.creationDate).getTime();
    const dateBInMs = new Date(b.creationDate).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  return {
    data: sortedData.map(l => {
      return {
        ...l,
        studio: studioFormatter(l.place),
        localeDate: new Date(l.date).toLocaleDateString("fr-FR"),
        localeCreationDate: new Date(l.creationDate).toLocaleDateString("fr-FR"),
      };
    }),
    fetching,
  };
};

const studioFormatter = (place: TeacherLessonListVM["place"]): string => {
  if (place.paidByTeacher && place.price !== null) {
    return `${place.price} €/heure: Payé par le professeur`;
  }
  return getPlaceName(place.paidByTeacher, place.name);
};
