import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { LessonListVM } from "../../../../domain/types/admin/payment";

export const retrievePaymentLessonList = createAsyncThunk<LessonListVM, string, { extra: Partial<Dependencies> }>(
  "transfers/retrievePaymentLessonList",
  async (id: string, { extra: { transferGateway } }) => {
    return transferGateway!.lessonList(id);
  }
);
