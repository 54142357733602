import React from "react";
import { Skeleton } from "@components/ui/skeleton";

export const ProviderStudentInvitationListSkeleton = () => (
  <div className="w-full">
    <div className="flex items-center py-4">
      <Skeleton className="h-[40px] w-2/5" />
    </div>
    <div className="rounded-md border">
      <Skeleton className="h-[400px] w-full" />
    </div>
  </div>
);
