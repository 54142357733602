import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ConventionPaymentVM } from "../../../../domain/types/admin/payment";

export const retrieveConventionTransferList = createAsyncThunk<ConventionPaymentVM[], void, { extra: Partial<Dependencies> }>(
  "transfers/retrieveConventionTransferList",
  async (_: void, { extra: { transferGateway } }) => {
    return transferGateway!.allConventions();
  }
);
