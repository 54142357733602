import { createReducer } from "@reduxjs/toolkit";
import { TeacherRetrievalState } from "@redux/app-state";
import { retrieveTeacher } from "./retrieve-teacher";

const initialState: TeacherRetrievalState = { fetching: "idle", data: null };

export const retrieveTeacherReducer = createReducer<TeacherRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTeacher.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTeacher.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTeacher.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
