import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateLessonBody } from "@academy-context/write/domain/types/teacher/lesson";

export const createLesson = createAsyncThunk<string, CreateLessonBody, { extra: Partial<Dependencies> }>(
  "lessons/createLesson",
  async (body: CreateLessonBody, { extra: { teacherLessonGateway } }) => {
    return teacherLessonGateway!.create(body);
  }
);

export const resetCreateLesson = createAction("lessons/resetCreateLesson");
