import React from "react";
import { Control, Controller } from "react-hook-form";
import CreatableReactSelect from "react-select/creatable";

interface Props {
  control: Control<any, any>;
  formName: string;
  options: { label: string; value: string }[];
  styleOptions?: { menu: { width: string } };
}

export const CreatableSelect = ({ control, formName, options, styleOptions }: Props) => {
  const menuStyle = { ...styleOptions?.menu };

  return (
    <Controller
      name={formName}
      control={control}
      render={({ field }) => {
        const { value, ...rest } = field;
        const val = value?.value ? value : null;
        return (
          <CreatableReactSelect
            {...rest}
            isClearable
            isSearchable={true}
            options={options}
            value={val}
            placeholder="Sélectionner..."
            formatCreateLabel={input => `Ajouter ${input}`}
            styles={{ menu: base => ({ ...base, ...menuStyle }) }}
          />
        );
      }}
    />
  );
};
