import { object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { ProviderStudentInvitationRow } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/selectors/provider-student-invitation-list-selectors";
import { AcceptProviderStudentInvitationBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export type ProviderStudentInvitationFormInputs = {
  email: string;
  name: string;
  lastName: string;
  mobile: string;
  training: string;
  internshipInterval: { start: string; end: string };
};

export const providerStudentInvitationdefaultValues = {
  email: "",
  name: "",
  lastName: "",
  mobile: "",
  training: "",
  internshipInterval: { start: "", end: "" },
};

export const providerStudentInvitationSchema = object().shape({
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  training: string().max(255).required(REQUIRED_FIELD),
  internshipInterval: object().shape({
    start: string().required(REQUIRED_FIELD),
    end: string().required(REQUIRED_FIELD),
  }),
});

export const formatProviderStudentInvitationDataToFormData = (
  invitation: ProviderStudentInvitationRow
): ProviderStudentInvitationFormInputs => {
  const { training, originalData } = invitation;

  return {
    ...providerStudentInvitationdefaultValues,
    internshipInterval: originalData.lastInternship.interval,
    training: training,
    name: originalData.student.name,
    lastName: originalData.student.lastName,
    mobile: originalData.student.mobile,
    email: originalData.student.email,
  };
};

export const formatFormDataToBodyData = (invitation: ProviderStudentInvitationFormInputs): AcceptProviderStudentInvitationBody => {
  const { internshipInterval, email, mobile, name, lastName } = invitation;

  return {
    student: {
      email,
      mobile,
      name,
      lastName,
    },
    internship: {
      interval: {
        start: new Date(internshipInterval.start).toISOString(),
        end: new Date(internshipInterval.end).toISOString(),
      },
    },
  };
};
