import { TeacherConventionListVM } from "../../../../../domain/types/teacher/convention";
import { AppState } from "@redux/app-state";
import { formatDateToLocale } from "@utils/formatting";

export type ExtendedTeacherConventionListVM = TeacherConventionListVM & { status: string; hasBeenPaid: boolean };

export const selectConventionsRetrievalForList = (state: AppState) => {
  const {
    teacherOldConventionsRetrieval: { data: oldConventions },
  } = state;
  const {
    teacherConventionsRetrieval: { data: conventions },
  } = state;

  const extendedOldConventions: ExtendedTeacherConventionListVM[] = oldConventions.map(c => {
    const toPay = c.hoursDone >= c.paymentThreshold;

    let status = "";
    if (c.paymentDate) status = `Payée le ${formatDateToLocale(c.paymentDate)}`;
    else if (toPay) status = "À payer";

    return { ...c, status, hasBeenPaid: Boolean(c.paymentDate), prematureEndDate: null };
  });
  const extendedConventions: ExtendedTeacherConventionListVM[] = conventions.map(c => ({
    ...c,
    status: "Les cours sous cette convention sont réglés en fin de mois",
    hasBeenPaid: false,
  }));

  return {
    data: [...extendedConventions, ...extendedOldConventions],
    oldConventions,
    conventions,
  };
};
