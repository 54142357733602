import { createReducer } from "@reduxjs/toolkit";
import { ExpenseCreateState } from "@redux/app-state";
import { createExpense, resetCreateExpense } from "./create-expense";

const initialState: ExpenseCreateState = { justCreatedExpenseId: null, fetching: "idle" };

export const createExpenseReducer = createReducer<ExpenseCreateState>(initialState, builder => {
  builder.addCase(createExpense.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedExpenseId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createExpense.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createExpense.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateExpense, () => {
    return initialState;
  });
});
