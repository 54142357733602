import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";

export const adminRetrieveProviderInternships = createAsyncThunk<ProviderInternship[], void, { extra: Partial<Dependencies> }>(
  "providers/adminRetrieveProviderInternships",
  async (_, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.internshipList();
  }
);
