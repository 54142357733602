import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "src/redux/app-state";
import { adminRetrieveTrainings } from "@academy-context/read/application/use-cases/admin/trainings-retrieval/retrieve-trainings";

const selectTrainingDunningsToggle = (state: AppState) => state.trainingDunningsStatusToggle;

export const useTrainingUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectTrainingDunningsToggle);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Formation mise à jour!" });
      dispatch(adminRetrieveTrainings());
    } else if (processing === "failed") {
      toast({
        description: "Erreur lors de la mise à jour de la formation. Veuillez contacter l'administrateur",
        variant: "destructive",
      });
    }
  }, [dispatch, processing, toast]);
};
