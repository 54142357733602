import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";

export const retrieveProviderPublicProfile = createAsyncThunk<Provider, string, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderPublicProfile",
  async (userId: string, { extra: { userGateway } }) => {
    return userGateway!.publicProviderProfile(userId);
  }
);
