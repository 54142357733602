import { createReducer } from "@reduxjs/toolkit";
import { adminResetUpdateProviderTraining, adminUpdateProviderTraining } from "./update-provider-training";
import { UpdateState } from "@redux/app-state";

const initialState: UpdateState = { justUpdatedId: null, processing: "idle" };

export const adminUpdateProviderTrainingReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(adminUpdateProviderTraining.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.trainingId,
      processing: "success",
    };
  });
  builder.addCase(adminUpdateProviderTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminUpdateProviderTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetUpdateProviderTraining, () => {
    return initialState;
  });
});
