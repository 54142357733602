import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentSummaryVM } from "../../../../../../user-management-context/read/domain/types/student/student";

export const retrieveStudentSummary = createAsyncThunk<StudentSummaryVM, string | undefined, { extra: Partial<Dependencies> }>(
  "students/retrieveStudentSummary",
  async (studentId: string | undefined, { extra: { studentGateway, adminStudentGateway } }) => {
    if (studentId) return adminStudentGateway!.summary(studentId);
    return studentGateway!.summary();
  }
);
