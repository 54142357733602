export const UNAUTHENTICATED_ERROR_NAME = "401 unauthenticated";
export const UNAUTHORIZED_ERROR_NAME = "403 unauthorized";
export const LOGIN_TIMEOUT_ERROR_NAME = "440 Login Timeout";

export class UnauthenticatedError extends Error {
  public constructor() {
    super();
    this.name = UNAUTHENTICATED_ERROR_NAME;
  }
}

export class UnauthorizedError extends Error {
  public constructor() {
    super();
    this.name = UNAUTHORIZED_ERROR_NAME;
  }
}

export class LoginTimeoutError extends Error {
  public constructor() {
    super();
    this.name = LOGIN_TIMEOUT_ERROR_NAME;
  }
}
