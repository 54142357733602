import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { TrainingTable } from "./training-table";
import { adminRetrieveTrainings } from "../../../read/application/use-cases/admin/trainings-retrieval/retrieve-trainings";
import { AppState } from "@redux/app-state";

export const TrainingList = () => {
  const dispatch = useAppDispatch();
  const { data: trainings } = useAppSelector((state: AppState) => state.adminTrainingsRetrieval);

  useEffect(() => {
    dispatch(adminRetrieveTrainings());
  }, [dispatch]);

  return <TrainingTable trainings={trainings} />;
};
