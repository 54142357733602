import { TeacherLessonPort } from "../../ports/teacher-lesson-port";
import { TokenStorageGateway } from "../../../../shared-kernel/application/ports/token-storage-gateway";
import { CreateLessonBody } from "../../../write/domain/types/teacher/lesson";
import { TeacherLessonListVM } from "../../../read/domain/types/teacher/lesson";
import config from "../../../../config/backend";
import { HttpGateway } from "../../../../shared-kernel/secondary/http-gateway";

export class BackendTeacherLessonGateway extends HttpGateway implements TeacherLessonPort {
  private _route: string = "lessons/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }
  async all(): Promise<TeacherLessonListVM[]> {
    const { data: lessons } = await this._instance.get(this._route);
    return lessons;
  }

  async create(lesson: CreateLessonBody): Promise<string> {
    const { data: lessonId } = await this._instance.post(this._route, lesson);
    return lessonId;
  }
}
