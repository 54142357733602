import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UserPassword } from "@user-management-context/write/domain/types/user";

export const createAdminAccount = createAsyncThunk<void, UserPassword, { extra: Partial<Dependencies> }>(
  "users/createAdminAccount",
  async (body: UserPassword, { extra: { userGateway } }) => {
    return userGateway!.createAdminAccount(body);
  }
);
