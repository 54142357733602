import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";

export const retrieveRefundList = createAsyncThunk<Refund[], void, { extra: Partial<Dependencies> }>(
  "refunds/retrieveRefundList",
  async (_: void, { extra: { refundRequestGateway } }) => {
    return refundRequestGateway!.list();
  }
);
