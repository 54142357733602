import { AppState } from "@redux/app-state";

export const selectConventionsRetrievalForList = (state: AppState) => {
  const {
    studentConventionsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.creationDate).getTime();
    const dateBInMs = new Date(b.creationDate).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  const activeConventions = sortedData.filter(c => c.hoursLeft > 0);

  return {
    data: sortedData.map(c => {
      return {
        ...c,
        localeDate: new Date(c.date).toLocaleDateString("fr-FR"),
      };
    }),
    lastActiveConvention: activeConventions.length ? activeConventions[0] : null,
    fetching,
  };
};
