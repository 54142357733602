import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StandardPaymentSummaryVM } from "../../../../domain/types/admin/payment";

export const retrieveTransferSummary = createAsyncThunk<StandardPaymentSummaryVM, void, { extra: Partial<Dependencies> }>(
  "transfers/retrieveTransferSummary",
  async (_: void, { extra: { transferGateway } }) => {
    return transferGateway!.summary();
  }
);
