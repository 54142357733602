import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminBudgetListVM } from "../../../../domain/types/admin/budget";

export const retrieveBudgets = createAsyncThunk<AdminBudgetListVM[], void, { extra: Partial<Dependencies> }>(
  "budgets/retrieveBudgets",
  async (_: void, { extra: { adminBudgetGateway } }) => {
    return adminBudgetGateway!.all();
  }
);
