import { number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { AdminCreateProviderTrainingBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

export type ProviderTrainingFormInputs = {
  title: string;
  tag: string;
  objective: string;
  objectiveText: string;
  description: string;
  descriptionText: string;
  team: string;
  price?: number;
};

export const providerTrainingdefaultValues = {
  title: "Nouvelle formation",
  tag: "",
  objective: "",
  objectiveText: "",
  description: "",
  descriptionText: "",
  team: "",
  price: undefined,
};

export const providerTrainingSchema = object().shape({
  title: string().max(255).required(REQUIRED_FIELD),
  tag: string().max(255).required(REQUIRED_FIELD),
  objective: string().max(4000).required(REQUIRED_FIELD),
  objectiveText: string().max(4000).required(REQUIRED_FIELD),
  description: string().max(4000).required(REQUIRED_FIELD),
  descriptionText: string().max(4000).required(REQUIRED_FIELD),
  team: string().max(255).required(REQUIRED_FIELD),
  price: number().min(1).max(2000).required(REQUIRED_FIELD),
});

export const formatProviderTrainingDataToFormData = (training: Omit<ProviderTraining, "providerId">): ProviderTrainingFormInputs => {
  const { tag, title, objective, description, team, price } = training;

  return {
    tag,
    title,
    objective,
    objectiveText: objective,
    description,
    descriptionText: description,
    team,
    price,
  };
};

export const formatFormDataToBodyData = (training: ProviderTrainingFormInputs): Omit<AdminCreateProviderTrainingBody, "providerId"> => {
  const { tag, title, objective, description, team, price } = training;

  return {
    tag,
    title,
    objective,
    description,
    team,
    price: price ?? 0,
  };
};
