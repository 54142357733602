import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { RefundRequestBody } from "@academy-context/write/domain/types/admin/refund-request";

export const requestRefund = createAsyncThunk<void, RefundRequestBody, { extra: Partial<Dependencies> }>(
  "refunds/requestRefund",
  async (refundRequest: RefundRequestBody, { extra: { refundRequestGateway } }) => {
    return refundRequestGateway!.request(refundRequest);
  }
);

export const resetRequestRefund = createAction("refunds/resetRequestRefund");
