import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { Teacher } from "../../../../domain/types/admin/teacher";

export const retrieveTeacherProfile = createAsyncThunk<Teacher, void, { extra: Partial<Dependencies> }>(
  "teachers/retrieveTeacherProfile",
  async (_: void, { extra: { teacherGateway } }) => {
    return teacherGateway!.profile();
  }
);
