import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";
import { retrieveProviderInternships } from "@academy-context/read/application/use-cases/provider/provider-internships-retrieval/retrieve-provider-internships";

const initialState: RetrievalState<ProviderInternship[]> = { data: [], fetching: "idle" };

export const retrieveProviderInternshipsReducer = createReducer<RetrievalState<ProviderInternship[]>>(initialState, builder => {
  builder.addCase(retrieveProviderInternships.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveProviderInternships.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveProviderInternships.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
