import { object, string } from "yup";
import { UserBody } from "@user-management-context/write/domain/types/user";
import { User } from "@user-management-context/read/domain/types/user";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export type FormInputs = {
  password: string;
  email: string;
};

export const defaultValues = {
  password: "",
  email: "",
};

export const schema = object().shape({
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  password: string().required(REQUIRED_FIELD),
});

export const formatUserDataToFormData = (user: User): FormInputs => {
  return {
    ...defaultValues,
    email: user.email,
  };
};

export const formatFormDataToBodyData = (user: FormInputs): UserBody => {
  return {
    password: user.password,
    email: user.email.toLowerCase().trim(),
  };
};
