import React, { useEffect, useMemo } from "react";
import { Survey } from "./survey.components";
import { useLocation, useParams } from "react-router-dom";
import { BackButton } from "../../../../shared-kernel/primary/shared/back-button/back-button";
import { useInterval } from "../../../../hooks/useInterval";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { studentRetrieveTraining } from "../../../read/application/use-cases/student/training-retrieval/retrieve-training";
import { AppState } from "@redux/app-state";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";

const generatePreTrainingSurveyUrl = ({ id, name, lastName }: { id: string; name: string; lastName: string }) =>
  `https://docs.google.com/forms/d/e/1FAIpQLSenbbu75-n11YnqtE8W1Ge2OR6aVTYrkjDTtT8Q5cX6-WKpAg/viewform?usp=pp_url&entry.1900421933=${name}+${lastName}&entry.1537455663=${id}`;
const generatePostTrainingSurveyUrl = ({ id, name, lastName }: { id: string; name: string; lastName: string }) =>
  `https://docs.google.com/forms/d/e/1FAIpQLSe_cj-OdXjpcAyLDTdjVZM42bbncd_GUjGLQofRwH5tBYI4aw/viewform?usp=pp_url&entry.943913357=${id}&entry.1044283439=${name}+${lastName}`;

// The underlying component uses an iframe to display the survey.
// Unfortunately, we can't access to the html components to track if user has submitted it (+ this solution is rather weak because we depend on the survey provider tags).
// The only way I found is to poll the backend until the user has filled the survey. When filled, user is redirected back.
export const TrainingSurvey = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { data: training } = useAppSelector((state: AppState) => state.studentTrainingRetrieval);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isPreTrainingSurvey = location.pathname.includes("pretrainingsurvey");
  const isPostTrainingSurvey = location.pathname.includes("posttrainingsurvey");

  // Poll backend to get survey completion status
  useInterval(
    () => {
      if (id) dispatch(studentRetrieveTraining(id));
    },
    1000,
    { runOnInit: false }
  );

  useEffect(() => {
    if (training) {
      // If filled then go back
      if (
        (isPreTrainingSurvey && training.preTrainingSurveyStatus === "done") ||
        (isPostTrainingSurvey && training.postTrainingSurveyStatus === "done")
      ) {
        navigate(`/trainings/${id}`);
        toast({ description: "formulaire soumis avec succès!" });
      }
    }
  }, [id, navigate, training, isPreTrainingSurvey, isPostTrainingSurvey, toast]);

  const url = useMemo(() => {
    let url = null;
    if (training && id) {
      const surveyPayload = { id, name: training.student.name, lastName: training.student.lastName };
      // Used to fill the id in the survey
      // See here for more info: https://docs.google.com/document/d/1ezsCI6Z7g4SXtRtfPt936YQlZ6zGMThTJYpTfxzxHME/edit#heading=h.dc9ekbt79xrb
      if (isPreTrainingSurvey) url = generatePreTrainingSurveyUrl(surveyPayload);
      else if (isPostTrainingSurvey) url = generatePostTrainingSurveyUrl(surveyPayload);
    }
    return url;
  }, [training, id, isPostTrainingSurvey, isPreTrainingSurvey]);

  if (!id) return <></>;

  return (
    <div className="flex justify-center mb-5">
      <div className="w-[90%]">
        <BackButton link={`/trainings/${id}`} label="Suivi qualité de votre formation" />
        <div className="mt-5 flex justify-center">{url && <Survey url={url} />}</div>
      </div>
    </div>
  );
};
