import { createReducer } from "@reduxjs/toolkit";
import { TeacherProfileRetrievalState } from "@redux/app-state";
import { retrieveTeacherPublicProfile } from "./retrieve-teacher-public-profile";

const initialState: TeacherProfileRetrievalState = { fetching: "idle", data: null };

export const retrieveTeacherPublicProfileReducer = createReducer<TeacherProfileRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTeacherPublicProfile.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTeacherPublicProfile.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTeacherPublicProfile.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
