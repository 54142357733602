import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";

export const selectProviderTrainingUpdated = (state: AppState) => state.providerTrainingUpdate;

export const useProviderTrainingUpdated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId, processing } = useAppSelector(selectProviderTrainingUpdated);

  useEffect(() => {
    if (processing === "success" && justUpdatedId) {
      toast({ description: "Formation mise à jour !" });
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour de la formation", variant: "destructive" });
    }
  }, [dispatch, justUpdatedId, processing, toast]);
};
