import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const approveCollaborationRequest = createAsyncThunk<
  void,
  { id: string; status: "approved" | "rejected" },
  { extra: Partial<Dependencies> }
>(
  "collaborationRequests/approveCollaborationRequest",
  async (data: { id: string; status: "approved" | "rejected" }, { extra: { collaborationRequestGateway } }) => {
    return collaborationRequestGateway!.approve(data);
  }
);

export const resetApproveCollaborationRequest = createAction("collaborationRequests/resetApproveCollaborationRequest");
