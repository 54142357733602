import { isPossiblePhoneNumber } from "react-phone-number-input";
import { object, string } from "yup";
import { UpdateStudentBody } from "@user-management-context/write/domain/types/student/student";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export type Validation = "student" | "admin";

export type FormInputs = {
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  profilePictureUrl: string;
};

export const defaultValues = {
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  profilePictureUrl: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  mobile: string().test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  profilePictureUrl: string().url(),
});

export const formatStudentDataToFormData = (student: Student): FormInputs => {
  return {
    ...defaultValues,
    name: student.name,
    lastName: student.lastName,
    email: student.email,
    mobile: student.mobile,
    profilePictureUrl: student.profilePictureUrl || "",
  };
};

export const formatFormDataToBodyData = (student: FormInputs): UpdateStudentBody => {
  return {
    name: student.name,
    lastName: student.lastName,
    email: student.email.toLowerCase().trim(),
    mobile: student.mobile,
    profilePictureUrl: student.profilePictureUrl ? student.profilePictureUrl : null,
  };
};
