import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const isEmailUsed = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "admins/isEmailUsed",
  async (email: string, { extra: { userGateway } }) => {
    return userGateway!.isEmailUsed(email);
  }
);

export const resetIsEmailUsed = createAction("admins/resetIsEmailUsed");
