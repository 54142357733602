import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateTeacherAccountBody } from "@user-management-context/write/domain/types/teacher/teacher";

export const createTeacherAccount = createAsyncThunk<void, CreateTeacherAccountBody, { extra: Partial<Dependencies> }>(
  "users/createTeacherAccount",
  async (body: CreateTeacherAccountBody, { extra: { userGateway } }) => {
    return userGateway!.createTeacherAccount(body);
  }
);
