import { createReducer } from "@reduxjs/toolkit";
import { StudentLessonsRetrievalState } from "@redux/app-state";
import { studentRetrieveLessons } from "./retrieve-lessons";

const initialState: StudentLessonsRetrievalState = { fetching: "idle", data: [] };

export const studentRetrieveLessonsReducer = createReducer<StudentLessonsRetrievalState>(initialState, builder => {
  builder.addCase(studentRetrieveLessons.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveLessons.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveLessons.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
