import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "@redux/app-state";
import { AdminTrainingObjective } from "../../../../../domain/types/training";

export const selectTrainingObjectivesRetrievalForAdmin = createSelector(
  [(state: AppState) => state.adminTrainingObjectivesRetrieval],
  ({ data: { studentObjectives, teacherObjectives } }) => {
    const objectives: AdminTrainingObjective[] = [];
    const addedteacherObjectives: string[] = [];
    for (const o of studentObjectives) {
      const teacherObjective = teacherObjectives.find(obj => obj.id === o.id);
      objectives.push({
        id: o.id,
        title: o.title,
        description: o.description,
        doneByStudent: o.done,
        doneByTeacher: teacherObjective?.done ?? null,
        number: o.number,
      });
      if (teacherObjective) addedteacherObjectives.push(teacherObjective.id);
    }

    const notAddedTeacherObjectives = teacherObjectives.filter(o => !addedteacherObjectives.includes(o.id));
    for (const o of notAddedTeacherObjectives) {
      objectives.push({
        id: o.id,
        title: o.title,
        description: o.description,
        doneByStudent: null,
        doneByTeacher: o.done,
        number: o.number,
      });
    }

    return { objectives: objectives.sort((a, b) => a.number - b.number) };
  }
);
