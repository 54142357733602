import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { User } from "../../../domain/types/user";

export const retrieveUserProfile = createAsyncThunk<User, void, { extra: Partial<Dependencies> }>(
  "users/retrieveUserProfile",
  async (_: void, { extra: { userGateway } }) => {
    return userGateway!.profile();
  }
);

export const resetRetrieveUserProfile = createAction("users/resetRetrieveUserProfile");
