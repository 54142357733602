import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderStudentInternshipInvitationBody } from "@user-management-context/shared/application/ports/provider-repository";

export const inviteStudentToInternship = createAsyncThunk<void, ProviderStudentInternshipInvitationBody, { extra: Partial<Dependencies> }>(
  "providers/inviteStudentToInternship",
  async (invitation: ProviderStudentInternshipInvitationBody, { extra: { providerRepository } }) => {
    return providerRepository!.inviteStudentToInternship(invitation);
  }
);

export const resetInviteStudentToInternship = createAction("budgets/resetInviteStudentToInternship");
