import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingSessions } from "../../../read/application/use-cases/shared/training-sessions-retrieval/retrieve-training-sessions";
import { teacherRetrieveTrainings } from "../../../read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings";
import { studentRetrieveTrainings } from "../../../read/application/use-cases/student/trainings-retrieval/retrieve-trainings";

export const useAttendanceSheetSignature = (id: string | undefined, role: "student" | "teacher", isAdmin: boolean) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector((state: AppState) => state.attendanceSheetSignature);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Signature enregistrée!" });
      if (id) dispatch(retrieveTrainingSessions(id));

      if (!isAdmin) {
        // Used to display correct status on teacher / student sidebar
        if (role === "student") dispatch(studentRetrieveTrainings());
        else dispatch(teacherRetrieveTrainings());
      }
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'enregistrement de la signature", variant: "destructive" });
    }
  }, [dispatch, processing, id, role, toast, isAdmin]);
};
