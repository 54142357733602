import { TokenStorageGateway } from "../application/ports/token-storage-gateway";
import Cookies from "universal-cookie";
import { Nullable } from "../core/types/nullable";

const cookies = new Cookies();

export class CookieTokenStorageGateway implements TokenStorageGateway {
  retrieveAccessToken(): Nullable<string> {
    const userInfo = cookies.get("userInfo");
    return userInfo?.accessToken ?? null;
  }
}
