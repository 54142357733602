import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";

export const adminCreateLesson = createAsyncThunk<string, CreateLessonBody, { extra: Partial<Dependencies> }>(
  "lessons/adminCreateLesson",
  async (lesson: CreateLessonBody, { extra: { adminLessonGateway } }) => {
    return adminLessonGateway!.create(lesson);
  }
);

export const adminResetCreateLesson = createAction("lessons/adminResetCreateLesson");
