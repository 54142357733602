import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveProvider } from "./retrieve-provider";
import { Provider } from "@user-management-context/read/domain/types/admin/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<Provider>> = { fetching: "idle", data: null };

export const retrieveProviderReducer = createReducer<RetrievalState<Nullable<Provider>>>(initialState, builder => {
  builder.addCase(retrieveProvider.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveProvider.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveProvider.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
