import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderInvitationListItem } from "@user-management-context/read/domain/types/provider/invitation";

export const retrieveProviderStudentInvitations = createAsyncThunk<ProviderInvitationListItem[], void, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderStudentInvitations",
  async (_: void, { extra: { providerRepository } }) => {
    return providerRepository!.invitationList();
  }
);
