import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useProviderProfileUpdate } from "@user-management-context/primary/provider/provider-user-detail/use-provider-user-update";
import { retrieveProviderProfile } from "@user-management-context/read/application/use-cases/provider/provider-profile-retrieval/retrieve-provider";
import { selectProviderProfileRetrieval } from "@user-management-context/read/application/use-cases/provider/provider-profile-retrieval/selectors/provider-profile-selectors";
import { ProviderUserForm } from "@user-management-context/primary/provider/provider-user-detail/provider-user-form.component";
import { UpdateProviderProfileBody } from "@user-management-context/write/domain/types/provider/provider";
import { ProviderUserDetailSkeleton } from "@user-management-context/primary/provider/provider-user-detail/provider-user-detail.skeleton";
import { updateProviderProfile } from "@user-management-context/write/application/use-cases/provider/provider-profile-update/update-provider-profile-";

export const ProviderUserDetailContainer = () => {
  const dispatch = useAppDispatch();

  const { provider, isLoading } = useAppSelector(selectProviderProfileRetrieval);

  useEffect(() => {
    dispatch(retrieveProviderProfile());
  }, [dispatch]);

  const saveProvider = async (body: UpdateProviderProfileBody) => {
    dispatch(updateProviderProfile(body));
  };

  useProviderProfileUpdate();

  if (isLoading) return <ProviderUserDetailSkeleton />;

  return <ProviderUserForm provider={provider} onSubmit={saveProvider} />;
};
