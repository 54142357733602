import React, { useEffect, useMemo } from "react";
import logo from "../../../../assets/img/logo/logo.png";
import clsx from "clsx";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { AppState } from "src/redux/app-state";
import { retrieveUserProfile } from "@user-management-context/read/application/use-cases/user-profile/retrieve-user-profile";
import { AppRoute, REFUND_REQUEST_ROUTE_PATH, STUDENT_FUNDING_REQUEST_MENU_LABEL, TRAININGS_ROUTE_PATH } from "src/routes";
import { retrieveFundingRequestEligibility } from "@academy-context/read/application/use-cases/student/funding-request-eligibility-retrieval/retrieve-funding-request-eligibility";
import { studentRetrieveTrainings } from "@academy-context/read/application/use-cases/student/trainings-retrieval/retrieve-trainings";
import { teacherRetrieveTrainings } from "@academy-context/read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings";
import { DangerSign } from "@shared-kernel/primary/shared/danger-sign/danger-sign";
import bolt from "../../../../assets/img/logo/bolt.png";
import { twMerge } from "tailwind-merge";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { retrieveStudentProfile } from "@user-management-context/read/application/use-cases/student/profile-retrieval/retrieve-student-profile";
import { selectStudentProfileRetrieval } from "@user-management-context/read/application/use-cases/student/profile-retrieval/selectors/student-profile-selectors";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

const ROLE_MAP = {
  [ROLES.ADMIN]: "Admin",
  [ROLES.STUDENT]: "Elève",
  [ROLES.TEACHER]: "Professeur",
  [ROLES.PROVIDER]: "Prestataire",
};

interface Props {
  routes: AppRoute[];
  logout: () => void;
}

export const SideBar = ({ routes, logout }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: user } = useAppSelector((state: AppState) => state.userProfileRetrieval);
  const { data } = useAppSelector((state: AppState) => state.fundingRequestEligibilityRetrieval);
  const { data: studentTrainings } = useAppSelector((state: AppState) => state.studentTrainingsRetrieval);
  const { data: teacherTrainings } = useAppSelector((state: AppState) => state.teacherTrainingsRetrieval);
  const student = useAppSelector(selectStudentProfileRetrieval);

  const isStudent = user?.role === ROLES.STUDENT;
  const isTeacher = user?.role === ROLES.TEACHER;

  useEffect(() => {
    dispatch(retrieveUserProfile());
    if (isStudent) dispatch(retrieveStudentProfile());
  }, [dispatch, isStudent]);

  const studentIsAvailableAction = useMemo(() => studentTrainings.some(t => t.isAvailableAction), [studentTrainings]);
  const teacherIsAvailableAction = useMemo(() => teacherTrainings.some(t => t.isAvailableAction), [teacherTrainings]);

  useEffect(() => {
    if (isStudent) {
      dispatch(retrieveFundingRequestEligibility());
      dispatch(studentRetrieveTrainings());
    }
  }, [dispatch, isStudent]);

  useEffect(() => {
    if (isTeacher) {
      dispatch(teacherRetrieveTrainings());
    }
  }, [dispatch, isTeacher]);

  let filteredRoutes = routes.filter(r => r.name);
  if (isStudent && !student.canRequestARefund) {
    filteredRoutes = filteredRoutes.filter(r => r.path !== REFUND_REQUEST_ROUTE_PATH);
  }

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <div className="h-screen w-64 bg-white dark:bg-slate-900">
      <aside id="sidebar" className="fixed left-0 top-0 z-40 h-screen w-64 transition-transform" aria-label="Sidebar">
        <div className="flex h-full flex-col overflow-y-auto border-r border-slate-200 bg-white px-3 py-0 dark:border-slate-700 dark:bg-slate-900">
          <Link to={user && user.role !== ROLES.ADMIN ? "/dashboard" : "/transfers"}>
            <div className="flex items-center rounded-lg px-3 py-0 text-slate-900">
              <div className="relative h-[125px]">
                <img src={logo} className="w-full" alt="logo" />
              </div>
            </div>
          </Link>
          <Separator />
          {user && (
            <div className={clsx("flex flex-col justify-evenly items-center relative my-3")}>
              <span className="font-bold text-center">
                {user.name} {user.lastName}
              </span>
              <span>{ROLE_MAP[user.role]}</span>
            </div>
          )}
          <Separator />
          <div onClick={onLogout} role="button" className="flex justify-center items-center py-4 hover:font-medium">
            <span>Se déconnecter</span>
          </div>
          <Separator />
          <ul className="space-y-2 text-sm font-medium">
            {filteredRoutes.map((route, index) => (
              <li key={index}>
                <NavLink
                  to={route.path}
                  end={route.end}
                  className={({ isActive }) =>
                    twMerge(
                      clsx(
                        "flex items-center rounded-lg px-3 py-2 text-slate-900 hover:bg-slate-100 dark:text-white dark:hover:bg-slate-700",
                        isActive && "bg-slate-200 dark:text-black dark:hover:text-white"
                      )
                    )
                  }
                >
                  {route.name === STUDENT_FUNDING_REQUEST_MENU_LABEL && data?.isEligibleForAFundingRequest ? (
                    <>
                      <div className="h-[30px]">
                        <img src={bolt} alt="bolt" className="h-full" />
                      </div>
                      <span className="ml-3 flex-1 font-black">{route.name}</span>
                    </>
                  ) : route.path === TRAININGS_ROUTE_PATH && (studentIsAvailableAction || teacherIsAvailableAction) ? (
                    <>
                      <div className="h-[30px]">
                        <DangerSign />
                      </div>
                      <span className="ml-3 flex-1 font-black">{route.name}</span>
                    </>
                  ) : (
                    <span className="ml-3 flex-1">{route.name}</span>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
          {/* Dark mode disabled */}
          {/* <div className="mt-auto flex">
            <div className="flex w-full">
              <ModeToggle />
            </div>
          </div>*/}
        </div>
      </aside>
    </div>
  );
};
