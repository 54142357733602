import { isValid } from "iban";
import { boolean, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { CreateProviderBody } from "@user-management-context/write/domain/types/admin/provider";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { Provider } from "@user-management-context/read/domain/types/admin/provider";

export type Validation = "provider" | "admin";

export type ProviderFormInputs = {
  name: string;
  tag: string;
  email: string;
  mobile: string;
  iban: string;
  address: string;
  additionalAddress: string;
  isTrainingFollowUpManagedByOperaOff: boolean;
  areConventionSentToOperaOff: boolean;
  siret: string;
};

export const defaultValues = {
  name: "",
  tag: "",
  email: "",
  mobile: "",
  iban: "",
  address: "",
  additionalAddress: "",
  isTrainingFollowUpManagedByOperaOff: false,
  areConventionSentToOperaOff: false,
  siret: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  tag: string().max(25).required(REQUIRED_FIELD),
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  mobile: string().test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  iban: string()
    .max(255)
    .test("iban", "L'IBAN renseigné est invalide", value => {
      if (value) {
        return isValid(value);
      }
      // IBAN is empty which is valid since it can be null
      return true;
    })
    .required(REQUIRED_FIELD),
  address: string().max(255).required(REQUIRED_FIELD),
  additionalAddress: string().max(255).required(REQUIRED_FIELD),
  isTrainingFollowUpManagedByOperaOff: boolean().required(REQUIRED_FIELD),
  areConventionSentToOperaOff: boolean().required(REQUIRED_FIELD),
  // students: array().of(string()).required(REQUIRED_FIELD),
  siret: string().max(14),
});

export const formatProviderFormDataToBodyData = (provider: ProviderFormInputs): CreateProviderBody => {
  return {
    name: provider.name,
    tag: provider.tag,
    email: provider.email.toLowerCase().trim(),
    mobile: provider.mobile.toLowerCase().trim(),
    iban: provider.iban,
    address: provider.address,
    additionalAddress: provider.additionalAddress,
    configuration: {
      isTrainingFollowUpManagedByOperaOff: provider.isTrainingFollowUpManagedByOperaOff,
      areConventionSentToOperaOff: provider.areConventionSentToOperaOff,
    },
    // TODO
    // students: provider.students,
    students: [],
    siret: provider.siret || null,
  };
};

export const formatProviderDataToFormData = (provider: Provider): ProviderFormInputs => {
  return {
    name: provider.name,
    tag: provider.tag,
    email: provider.email,
    mobile: provider.mobile,
    iban: provider.iban,
    address: provider.address,
    additionalAddress: provider.additionalAddress,
    isTrainingFollowUpManagedByOperaOff: provider.configuration.isTrainingFollowUpManagedByOperaOff,
    areConventionSentToOperaOff: provider.configuration.areConventionSentToOperaOff,
    // students: provider.students,
    siret: provider.siret ?? "",
  };
};
