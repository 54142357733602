import { createReducer } from "@reduxjs/toolkit";
import { ConventionUploadState } from "@redux/app-state";
import { resetUploadSignedConvention, uploadSignedConvention } from "./upload-signed-convention";
import { deleteSignedConvention } from "../signed-convention-deletion/delete-signed-convention";

const initialState: ConventionUploadState = { processing: "idle", conventionUrl: null };

export const uploadSignedConventionReducer = createReducer<ConventionUploadState>(initialState, builder => {
  builder.addCase(uploadSignedConvention.fulfilled, (state, { payload }) => {
    return {
      ...state,
      conventionUrl: payload,
      processing: "success",
    };
  });
  builder.addCase(uploadSignedConvention.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(uploadSignedConvention.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUploadSignedConvention, () => {
    return initialState;
  });
  builder.addCase(deleteSignedConvention.fulfilled, () => {
    return initialState;
  });
});
