import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { InviteUserBody } from "@user-management-context/write/domain/types/admin/user-invitation";

export const inviteUser = createAsyncThunk<string, InviteUserBody, { extra: Partial<Dependencies> }>(
  "users/inviteUser",
  async (body: InviteUserBody, { extra: { adminUserInvitationGateway } }) => {
    return adminUserInvitationGateway!.invite(body);
  }
);

export const resetInviteUser = createAction("users/resetInviteUser");
