import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useStudentUserUpdate } from "./use-student-user-update";
import { StudentUserForm } from "./student-user-form.component";
import { UpdateStudentBody } from "@user-management-context/write/domain/types/student/student";
import { selectStudentProfileRetrieval } from "../../../read/application/use-cases/student/profile-retrieval/selectors/student-profile-selectors";
import { retrieveStudentProfile } from "../../../read/application/use-cases/student/profile-retrieval/retrieve-student-profile";
import { updateStudentProfile } from "../../../write/application/use-cases/student/update-profile/update-student-profile";

export const StudentUserDetail = () => {
  const dispatch = useAppDispatch();
  const student = useAppSelector(selectStudentProfileRetrieval);

  useEffect(() => {
    dispatch(retrieveStudentProfile());
  }, [dispatch]);

  const saveStudent = async (body: UpdateStudentBody) => {
    await dispatch(updateStudentProfile(body));
  };

  useStudentUserUpdate();

  return <StudentUserForm student={student} onSubmit={saveStudent} />;
};
