import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { TransfersTable } from "../shared/components/transfer/transfers-table";
import { useTransferUpdate } from "../shared/components/transfer/use-transfers-update";
import { retrieveTransferList } from "../../../read/application/use-cases/admin/admin-transfer-list-retrieval/retrieve-transfer-list";
import { AppState } from "@redux/app-state";
import { resetUpdateTransfer } from "@academy-context/write/application/use-cases/admin/admin-transfer-update/update-transfer";

export const TransfersList = () => {
  const dispatch = useAppDispatch();
  const { data: transfers } = useAppSelector((state: AppState) => state.transferListRetrieval);

  useEffect(() => {
    dispatch(retrieveTransferList());
  }, [dispatch]);

  const onRefreshTransfers = () => {
    dispatch(resetUpdateTransfer());
    dispatch(retrieveTransferList());
  };

  useTransferUpdate({ onRefresh: onRefreshTransfers });

  return <TransfersTable transfers={transfers} title="Paiements" />;
};
