import { object, string } from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { UpdateProviderProfileBody } from "@user-management-context/write/domain/types/provider/provider";

export type ProviderProfileUpdateFormInputs = {
  name: string;
  mobile: string;
  address: string;
  additionalAddress: string;
  iban: string;
  siret: string;
};

export const defaultValues: ProviderProfileUpdateFormInputs = {
  name: "",
  mobile: "",
  address: "",
  additionalAddress: "",
  iban: "",
  siret: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  address: string().max(255).required(REQUIRED_FIELD),
  additionalAddress: string().max(255).required(REQUIRED_FIELD),
  iban: string().max(255).required(REQUIRED_FIELD),
  siret: string().max(14),
});

export const providerProfileUpdateFormatDataToFormData = (provider: Provider): ProviderProfileUpdateFormInputs => {
  return {
    name: provider.name,
    mobile: provider.mobile,
    address: provider.address,
    additionalAddress: provider.additionalAddress,
    iban: provider.iban,
    siret: provider.siret ?? "",
  };
};

export const providerProfileUpdateFormatFormDataToBodyData = (provider: ProviderProfileUpdateFormInputs): UpdateProviderProfileBody => {
  return {
    name: provider.name,
    mobile: provider.mobile,
    address: provider.address,
    additionalAddress: provider.additionalAddress,
    iban: provider.iban,
    siret: provider.siret || null,
  };
};
