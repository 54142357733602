import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TrainingObjective } from "../../../../domain/types/training";

export const adminRetrieveTrainingObjectives = createAsyncThunk<
  { studentObjectives: TrainingObjective[]; teacherObjectives: TrainingObjective[] },
  { trainingId: string },
  { extra: Partial<Dependencies> }
>("trainingObjectives/adminRetrieveTrainingObjectives", async ({ trainingId }: { trainingId: string }, { extra: { trainingGateway } }) => {
  const studentObjectives = await trainingGateway!.getTrainingObjectives(trainingId, "student");
  const teacherObjectives = await trainingGateway!.getTrainingObjectives(trainingId, "teacher");
  return { studentObjectives, teacherObjectives };
});
