import { retrieveExpenses } from "../../../read/application/use-cases/admin/expenses-retrieval/retrieve-expenses";
import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { UseFormReset } from "react-hook-form";
import { FormInputs } from "./form-validation/expense";
import { resetCreateExpense } from "../../../write/application/use-cases/admin/expense-creation/create-expense";

export const selectExpenseCreate = (state: AppState) => state.expenseCreate;

interface Props {
  reset: UseFormReset<FormInputs>;
}

export const useExpenseCreate = ({ reset }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justCreatedExpenseId, fetching } = useAppSelector(selectExpenseCreate);

  useEffect(() => {
    if (justCreatedExpenseId) {
      toast({ description: "Masterclass / Frais ajouté!" });
      reset();
      dispatch(resetCreateExpense());
      dispatch(retrieveExpenses());
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de l'ajout de la masterclass / frais", variant: "destructive" });
    }
  }, [justCreatedExpenseId, fetching, dispatch, toast, reset]);
};
