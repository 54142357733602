import { createReducer } from "@reduxjs/toolkit";
import { FundingRequestReviewState } from "@redux/app-state";
import { resetReviewFundingRequest, reviewFundingRequest } from "./review-funding-request";

const initialState: FundingRequestReviewState = { fetching: "idle" };

export const reviewFundingRequestReducer = createReducer<FundingRequestReviewState>(initialState, builder => {
  builder.addCase(reviewFundingRequest.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(reviewFundingRequest.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(reviewFundingRequest.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetReviewFundingRequest, () => {
    return initialState;
  });
});
