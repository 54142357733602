import { createReducer } from "@reduxjs/toolkit";
import { CreationState } from "../../../../../../redux/app-state";
import { createProvider, resetCreateProvider } from "./create-provider";

const initialState: CreationState = { justCreatedId: null, processing: "idle" };

export const createProviderReducer = createReducer<CreationState>(initialState, builder => {
  builder.addCase(createProvider.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedId: payload,
      processing: "success",
    };
  });
  builder.addCase(createProvider.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(createProvider.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetCreateProvider, () => {
    return initialState;
  });
});
