import { getNormalizedString } from "../../../../../../../utils/utils";
import { TeacherListElement } from "../../../../../domain/types/admin/teacher";
import { AppState } from "@redux/app-state";

export type TeacherExtended = TeacherListElement & { filterString: string };

export const selectTeachersRetrievalForModal = (state: AppState) => {
  const {
    teachersRetrieval: { data, fetching },
  } = state;

  const extendedTeachers: TeacherExtended[] = data.map(t => {
    return {
      ...t,
      filterString: getNormalizedString(`${t.name} ${t.lastName}`),
    };
  });

  return {
    data: extendedTeachers.sort((a, b) => {
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();

      if (lastNameB > lastNameA) {
        return -1;
      } else if (lastNameB < lastNameA) {
        return 1;
      }
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameB > nameA) {
        return -1;
      } else if (nameB < nameA) {
        return 1;
      }
      return 0;
    }),
    fetching,
  };
};
