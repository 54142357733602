import { object, string } from "yup";
import { User } from "../../../../read/domain/types/user";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export type FormInputs = {
  email: string;
};

export const defaultValues = {
  email: "",
};

export const schema = object().shape({
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
});

export const formatUserDataToFormData = (user: User): FormInputs => {
  return {
    ...defaultValues,
    email: user.email,
  };
};

export const formatFormDataToBodyData = (user: FormInputs): { email: string } => {
  return {
    email: user.email.toLowerCase().trim(),
  };
};
