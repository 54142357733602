import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveCollaborationRequestList } from "./retrieve-collaboration-request-list";
import { CollaborationRequest } from "@academy-context/read/domain/types/admin/collaboration-request";

const initialState: RetrievalState<CollaborationRequest[]> = { fetching: "idle", data: [] };

export const retrieveCollaborationRequestListReducer = createReducer<RetrievalState<CollaborationRequest[]>>(initialState, builder => {
  builder.addCase(retrieveCollaborationRequestList.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveCollaborationRequestList.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveCollaborationRequestList.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
