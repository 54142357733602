import { createReducer } from "@reduxjs/toolkit";
import { UpdatePasswordState } from "@redux/app-state";
import { updatePassword } from "./update-password";

const initialState: UpdatePasswordState = { fetching: "idle" };

export const updatePasswordReducer = createReducer<UpdatePasswordState>(initialState, builder => {
  builder.addCase(updatePassword.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(updatePassword.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updatePassword.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
