import { createReducer } from "@reduxjs/toolkit";
import { adminResetConfirmProviderInternship, adminConfirmProviderInternship } from "./confirm-provider-internship";
import { UpdateState } from "src/redux/app-state";

const initialState: UpdateState = { justUpdatedId: null, processing: "idle" };

export const adminConfirmProviderInternshipReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(adminConfirmProviderInternship.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.id,
      processing: "success",
    };
  });
  builder.addCase(adminConfirmProviderInternship.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(adminConfirmProviderInternship.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(adminResetConfirmProviderInternship, () => {
    return initialState;
  });
});
