import { createReducer } from "@reduxjs/toolkit";
import { TeachersRetrievalState } from "@redux/app-state";
import { adminRetrieveTeachers } from "./retrieve-teachers";

const initialState: TeachersRetrievalState = { fetching: "idle", data: [] };

export const retrieveTeachersReducer = createReducer<TeachersRetrievalState>(initialState, builder => {
  builder.addCase(adminRetrieveTeachers.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveTeachers.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveTeachers.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
