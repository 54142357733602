import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { SimpleTable } from "../../../../shared-kernel/primary/shared/simple-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { AdminBudgetExtended, getFunderLabel } from "@academy-context/read/domain/types/admin/budget";

interface Props {
  budgets: AdminBudgetExtended[];
}

const headers = [
  { title: "Enregistré le" },
  { title: "Date (de fin de stage)" },
  { title: "Elève" },
  { title: "Financeur" },
  { title: "Montant total" },
  { title: "OPERA Off" },
  { title: "Total crédité" },
  { title: "Commentaire" },
  { title: "Module" },
];

export const BudgetListSummary = ({ budgets }: Props) => {
  const entries = useMemo(
    () =>
      budgets.map(b => {
        return [
          { value: formatDateToLocale(b.creationDate) },
          { value: b.localeDate },
          { value: b.student },
          { value: getFunderLabel(b.funder) },
          { value: formatToCurrency(b.allocatedAmount) },
          { value: formatToCurrency(b.operaOffCommission) },
          { value: formatToCurrency(b.totalAmount) },
          { value: b.commentary },
          { value: b.externalFundingRequestId },
        ];
      }),
    [budgets]
  );

  return (
    <CustomCard
      title="Historique des derniers financements"
      headerChildren={
        <Link to={"/budgets/list"}>
          <Button>Voir tous les financements</Button>
        </Link>
      }
    >
      <SimpleTable headers={headers} entries={entries} />
    </CustomCard>
  );
};
