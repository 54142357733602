import React from "react";

interface Props {
  url: string;
}

export const Survey = ({ url }: Props) => (
  <iframe src={url} width="640" height="1146" title="survey">
    Chargement…
  </iframe>
);
