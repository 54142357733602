import { UpdateUserBody } from "@user-management-context/write/domain/types/user";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const updateUser = createAsyncThunk<void, UpdateUserBody, { extra: Partial<Dependencies> }>(
  "users/updateUser",
  async (body: UpdateUserBody, { extra: { userGateway } }) => {
    return userGateway!.update(body);
  }
);

export const resetUpdateUser = createAction("users/resetUpdateUser");
