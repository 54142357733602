import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveTrainingObjectives } from "../../../read/application/use-cases/shared/training-objectives-retrieval/retrieve-training-objectives";
import { adminRetrieveTrainingObjectives } from "../../../read/application/use-cases/admin/training-objectives-retrieval/retrieve-training-objectives";
import { teacherRetrieveTrainings } from "../../../read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings";
import { studentRetrieveTrainings } from "../../../read/application/use-cases/student/trainings-retrieval/retrieve-trainings";

export const selectObjectiveUpdate = (state: AppState) => state.trainingObjectiveUpdate;

interface Props {
  trainingId: string;
  role: "student" | "teacher" | "admin";
}

export const useObjectiveUpdate = ({ trainingId, role }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectObjectiveUpdate);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Bilan mis à jour!" });
      if (role === "admin") {
        dispatch(adminRetrieveTrainingObjectives({ trainingId }));
      } else {
        dispatch(retrieveTrainingObjectives({ trainingId, role }));
      }
      // Used to display correct status on teacher / student sidebar
      if (role === "student") dispatch(studentRetrieveTrainings());
      else dispatch(teacherRetrieveTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de la mise à jour du bilan", variant: "destructive" });
    }
  }, [processing, dispatch, trainingId, role, toast]);
};
