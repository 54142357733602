import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { verifyTokenValidity } from "../../../write/application/use-cases/token-verification/verify-token-validity";
import { TokenStatus } from "../../../shared/domain/types/enums/token";
import { ProviderAccountCreationComponent } from "@user-management-context/primary/provider/provider-account-creation/provider-account-creation.components";
import { ProviderAccountCreationSkeleton } from "@user-management-context/primary/provider/provider-account-creation/provider-account-creation.skeleton";
import { useProviderAccountCreate } from "@user-management-context/primary/provider/provider-account-creation/use-provider-create";
import { retrieveProviderPublicProfile } from "@user-management-context/read/application/use-cases/provider/public-profile-retrieval/retrieve-provider-public-profile";
import { createProviderAccount } from "@user-management-context/write/application/use-cases/provider/provider-account-creation/create-provider-account";
import { CreateProviderAccountBody } from "@user-management-context/write/domain/types/provider/provider";
import { selectProviderPublicProfileRetrieval } from "@user-management-context/read/application/use-cases/provider/public-profile-retrieval/selectors/provider-public-profile-retrieval-selectors";

export const ProviderAccountCreationContainer = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");

  const { provider, isLoading } = useAppSelector(selectProviderPublicProfileRetrieval);
  const { data: tokenFromDB, fetching } = useAppSelector((state: AppState) => state.tokenValidityCheck);

  const [tokenStatus, setTokenStatus] = useState<TokenStatus>("invalid");

  useEffect(() => {
    if (token && userId) dispatch(verifyTokenValidity({ userId, token }));
  }, [dispatch, token, userId]);

  useEffect(() => {
    if (tokenFromDB) setTokenStatus(tokenFromDB.status);
  }, [tokenFromDB]);

  useEffect(() => {
    if (tokenStatus === "valid" && userId) dispatch(retrieveProviderPublicProfile(userId));
  }, [dispatch, userId, tokenStatus]);

  useProviderAccountCreate();

  const onSubmit = async (body: CreateProviderAccountBody) => {
    dispatch(createProviderAccount(body));
  };

  if (fetching === "pending" || isLoading) return <ProviderAccountCreationSkeleton />;

  const tokenData = { status: tokenStatus, id: token };

  return <ProviderAccountCreationComponent provider={provider} onSubmit={onSubmit} userId={userId} token={tokenData} />;
};
