import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateProvider, updateProvider } from "./update-provider";
import { UpdateState } from "@redux/app-state";

const initialState: UpdateState = { justUpdatedId: null, processing: "idle" };

export const updateProviderReducer = createReducer<UpdateState>(initialState, builder => {
  builder.addCase(updateProvider.fulfilled, (state, { meta }) => {
    return {
      ...state,
      justUpdatedId: meta.arg.id,
      processing: "success",
    };
  });
  builder.addCase(updateProvider.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateProvider.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateProvider, () => {
    return initialState;
  });
});
