import { createReducer } from "@reduxjs/toolkit";
import { ResumeUploadState } from "@redux/app-state";
import { resetUploadResume, uploadResume } from "./upload-resume";

const initialState: ResumeUploadState = { processing: "idle", resumeUrl: null };

export const uploadResumeReducer = createReducer<ResumeUploadState>(initialState, builder => {
  builder.addCase(uploadResume.fulfilled, (state, { payload }) => {
    return {
      ...state,
      resumeUrl: payload,
      processing: "success",
    };
  });
  builder.addCase(uploadResume.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(uploadResume.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUploadResume, () => {
    return initialState;
  });
});
