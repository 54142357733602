import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { createAdminAccount } from "../../../../user-management-context/write/application/use-cases/admin/account-creation/create-admin-account";
import { useAdminAccountCreation } from "./use-admin-account-creation";
import { useSearchParams } from "react-router-dom";
import { AppState } from "@redux/app-state";
import { TokenStatus } from "../../../../user-management-context/shared/domain/types/enums/token";
import { verifyTokenValidity } from "../../../../user-management-context/write/application/use-cases/token-verification/verify-token-validity";
import { useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues, adminFormatFormDataToBodyData, formatAdminDataToFormData } from "./form-validation/admin";
import { yupResolver } from "@hookform/resolvers/yup";
import { generateMessage } from "../../../../user-management-context/primary/shared/account-creation";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { PasswordInput } from "../../../../user-management-context/primary/shared/password-input/password-input.components";
import { retrieveAdminPublicProfile } from "@user-management-context/read/application/use-cases/admin/public-profile-retrieval/retrieve-admin-public-profile";

export const AdminAccountCreation = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const token = searchParams.get("token");
  const { data: admin } = useAppSelector((state: AppState) => state.adminPublicProfileRetrieval);
  const { data: tokenFromDB } = useAppSelector((state: AppState) => state.tokenValidityCheck);
  const [tokenStatus, setTokenStatus] = useState<TokenStatus>("invalid");

  useEffect(() => {
    if (token && userId) dispatch(verifyTokenValidity({ userId, token }));
  }, [dispatch, token, userId]);

  useEffect(() => {
    if (tokenFromDB) setTokenStatus(tokenFromDB.status);
  }, [tokenFromDB]);

  useEffect(() => {
    if (tokenStatus === "valid" && userId) dispatch(retrieveAdminPublicProfile(userId));
  }, [dispatch, userId, tokenStatus]);

  useEffect(() => {
    if (admin) reset({ ...formatAdminDataToFormData(admin), password: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  useAdminAccountCreation();

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    formState: { isDirty },
    watch,
  } = form;

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = adminFormatFormDataToBodyData(formBody, userId as string, token as string);
    dispatch(createAdminAccount(body));
  };

  if (tokenStatus !== "valid") return generateMessage(tokenStatus);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Prénom</FormLabel>
              <FormControl>
                <Input {...field} className="text-black" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nom de famille</FormLabel>
              <FormControl>
                <Input {...field} className="text-black" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="space-y-2">
          <Label>Email</Label>
          <Input value={admin?.email || ""} disabled className="text-black" />
        </div>
        <PasswordInput control={form.control} password={watch("password")} />
        <div className="col-span-2 flex justify-end">
          <Button type="submit" disabled={!isDirty} className="ml-2">
            Créer compte
          </Button>
        </div>
      </form>
    </Form>
  );
};
