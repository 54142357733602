import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";
import { Nullable } from "@shared-kernel/core/types/nullable";

export const retrieveProviderStudentByEmail = createAsyncThunk<Nullable<ProviderStudent>, string, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderStudentByEmail",
  async (email: string, { extra: { userGateway } }) => {
    return userGateway!.getStudentByEmail(email);
  }
);

export const resetRetrieveProviderStudentByEmail = createAction("users/resetRetrieveProviderStudentByEmail");
