import { isLoadingState, sortAlphabetically } from "@utils/utils";
import { AppState } from "@redux/app-state";
import { createSelector } from "@reduxjs/toolkit";

export const selectProviderStudentInvitationRetrievalForList = createSelector(
  (state: AppState) => state.providerStudentInvitationsRetrieval,
  providerStudentsRetrieval => {
    const { data, fetching } = providerStudentsRetrieval;

    const formattedData = [...data].sort((a, b) => sortAlphabetically(a.student.name, b.student.name));

    return {
      invitations: formattedData,
      isLoading: isLoadingState(fetching),
    };
  }
);
