import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useNavigate } from "react-router-dom";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ProviderListComponent } from "@user-management-context/primary/admin/provider-list/provider-list.components";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { selectProviderRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { ProviderListSkeleton } from "@user-management-context/primary/admin/provider-list/provider-list.skeleton";

export const ProviderListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { providers, isLoading } = useAppSelector(selectProviderRetrievalForList);

  useEffect(() => {
    dispatch(adminRetrieveProviders());
  }, [dispatch]);

  const onNewProvider = () => {
    navigate("/providers/create");
  };

  return (
    <CustomCard
      title="Prestataires"
      headerChildren={
        <div>
          <Button onClick={onNewProvider}>Nouveau prestataire</Button>
        </div>
      }
    >
      {isLoading ? <ProviderListSkeleton /> : <ProviderListComponent providers={providers} />}
    </CustomCard>
  );
};
