import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateFundingRequestBody } from "@academy-context/write/domain/types/student/funding-request";

export const createFundingRequest = createAsyncThunk<string, CreateFundingRequestBody, { extra: Partial<Dependencies> }>(
  "fundingRequests/createFundingRequest",
  async (fundingRequest: CreateFundingRequestBody, { extra: { fundingRequestGateway } }) => {
    return fundingRequestGateway!.create(fundingRequest);
  }
);

export const resetCreateFundingRequest = createAction("fundingRequests/resetCreateFundingRequest");
