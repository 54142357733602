import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateInstantFundingRequestBody } from "@academy-context/write/domain/types/admin/funding-request";

export const createInstantFundingRequest = createAsyncThunk<string, CreateInstantFundingRequestBody, { extra: Partial<Dependencies> }>(
  "fundingRequests/createInstantFundingRequest",
  async (fundingRequest: CreateInstantFundingRequestBody, { extra: { fundingRequestGateway } }) => {
    return fundingRequestGateway!.createInstantFundingRequest(fundingRequest);
  }
);

export const resetCreateInstantFundingRequest = createAction("fundingRequests/resetCreateInstantFundingRequest");
