import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";
import { LessonCreate } from "./lesson-create.component";
import { selectLessonsRetrievalForSummmaryList } from "../../../read/application/use-cases/admin/lessons-retrieval/last-seasons/selectors/lessons-list-summary-selectors";
import { LessonListSummary } from "./lesson-list-summary.components";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { adminCreateLesson } from "../../../write/application/use-cases/admin/lesson-creation/create-lesson";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { adminRetrieveLastLessons } from "../../../read/application/use-cases/admin/lessons-retrieval/last-seasons/retrieve-last-lessons";

export const LessonAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: lessons } = useAppSelector(selectLessonsRetrievalForSummmaryList);

  useEffect(() => {
    dispatch(adminRetrieveLastLessons());
    dispatch(retrieveStudents());
    dispatch(adminRetrieveTeachers());
  }, [dispatch]);

  const saveLesson = async (lesson: CreateLessonBody) => {
    await dispatch(adminCreateLesson(lesson));
  };

  return (
    <div className="space-y-5">
      <LessonCreate onSubmit={saveLesson} />
      <LessonListSummary lessons={lessons} />
    </div>
  );
};
