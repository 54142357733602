import { createReducer } from "@reduxjs/toolkit";
import { TeacherStudentsRetrievalState } from "@redux/app-state";
import { retrieveTeacherStudents } from "./retrieve-students";

const initialState: TeacherStudentsRetrievalState = { fetching: "idle", data: [] };

export const retrieveTeacherStudentsReducer = createReducer<TeacherStudentsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTeacherStudents.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTeacherStudents.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTeacherStudents.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
