import { createReducer } from "@reduxjs/toolkit";
import { adminRetrieveTrainingObjectives } from "./retrieve-training-objectives";
import { AdminTrainingObjectivesRetrievalState } from "@redux/app-state";

const initialState: AdminTrainingObjectivesRetrievalState = { fetching: "idle", data: { studentObjectives: [], teacherObjectives: [] } };

export const adminRetrieveTrainingObjectivesReducer = createReducer<AdminTrainingObjectivesRetrievalState>(initialState, builder => {
  builder.addCase(adminRetrieveTrainingObjectives.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveTrainingObjectives.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveTrainingObjectives.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
