import config from "../../../../config/backend";
import { StudentExpensePort } from "../../ports/student-expense-port";
import { TokenStorageGateway } from "../../../../shared-kernel/application/ports/token-storage-gateway";
import { StudentExpenseListVM } from "../../../read/domain/types/student/expense";
import { HttpGateway } from "../../../../shared-kernel/secondary/http-gateway";

export class StudentBackendExpenseGateway extends HttpGateway implements StudentExpensePort {
  private _route: string = "student/v1/expenses";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async all(): Promise<StudentExpenseListVM[]> {
    const { data: expenses } = await this._instance.get(this._route);
    return expenses;
  }
}
