import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";
import { number, object, string } from "yup";
import { ADMIN_MIN_HOURLY_PRICE } from "../../../../write/domain/constants/shared";
import { MAX_HOURLY_PRICE, TEACHER_MIN_HOURLY_PRICE } from "../../../../write/domain/constants/shared";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export const MAX_CONVENTION_HOURS = 40;
export const MIN_CONVENTION_HOURS = 1;

export type FormInputs = {
  student: string;
  teacher: string;
  hours: number;
  hourlyPrice: number;
  date: string;
  commentary: string;
  fundingRequestId: string;
};

const today = new Date().toISOString();

export const defaultValues = {
  date: today,
  student: "",
  teacher: "",
  hours: 1,
  hourlyPrice: TEACHER_MIN_HOURLY_PRICE,
  commentary: "",
  fundingRequestId: "",
};

export const schema = object().shape({
  date: string().max(255).required(REQUIRED_FIELD),
  student: string().max(255).required(REQUIRED_FIELD),
  teacher: string().max(255).required(REQUIRED_FIELD),
  hours: number()
    .typeError("Une valeur est nécessaire")
    .min(MIN_CONVENTION_HOURS, `minimum ${MIN_CONVENTION_HOURS}h`)
    .max(MAX_CONVENTION_HOURS, `maximum ${MAX_CONVENTION_HOURS}h`)
    .required(REQUIRED_FIELD),
  hourlyPrice: number()
    .typeError("Une valeur est nécessaire")
    .min(ADMIN_MIN_HOURLY_PRICE, `minimum ${ADMIN_MIN_HOURLY_PRICE}€`)
    .max(MAX_HOURLY_PRICE, `maximum ${MAX_HOURLY_PRICE}€`)
    .required(REQUIRED_FIELD),
  commentary: string().max(240),
  fundingRequestId: string().max(255),
});

export const formatFormDataToBodyData = (convention: FormInputs): CreateConventionBody => {
  return {
    date: convention.date,
    studentId: convention.student,
    teacherId: convention.teacher,
    hours: convention.hours,
    hourlyPrice: convention.hourlyPrice,
    commentary: convention.commentary || null,
    fundingRequestId: convention.fundingRequestId || null,
  };
};
