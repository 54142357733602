import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { retrieveTeacher } from "../../../../user-management-context/read/application/use-cases/admin/admin-teacher-retrieval/retrieve-teacher";
import { resetUpdateTeacher } from "../../../../user-management-context/write/application/use-cases/admin/teacher-update/update-teacher";
import { AppState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";

const selectTeacherUpdate = (state: AppState) => state.teacherUpdate;

export const useTeacherUpdate = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedTeacherId, fetching } = useSelector(selectTeacherUpdate);

  useEffect(() => {
    if (justUpdatedTeacherId) {
      toast({ description: "Mise à jour effectuée avec succès!" });
      dispatch(resetUpdateTeacher());
      dispatch(retrieveTeacher(justUpdatedTeacherId));
    } else if (fetching === "failed") {
      toast({ description: "Erreur lors de la mise à jour", variant: "destructive" });
    }
  }, [justUpdatedTeacherId, fetching, dispatch, toast]);
};
