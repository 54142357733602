import { createReducer } from "@reduxjs/toolkit";
import { ConventionCreateState } from "@redux/app-state";
import { createConvention, resetCreateConvention } from "./create-convention";

const initialState: ConventionCreateState = { justCreatedConventionId: null, fetching: "idle" };

export const createConventionReducer = createReducer<ConventionCreateState>(initialState, builder => {
  builder.addCase(createConvention.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedConventionId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createConvention.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createConvention.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateConvention, () => {
    return initialState;
  });
});
