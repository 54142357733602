import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { toggleTrainingDunningsStatus } from "./toggle-training-dunnings-status";

const initialState: SimpleState = { processing: "idle" };

export const toggleTrainingDunningsStatusReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(toggleTrainingDunningsStatus.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(toggleTrainingDunningsStatus.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(toggleTrainingDunningsStatus.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
