import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const uploadSignedConvention = createAsyncThunk<string, { id: string; convention: File }, { extra: Partial<Dependencies> }>(
  "trainings/uploadSignedConvention",
  async ({ id, convention }: { id: string; convention: File }, { extra: { trainingGateway } }) => {
    return trainingGateway!.uploadSignedConvention(id, convention);
  }
);

export const resetUploadSignedConvention = createAction("trainings/resetUploadSignedConvention");
