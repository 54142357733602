import { isPossiblePhoneNumber } from "react-phone-number-input";
import { number, object, string } from "yup";
import { Teacher } from "../../../../read/domain/types/admin/teacher";
import { validatePassword } from "../../../../write/domain/services/password";
import { MAX_HOURLY_PRICE, TEACHER_MIN_HOURLY_PRICE } from "../../../../../academy-context/write/domain/constants/shared";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { isValid } from "iban";
import { CreateTeacherAccountBody } from "@user-management-context/write/domain/types/teacher/teacher";

export type FormInputs = {
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  defaultPrice: number;
  address: string;
  additionalAddress: string;
  password: string;
  siret: string;
  iban: string;
};

export const defaultValues = {
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  defaultPrice: TEACHER_MIN_HOURLY_PRICE,
  address: "",
  additionalAddress: "",
  password: "",
  siret: "",
  iban: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  email: string().email("Email incorrect").required(REQUIRED_FIELD),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  defaultPrice: number()
    .typeError(`Minimum ${TEACHER_MIN_HOURLY_PRICE}€`)
    .positive(`Minimum ${TEACHER_MIN_HOURLY_PRICE}€`)
    .min(TEACHER_MIN_HOURLY_PRICE, `Minimum ${TEACHER_MIN_HOURLY_PRICE}€`)
    .max(MAX_HOURLY_PRICE, `Maximum ${MAX_HOURLY_PRICE}€`)
    .required(REQUIRED_FIELD),
  address: string().max(255).required(REQUIRED_FIELD),
  additionalAddress: string().max(255).required(REQUIRED_FIELD),
  password: string()
    .required(REQUIRED_FIELD)
    .test("Mot de passe valide", "Le mot de passe n'est pas valide", (password?: string) => {
      return password ? validatePassword(password) : false;
    }),
  siret: string().max(14).required(REQUIRED_FIELD),
  iban: string()
    .max(255)
    .test("iban", "L'IBAN renseigné est invalide", value => {
      if (value) {
        return isValid(value);
      }
      // IBAN is empty which is valid since it can be null
      return true;
    }),
});

export const formatTeacherDataToFormData = (teacher: Teacher): FormInputs => {
  return {
    ...defaultValues,
    name: teacher.name ?? "",
    lastName: teacher.lastName ?? "",
    email: teacher.email,
    mobile: teacher.mobile ?? "",
    defaultPrice: teacher.defaultPrice,
    address: teacher.address ?? "",
    additionalAddress: teacher.additionalAddress ?? "",
    siret: teacher.siret ?? "",
    iban: teacher.iban ?? "",
  };
};

export const teacherFormatFormDataToBodyData = (teacher: FormInputs, userId: string, token: string): CreateTeacherAccountBody => {
  return {
    userId,
    token,
    name: teacher.name,
    lastName: teacher.lastName,
    address: teacher.address,
    additionalAddress: teacher.additionalAddress,
    mobile: teacher.mobile,
    defaultPrice: teacher.defaultPrice,
    password: teacher.password,
    siret: teacher.siret || null,
    iban: teacher.iban || null,
  };
};
