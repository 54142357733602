import { createReducer } from "@reduxjs/toolkit";
import { FundingRequestDeletionState } from "@redux/app-state";
import { deleteFundingRequest } from "./delete-funding-request";

const initialState: FundingRequestDeletionState = {
  fetching: "idle",
};

export const deleteFundingRequestReducer = createReducer<FundingRequestDeletionState>(initialState, builder => {
  builder.addCase(deleteFundingRequest.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(deleteFundingRequest.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(deleteFundingRequest.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
