const config = {
  local: {
    url: "http://localhost:3010",
  },
  dev: {
    url: "https://opera-off-dev.herokuapp.com",
  },
  production: {
    url: "https://opera-off.herokuapp.com",
  },
  test: {
    url: "test.com",
  },
  demo: {
    url: "http://localhost:3010",
  },
};

if (!import.meta.env["VITE_SERVICE_ENV"]) {
  throw new Error("VITE_SERVICE_ENV is undefined.");
}

type ENV = "local" | "production" | "test" | "dev" | "demo";
export default config[import.meta.env["VITE_SERVICE_ENV"] as ENV];
