import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "../../../../../../../redux/store";
import { ApprovedFundingRequestVM } from "../../../../../domain/types/admin/funding-request";

export const retrieveApprovedFundingRequests = createAsyncThunk<ApprovedFundingRequestVM[], void, { extra: Partial<Dependencies> }>(
  "fundingRequests/retrieveApprovedFundingRequests",
  async (_: void, { extra: { fundingRequestGateway } }) => {
    const requests = await fundingRequestGateway!.getAll(["approved"]);
    const response: ApprovedFundingRequestVM[] = [];
    for (const r of requests) {
      if (r.status === "approved") {
        response.push(r);
      }
    }
    return response;
  }
);
