import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminLessonListVM } from "../../../../../domain/types/admin/lesson";

export const adminRetrieveLessons = createAsyncThunk<AdminLessonListVM[], void, { extra: Partial<Dependencies> }>(
  "lessons/adminRetrieveLessons",
  async (_: void, { extra: { adminLessonGateway } }) => {
    return adminLessonGateway!.all();
  }
);
