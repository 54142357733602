import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentExpenseListVM } from "../../../../domain/types/student/expense";

export const studentRetrieveExpenses = createAsyncThunk<StudentExpenseListVM[], void, { extra: Partial<Dependencies> }>(
  "expenses/studentRetrieveExpenses",
  async (_: void, { extra: { studentExpenseGateway } }) => {
    return studentExpenseGateway!.all();
  }
);
