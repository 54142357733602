import { LessonVM } from "../../../../../domain/types/admin/payment";
import { AppState } from "@redux/app-state";

export interface LessonExtended extends LessonVM {
  localeDate: string;
  localeCreationDate: string;
}

export const selectPaymentLessonListRetrieval = (state: AppState) => {
  const {
    paymentLessonListRetrieval: { data, fetching },
  } = state;

  const lessonsListExtended: LessonExtended[] = data.map(l => ({
    ...l,
    localeDate: new Date(l.date).toLocaleDateString("fr-FR"),
    localeCreationDate: new Date(l.creationDate).toLocaleDateString("fr-FR"),
  }));

  return {
    data: lessonsListExtended,
    fetching,
  };
};
