import React from "react";
import InputError from "../../../../shared-kernel/primary/shared/input-error";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "./form-validation/afdas-funding-request";
import RichText from "../../../../shared-kernel/primary/shared/rich-text/rich-text";
import { CreatableSelect } from "../../../../shared-kernel/primary/shared/creatable-select/creatable-select";
import { PROJECT_OBJECTIVE } from "../../../read/domain/types/enums/funding-request-project-objectives";
import { Trash } from "lucide-react";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";

interface Props {
  index: number;
  onRemove: () => void;
}

const objectivesOptions: { label: PROJECT_OBJECTIVE; value: PROJECT_OBJECTIVE }[] = Object.values(PROJECT_OBJECTIVE).map(o => ({
  label: o,
  value: o,
}));

export const Objective = ({ index, onRemove }: Props) => {
  const {
    formState: { errors, isSubmitted },
    watch,
    setValue,
    resetField,
    control,
  } = useFormContext<FormInputs>();

  const personalizedEducationalProject = watch(`personalizedEducationalProject.${index}.description`);

  const handlePersonalizedEducationalProjectChange = (value: { html: string; text: string }) => {
    const isDirty =
      control._defaultValues.personalizedEducationalProject &&
      control._defaultValues.personalizedEducationalProject[index]?.description !== value.html;
    // We use resetField because it seems like shouldDirty doesn't work when setting it to false
    if (isDirty) {
      setValue(`personalizedEducationalProject.${index}.description`, value.html, { shouldDirty: isDirty, shouldValidate: isSubmitted });
      setValue(`personalizedEducationalProject.${index}.descriptionText`, value.text.trim(), {
        shouldDirty: isDirty,
        shouldValidate: isSubmitted,
      });
    } else {
      resetField(`personalizedEducationalProject.${index}.description`);
      resetField(`personalizedEducationalProject.${index}.descriptionText`);
    }
  };

  const handleResetPersonalizedEducationalProject = (value: string) => {
    resetField(`personalizedEducationalProject.${index}.description`, { defaultValue: value });
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <Label>{`Objectif ${index + 1}`}</Label>
        <Trash size={24} className="ml-2 cursor-pointer" fill="black" onClick={() => onRemove()} />
      </div>
      <div className="space-y-2">
        <CreatableSelect formName={`personalizedEducationalProject.${index}.title`} control={control} options={objectivesOptions} />
        <InputError error={errors.personalizedEducationalProject && errors.personalizedEducationalProject[index]?.title?.value?.message} />
      </div>
      <FormField
        control={control}
        name={`personalizedEducationalProject.${index}`}
        render={() => (
          <FormItem>
            <FormControl>
              <RichText
                value={personalizedEducationalProject}
                onChange={handlePersonalizedEducationalProjectChange}
                onReset={handleResetPersonalizedEducationalProject}
                hasError={Boolean(
                  (errors.coverLetter && errors.coverLetter.message) || (errors.coverLetterText && errors.coverLetterText.message)
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
