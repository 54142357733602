import React from "react";
import { useCallbackPrompt } from "./use-callback-prompt";
import { AlertModal } from "../modal/alert-modal";

interface Props {
  shouldBlockNavigation: boolean;
}

export const LostDataModal = ({ shouldBlockNavigation: isDirty }: Props) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);

  return (
    <AlertModal
      isOpen={showPrompt}
      onClose={() => cancelNavigation()}
      onSubmit={() => confirmNavigation()}
      title="Quitter sans sauvegarder?"
      body="Si vous quittez la page maintenant, les changements réalisés seront perdus"
      submitText="Quitter sans sauvegarder"
      cancelText="Continuer l'édition"
    />
  );
};
