import React from "react";
import { UserInvitation } from "../../../../read/application/use-cases/admin/user-invitations-retrieval/selectors/user-invitation-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale } from "../../../../../utils/formatting";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@shared-kernel/primary/shared/shadcn/ui/tabs";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";

const columns: ColumnDef<UserInvitation>[] = [
  {
    accessorKey: "updatedAt",
    header: () => "Date d'envoi",
    cell: ({ row }) => formatDateToLocale(row.original.updatedAt),
    enableSorting: true,
    meta: {
      title: "Date d'envoi",
    },
    size: 50,
  },
  {
    accessorKey: "name",
    header: () => "Prénom",
    enableSorting: true,
    meta: {
      title: "Prénom",
    },
    size: 80,
  },
  {
    accessorKey: "lastName",
    header: () => "Nom",
    enableSorting: true,
    meta: {
      title: "Nom",
    },
    size: 80,
  },
  {
    accessorKey: "email",
    header: () => "Email",
    enableSorting: true,
    meta: {
      title: "Email",
    },
  },
  {
    accessorKey: "role",
    header: () => "Rôle",
    enableSorting: true,
    meta: {
      title: "Rôle",
    },
    size: 40,
  },
];

interface Props {
  valid: UserInvitation[];
  expired: UserInvitation[];
}

export const InvitationsListComponent = ({ valid, expired }: Props) => {
  return (
    <Tabs defaultValue="current" className="mt-5">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="current">Invitations en cours</TabsTrigger>
        <TabsTrigger value="expired">Invitations expirées</TabsTrigger>
      </TabsList>
      <TabsContent value="current">
        <Card>
          <CardContent className="space-y-2">
            <DataTable columns={columns} data={valid} sortField="updatedAt" order="desc" searchPlaceHolder="Rechercher une invitation" />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="expired">
        <Card>
          <CardContent className="space-y-2">
            <DataTable columns={columns} data={expired} sortField="updatedAt" order="desc" searchPlaceHolder="Rechercher une invitation" />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
};
