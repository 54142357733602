import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateProviderProfile, updateProviderProfile } from "./update-provider-profile-";
import { SimpleState } from "@redux/app-state";

const initialState: SimpleState = { processing: "idle" };

export const updateProviderProfileReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(updateProviderProfile.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(updateProviderProfile.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateProviderProfile.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetUpdateProviderProfile, () => {
    return initialState;
  });
});
