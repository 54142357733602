import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UpdateProviderProfileBody } from "@user-management-context/write/domain/types/provider/provider";

export const updateProviderProfile = createAsyncThunk<void, UpdateProviderProfileBody, { extra: Partial<Dependencies> }>(
  "providers/updateProviderProfile",
  async (body: UpdateProviderProfileBody, { extra: { providerRepository } }) => {
    return providerRepository!.updateProfile(body);
  }
);

export const resetUpdateProviderProfile = createAction("providers/resetUpdateProviderProfile");
