import { createReducer } from "@reduxjs/toolkit";
import { TeacherCreateState } from "../../../../../../redux/app-state";
import { createTeacher, resetCreateTeacher } from "./create-teacher";

const initialState: TeacherCreateState = { justCreatedTeacherId: null, fetching: "idle" };

export const createTeacherReducer = createReducer<TeacherCreateState>(initialState, builder => {
  builder.addCase(createTeacher.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedTeacherId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createTeacher.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createTeacher.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateTeacher, () => {
    return initialState;
  });
});
