import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateTeacherUser, updateTeacherUser } from "./update-teacher";
import { TeacherUserUpdateState } from "@redux/app-state";

const initialState: TeacherUserUpdateState = { fetching: "idle" };

export const updateTeacherUserReducer = createReducer<TeacherUserUpdateState>(initialState, builder => {
  builder.addCase(updateTeacherUser.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(updateTeacherUser.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updateTeacherUser.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetUpdateTeacherUser, () => {
    return initialState;
  });
});
