import { createReducer } from "@reduxjs/toolkit";
import { StudentExpensesRetrievalState } from "@redux/app-state";
import { studentRetrieveExpenses } from "./retrieve-expenses";

const initialState: StudentExpensesRetrievalState = { fetching: "idle", data: [] };

export const studentRetrieveExpensesReducer = createReducer<StudentExpensesRetrievalState>(initialState, builder => {
  builder.addCase(studentRetrieveExpenses.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(studentRetrieveExpenses.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(studentRetrieveExpenses.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
