import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminExpenseListVM } from "../../../../domain/types/admin/expense";

export const retrieveExpenses = createAsyncThunk<AdminExpenseListVM[], void, { extra: Partial<Dependencies> }>(
  "expenses/retrieveExpenses",
  async (_: void, { extra: { adminExpenseGateway } }) => {
    return adminExpenseGateway!.all();
  }
);
