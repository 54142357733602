import { createReducer } from "@reduxjs/toolkit";
import { TeacherProfileRetrievalState } from "@redux/app-state";
import { retrieveTeacherProfile } from "./retrieve-teacher";

const initialState: TeacherProfileRetrievalState = { fetching: "idle", data: null };

export const retrieveTeacherProfileReducer = createReducer<TeacherProfileRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTeacherProfile.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTeacherProfile.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTeacherProfile.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
