import { HttpGateway } from "../../../../shared-kernel/secondary/http-gateway";
import { TokenStorageGateway } from "../../../../shared-kernel/application/ports/token-storage-gateway";
import config from "../../../../config/backend";
import { CollaborationRequestPort } from "@academy-context/todo/ports/collaboration-request-port";
import { CollaborationRequestBody } from "@academy-context/write/domain/types/teacher/collaboration-request";
import { CollaborationRequest } from "@academy-context/read/domain/types/admin/collaboration-request";

export class BackendCollaborationRequestGateway extends HttpGateway implements CollaborationRequestPort {
  private _route: string = "collaboration-requests/v1";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async request(collaboration: CollaborationRequestBody): Promise<void> {
    await this._instance.post(this._route, collaboration);
  }
  async list(): Promise<CollaborationRequest[]> {
    const { data } = await this._instance.get(this._route);
    return data;
  }

  async approve({ id, status }: { id: string; status: "approved" | "rejected" }): Promise<void> {
    await this._instance.put(`${this._route}/${id}`, { status });
  }
}
