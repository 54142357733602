import { createReducer } from "@reduxjs/toolkit";
import { SimpleState } from "@redux/app-state";
import { inviteStudentToInternship, resetInviteStudentToInternship } from "./invite-student-to-internship";

const initialState: SimpleState = { processing: "idle" };

export const inviteStudentToInternshipReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(inviteStudentToInternship.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(inviteStudentToInternship.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(inviteStudentToInternship.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetInviteStudentToInternship, () => {
    return initialState;
  });
});
