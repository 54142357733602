import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import React, { ReactNode } from "react";

interface Props {
  title: string;
  body: ReactNode;
  onSubmit: () => void;
  onClose: () => void;
  isOpen: boolean;
  submitText: string;
  submitDisabled: boolean;
}

export const Modal = ({ title, body, submitText, isOpen, submitDisabled, onClose, onSubmit }: Props) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent className="sm:max-w-[425px]" onPointerDownOutside={onClose}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {body}
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Annuler
          </Button>
          <Button type="submit" onClick={onSubmit} disabled={submitDisabled}>
            {submitText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
