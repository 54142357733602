import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { retrieveProviderStudentByEmail, resetRetrieveProviderStudentByEmail } from "./retrieve-student-by-email";
import { ProviderStudent } from "@user-management-context/read/domain/types/provider/student";
import { Nullable } from "@shared-kernel/core/types/nullable";

const initialState: RetrievalState<Nullable<ProviderStudent>> = { fetching: "idle", data: null };

export const retrieveProviderStudentByEmailReducer = createReducer<RetrievalState<Nullable<ProviderStudent>>>(initialState, builder => {
  builder.addCase(retrieveProviderStudentByEmail.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveProviderStudentByEmail.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveProviderStudentByEmail.rejected, state => {
    return {
      ...state,
      data: null,
      fetching: "failed",
    };
  });
  builder.addCase(resetRetrieveProviderStudentByEmail, () => {
    return initialState;
  });
});
