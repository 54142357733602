import React from "react";
import { AdminTrainingElementStatus } from "../../../read/domain/types/training";
import { Link } from "react-router-dom";
import { TrainingElement } from "./element";

interface Props {
  title: string;
  status: AdminTrainingElementStatus;
  subTitle?: string;
  link?: string;
}

export const LinkTrainingElement = ({ title, status, subTitle, link }: Props) => {
  const content = <TrainingElement title={title} status={status} subTitle={subTitle} />;

  if (!link) return content;
  return <Link to={link}>{content}</Link>;
};
