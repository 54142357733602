import { createReducer } from "@reduxjs/toolkit";
import { resetUpdateStudentProfile, updateStudentProfile } from "./update-student-profile";
import { StudentProfileUpdateState } from "@redux/app-state";

const initialState: StudentProfileUpdateState = { fetching: "idle" };

export const updateStudentProfileReducer = createReducer<StudentProfileUpdateState>(initialState, builder => {
  builder.addCase(updateStudentProfile.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(updateStudentProfile.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(updateStudentProfile.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetUpdateStudentProfile, () => {
    return initialState;
  });
});
