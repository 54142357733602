import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentConventionListVM } from "../../../../domain/types/student/convention";

export const retrieveConventions = createAsyncThunk<StudentConventionListVM[], void, { extra: Partial<Dependencies> }>(
  "conventions/retrieveStudentConventions",
  async (_: void, { extra: { studentConventionGateway } }) => {
    return studentConventionGateway!.all();
  }
);
