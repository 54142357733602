import { useEffect } from "react";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { retrieveProviderTrainings } from "@academy-context/read/application/use-cases/provider/provider-trainings-retrieval/retrieve-provider-trainings";
import { resetCreateProviderTraining } from "@academy-context/write/application/use-cases/provider/provider-training-creation/create-provider-training";

export const selectProviderTrainingCreated = (state: AppState) => state.providerTrainingCreation;

export const useProviderTrainingCreated = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { processing } = useAppSelector(selectProviderTrainingCreated);

  useEffect(() => {
    if (processing === "success") {
      toast({ description: "Formation ajoutée !" });
      dispatch(resetCreateProviderTraining());
      dispatch(retrieveProviderTrainings());
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'ajout de la formation", variant: "destructive" });
    }
  }, [dispatch, processing, toast]);
};
