import { createReducer } from "@reduxjs/toolkit";
import { ToReviewFundingRequestsRetrievalState } from "../../../../../../../redux/app-state";
import { retrieveToReviewFundingRequests } from "./retrieve-to-review-funding-requests";

const initialState: ToReviewFundingRequestsRetrievalState = {
  fetching: "idle",
  data: [],
};

export const retrieveToReviewFundingRequestsReducer = createReducer<ToReviewFundingRequestsRetrievalState>(initialState, builder => {
  builder.addCase(retrieveToReviewFundingRequests.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveToReviewFundingRequests.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveToReviewFundingRequests.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
