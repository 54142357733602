import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { StudentBudgetListVM } from "../../../../domain/types/student/budget";

export const retrieveBudgets = createAsyncThunk<StudentBudgetListVM[], void, { extra: Partial<Dependencies> }>(
  "budgets/retrieveStudentBudgets",
  async (_: void, { extra: { studentBudgetGateway } }) => {
    return studentBudgetGateway!.all();
  }
);
