import { AnySchema, array, boolean, object, string } from "yup";
import { UpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { Student } from "@user-management-context/read/domain/types/admin/student";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export type Validation = "student" | "admin";

export type AdminStudentFormInputs = {
  name: string;
  lastName: string;
  email: string;
  mobile: string;
  favoriteTeachers: string[];
  blackListed: boolean;
  profilePictureUrl: string;
  canRequestARefundOnce: boolean;
};

export const defaultValues = {
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  favoriteTeachers: [],
  blackListed: false,
  profilePictureUrl: "",
  canRequestARefundOnce: false,
};

const requiredWhenAdminValidation = {
  is: (value: Validation) => {
    return value === "admin";
  },
  then: (schema: AnySchema) => schema.required(REQUIRED_FIELD),
  otherwise: (schema: AnySchema) => schema.nullable(),
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  lastName: string().max(255).required(REQUIRED_FIELD),
  email: string().email("Email incorrect").when("$validation", requiredWhenAdminValidation),
  mobile: string().test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  favoriteTeachers: array().of(string()).when("$validation", requiredWhenAdminValidation),
  blackListed: boolean().when("$validation", requiredWhenAdminValidation),
  canRequestARefundOnce: boolean().when("$validation", requiredWhenAdminValidation),
  profilePictureUrl: string().url(),
});

export const formatStudentDataToFormData = (student: Student): AdminStudentFormInputs => {
  return {
    ...defaultValues,
    name: student.name,
    lastName: student.lastName,
    email: student.email,
    mobile: student.mobile,
    favoriteTeachers: student.favoriteTeachers,
    blackListed: student.blackListed,
    canRequestARefundOnce: student.canRequestARefundOnce,
    profilePictureUrl: student.profilePictureUrl ?? "",
  };
};

export const adminFormatFormDataToBodyData = (student: AdminStudentFormInputs): UpdateStudentBody => {
  return {
    name: student.name,
    lastName: student.lastName,
    email: student.email.toLowerCase().trim(),
    mobile: student.mobile,
    favoriteTeachers: student.favoriteTeachers,
    blackListed: student.blackListed,
    canRequestARefundOnce: student.canRequestARefundOnce,
    profilePictureUrl: student.profilePictureUrl !== "" ? student.profilePictureUrl : null,
  };
};
