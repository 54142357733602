import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale } from "../../../../utils/formatting";
import { AppState } from "@redux/app-state";
import { Link } from "react-router-dom";
import { TeacherTrainingListVM } from "../../../read/domain/types/training";
import { teacherRetrieveTrainings } from "../../../read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings";
import { DangerSign } from "../../../../shared-kernel/primary/shared/danger-sign/danger-sign";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";

const linkWrapper = (id: string, value: string) => (
  <Link to={`/trainings/${id}`}>
    <span>{value}</span>
  </Link>
);

const columns: ColumnDef<TeacherTrainingListVM>[] = [
  {
    accessorKey: "isAvailableAction",
    header: () => "Action requise",
    cell: ({ row }) =>
      row.original.isAvailableAction ? (
        <div className="flex justify-center">
          <DangerSign />
        </div>
      ) : null,
    size: 40,
    meta: {
      title: "Action requise",
    },
  },
  {
    accessorKey: "conventionDate",
    header: () => "Date",
    cell: ({ row }) => linkWrapper(row.original.id, formatDateToLocale(row.original.conventionDate)),
    size: 40,
    meta: {
      title: "Date",
    },
    enableSorting: true,
  },
  {
    accessorKey: "title",
    header: () => "Nom de la formation",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.title),
    meta: {
      title: "Votre formation",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    cell: ({ row }) => linkWrapper(row.original.id, row.original.student),
    size: 40,
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
];

export const TeacherTrainingList = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: AppState) => state.teacherTrainingsRetrieval);

  useEffect(() => {
    dispatch(teacherRetrieveTrainings());
  }, [dispatch]);

  return (
    <CustomCard title="Suivi qualité de votre formation">
      <div className="mb-4 w-full">
        <p>
          Vous trouverez ci-dessous l’ensemble des formations de vos élèves.
          <br />
          En cliquant sur le nom de la formation, vous pourrez émarger vos feuilles de présences.
        </p>
        <div className="flex">
          <DangerSign /> <span className="ml-2">: Lorsque ce logo apparait, une action de votre part est requise.</span>
        </div>
      </div>
      <Separator className="bg-gray-400" />
      <DataTable columns={columns} data={data} sortField="conventionDate" order="desc" searchPlaceHolder="Rechercher une formation" />
    </CustomCard>
  );
};
