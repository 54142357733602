import { createReducer } from "@reduxjs/toolkit";
import { ConventionDeleteState } from "@redux/app-state";
import { deleteSignedConvention } from "./delete-signed-convention";

const initialState: ConventionDeleteState = { processing: "idle" };

export const deleteSignedConventionReducer = createReducer<ConventionDeleteState>(initialState, builder => {
  builder.addCase(deleteSignedConvention.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(deleteSignedConvention.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(deleteSignedConvention.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
