import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues } from "./form-validation/user-reset-password";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { ArrowBigLeft } from "lucide-react";

interface Props {
  onSubmit: (formBody: any) => void;
  onBack: () => void;
}

const ResetForm = ({ onSubmit, onBack }: Props) => {
  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    formState: { isDirty },
  } = methods;

  return (
    <Form {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full">
        <div className="space-y-2">
          <FormField
            control={methods.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} className="text-black" type="email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center justify-between gap-x-2">
            <div onClick={onBack} role="button" className="flex justify-between items-center hover:font-semibold">
              <ArrowBigLeft />
              <span>Retour</span>
            </div>
            <Button disabled={!isDirty} type="submit">
              Soumettre
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default ResetForm;
