import { Badge } from "@components/ui/badge";
import { ScrollArea } from "@components/ui/scroll-area";
import { cn } from "@components/utils/utils";
import { Nullable } from "@shared-kernel/core/types/nullable";
import React, { memo } from "react";
import DOMPurify from "dompurify";

interface Item {
  chips: string[];
  title: string;
  id: string;
  preview: string;
}

export interface ItemListProps {
  items: Item[];
  setSelectedItemId: (id: string) => void;
  selectedItemId: Nullable<string>;
}

// We don't pass the id to avoid a render when item is created (ID is generated on backend side)
const ItemComponent = memo(function ItemComponent({ chips, title, preview }: Omit<Item, "id">) {
  const sanitizedPreview = DOMPurify.sanitize(preview);

  return (
    <>
      <div className="flex w-full flex-col gap-1">
        <div className="font-semibold line-clamp-2 w-full">{title}</div>
      </div>
      <div className="line-clamp-3 text-xs text-muted-foreground" dangerouslySetInnerHTML={{ __html: sanitizedPreview }} />
      {chips.length ? (
        <div className="flex items-center gap-2">
          {chips.map(label => (
            <Badge key={label} variant="default">
              {label}
            </Badge>
          ))}
        </div>
      ) : null}
    </>
  );
});

export const ItemList = ({ items, setSelectedItemId, selectedItemId }: ItemListProps) => (
  <ScrollArea className="h-screen w-full">
    <div className="flex flex-col justify-center gap-2 p-4 pt-0">
      {items.map(item => (
        <button
          key={item.id}
          className={cn(
            "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
            selectedItemId === item.id && "bg-muted"
          )}
          onClick={() => setSelectedItemId(item.id)}
        >
          <ItemComponent key={item.id} chips={item.chips} title={item.title} preview={item.preview} />
        </button>
      ))}
    </div>
  </ScrollArea>
);
