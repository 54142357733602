import { createReducer } from "@reduxjs/toolkit";
import { LastLessonsRetrievalState } from "@redux/app-state";
import { adminRetrieveLastLessons } from "./retrieve-last-lessons";

const initialState: LastLessonsRetrievalState = { fetching: "idle", data: [] };

export const adminRetrieveLastLessonsReducer = createReducer<LastLessonsRetrievalState>(initialState, builder => {
  builder.addCase(adminRetrieveLastLessons.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveLastLessons.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveLastLessons.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
