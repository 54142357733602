import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateProviderAccountBody } from "@user-management-context/write/domain/types/provider/provider";

export const createProviderAccount = createAsyncThunk<void, CreateProviderAccountBody, { extra: Partial<Dependencies> }>(
  "users/createProviderAccount",
  async (body: CreateProviderAccountBody, { extra: { userGateway } }) => {
    return userGateway!.createProviderAccount(body);
  }
);
