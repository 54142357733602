import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { UserInvitation } from "@user-management-context/read/domain/types/admin/user-invitation";

export const retrieveUserInvitations = createAsyncThunk<UserInvitation[], void, { extra: Partial<Dependencies> }>(
  "users/retrieveUserInvitations",
  async (_: void, { extra: { adminUserInvitationGateway } }) => {
    return adminUserInvitationGateway!.all();
  }
);
