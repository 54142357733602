import { Student } from "../../../../../domain/types/student/student";
import { AppState } from "@redux/app-state";

export const selectStudentProfileRetrieval = (state: AppState) => {
  const { data, fetching } = state.studentProfileRetrieval;

  let student: Student = {} as Student;
  if (fetching === "success") {
    student = data!;
  }

  return student;
};
