import React from "react";
import { DangerSign } from "../../../../shared-kernel/primary/shared/danger-sign/danger-sign";
import { Nullable } from "../../../../shared-kernel/core/types/nullable";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormInputs, schema, defaultValues, formatFormDataToBodyData } from "./form-validation/objective";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { updateTrainingObjective } from "../../../write/application/use-cases/shared/training-objective-update/update-training-objective";
import { selectObjectiveUpdate } from "../training/use-objective-update";
import { CustomCard } from "../../../../shared-kernel/primary/shared/custom-card/custom-card";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Save } from "lucide-react";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Textarea } from "@shared-kernel/primary/shared/shadcn/ui/textarea";

interface Props {
  title: string;
  trainingId: string;
  objectiveId: string;
  description: string;
  done: Nullable<string>;
  role: "student" | "teacher";
}

export const ObjectiveCard = ({ title, description, done, trainingId, objectiveId, role }: Props) => {
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const dispatch = useAppDispatch();
  const { processing } = useAppSelector(selectObjectiveUpdate);

  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    await dispatch(updateTrainingObjective({ ...body, id: objectiveId, trainingId, role }));
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <CustomCard title={title}>
          <div className="grid grid-cols-2 gap-5 w-full">
            <div className="flex flex-col items-center">
              <h4 className="font-semibold mb-2">Prévu</h4>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            <div>
              <div className="flex justify-center">
                {!Boolean(done) && <DangerSign className="mr-3" />}
                <h4 className="font-semibold mb-2">Réalisé</h4>
              </div>
              {done ? (
                <p>{done}</p>
              ) : (
                <FormField
                  control={form.control}
                  name="done"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>
          <div className="w-full flex justify-end mr-4 mt-3">
            {!Boolean(done) && (
              <Button type="submit" disabled={!isDirty || processing === "pending"}>
                <Save className="mr-2" />
                Sauvegarder
              </Button>
            )}
          </div>
        </CustomCard>
      </form>
    </Form>
  );
};
