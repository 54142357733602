import { AdminProviderStudentInvitation } from "@user-management-context/read/domain/types/admin/provider-student-invitation";
import { PROVIDER_STUDENT_INVITATION_STATUS } from "@user-management-context/read/domain/types/provider/invitation";
import { invitationId } from "src/test/utils/common";

const defaultInvitation: AdminProviderStudentInvitation = {
  id: invitationId,
  creationDate: "2022-10-01T03:00:00Z",
  student: {
    name: "Marc",
    lastName: "Siebert",
    email: "marc@gmail.com",
    mobile: "0614366623",
    isAlreadyExisting: false,
  },
  providerTag: "LALA",
  training: "Le chant extrême",
  lastInternship: {
    interval: {
      start: "2022-10-02T03:00:00Z",
      end: "2022-10-04T03:00:00Z",
    },
  },
  status: PROVIDER_STUDENT_INVITATION_STATUS.SUBMITTED_TO_ADMIN,
};

export const AdminProviderStudentInvitationBuilder = (data: AdminProviderStudentInvitation = defaultInvitation) => {
  return {
    withId: (id: AdminProviderStudentInvitation["id"]) => AdminProviderStudentInvitationBuilder({ ...data, id }),
    withCreationDate: (creationDate: AdminProviderStudentInvitation["creationDate"]) =>
      AdminProviderStudentInvitationBuilder({ ...data, creationDate }),
    withStudent: (student: AdminProviderStudentInvitation["student"]) => AdminProviderStudentInvitationBuilder({ ...data, student }),
    build: (): AdminProviderStudentInvitation => data,
  };
};
