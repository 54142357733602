import config from "../../../../config/backend";
import { AdminStudentGateway } from "../../ports/admin-student-gateway";
import { TokenStorageGateway } from "../../../../shared-kernel/application/ports/token-storage-gateway";
import { CreateStudentBody, UpdateStudentBody } from "@user-management-context/write/domain/types/admin/student";
import { Student, StudentListElement } from "@user-management-context/read/domain/types/admin/student";
import { StudentSummaryVM } from "../../../read/domain/types/student/student";
import { HttpGateway } from "../../../../shared-kernel/secondary/http-gateway";

export class AdminBackendStudentGateway extends HttpGateway implements AdminStudentGateway {
  private _route: string = "admin/v1/students";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async one(id: string): Promise<Student> {
    const { data: student } = await this._instance.get(`${this._route}/${id}`);
    return student;
  }

  async all(): Promise<StudentListElement[]> {
    const { data: students } = await this._instance.get(this._route);
    return students;
  }

  async create(student: CreateStudentBody): Promise<string> {
    const { data: studentId } = await this._instance.post(this._route, student);
    return studentId;
  }

  async update(id: string, body: UpdateStudentBody): Promise<void> {
    await this._instance.put(`${this._route}/${id}`, body);
  }

  async summary(id: string): Promise<StudentSummaryVM> {
    const { data: summary } = await this._instance.get(`${this._route}/${id}/summary`);
    return summary;
  }
}
