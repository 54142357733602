import { createReducer } from "@reduxjs/toolkit";
import { SubmittedToFunderFundingRequestsRetrievalState } from "../../../../../../../redux/app-state";
import { retrieveSubmittedToFunderFundingRequests } from "./retrieve-submitted-to-afdas-funding-requests";

const initialState: SubmittedToFunderFundingRequestsRetrievalState = {
  fetching: "idle",
  data: [],
};

export const retrieveSubmittedToFunderFundingRequestsReducer = createReducer<SubmittedToFunderFundingRequestsRetrievalState>(
  initialState,
  builder => {
    builder.addCase(retrieveSubmittedToFunderFundingRequests.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        fetching: "success",
      };
    });
    builder.addCase(retrieveSubmittedToFunderFundingRequests.pending, state => {
      return {
        ...state,
        fetching: "pending",
      };
    });
    builder.addCase(retrieveSubmittedToFunderFundingRequests.rejected, state => {
      return {
        ...state,
        fetching: "failed",
      };
    });
  }
);
