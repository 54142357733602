import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { BudgetCreate } from "./budget-create.component";
import { createBudget } from "../../../write/application/use-cases/admin/admin-budget-creation/create-budget";
import { selectBudgetsRetrievalForSummmaryList } from "../../../read/application/use-cases/admin/admin-last-budgets-retrieval/selectors/budgets-list-summary-selectors";
import { BudgetListSummary } from "./budget-list-summary.components";
import { retrieveLastBudgets } from "../../../read/application/use-cases/admin/admin-last-budgets-retrieval/retrieve-last-budgets";
import { retrieveStudents } from "../../../../user-management-context/read/application/use-cases/admin/students-retrieval/retrieve-students";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-afdas/retrieve-submitted-to-afdas-funding-requests";

export const BudgetAdminDetail = () => {
  const dispatch = useAppDispatch();
  const { data: budgets } = useAppSelector(selectBudgetsRetrievalForSummmaryList);

  useEffect(() => {
    dispatch(retrieveStudents());
    dispatch(retrieveSubmittedToFunderFundingRequests());
    dispatch(retrieveLastBudgets());
  }, [dispatch]);

  const saveBudget = async (budget: CreateBudgetBody) => {
    await dispatch(createBudget(budget));
  };

  return (
    <div className="space-y-5">
      <BudgetCreate onSubmit={saveBudget} />
      <BudgetListSummary budgets={budgets} />
    </div>
  );
};
