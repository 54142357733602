import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Nullable } from "../../../../shared-kernel/core/types/nullable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@components/ui/button";
import { PROVIDER_INTERNSHIP_STATUS, ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";
import { AlertTriangle, CalendarCheck, Check, CheckCheck, FileCheck, FileClock, FileText, MousePointerClickIcon, X } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "@components/ui/avatar";
import { useModal } from "@hooks/useModal";
import { ConfirmInternshipModal } from "@academy-context/primary/shared/provider-internship/confirm-internship-modal";
import { AttachInvoiceBody, ConfirmInternshipBody } from "@user-management-context/shared/application/ports/admin-provider-repository";

const statusMap: Record<PROVIDER_INTERNSHIP_STATUS, ReactElement> = {
  [PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_SUBMITTED_BY_STUDENT]: (
    <div className="flex items-center justify-center">
      <FileText size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Dossier finalisé par l’élève</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_SUBMITTED_TO_FUNDER]: (
    <div className="flex items-center justify-center">
      <FileClock size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Dossier envoyé, attente de réponse du financeur</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER]: (
    <div className="flex items-center justify-center px-1">
      <FileCheck color="green" size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Financement accordé, attente confirmation presta</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CONFIRMED]: (
    <div className="flex items-center justify-center">
      <CalendarCheck size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Stage confirmé</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CANCELED_BY_ADMIN]: (
    <div className="flex items-center justify-center">
      <X color="red" size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Stage annulé</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CANCELED_BY_PROVIDER]: (
    <div className="flex items-center justify-center">
      <CalendarCheck size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Stage annulé par le prestataire</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_FINISHED]: (
    <div className="flex items-center justify-center">
      <AlertTriangle color="orange" size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Stage confirmé, en attente facture</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.INVOICE_RECEIVED]: (
    <div className="flex items-center justify-center">
      <Check size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Facture reçue, attente paiement OO à PRESTA</span>
      </div>
    </div>
  ),
  [PROVIDER_INTERNSHIP_STATUS.INVOICE_PAID]: (
    <div className="flex items-center justify-center">
      <CheckCheck color="green" size="40" className="ml-2" />
      <div className="flex-1 ml-2">
        <span>Facture payée</span>
      </div>
    </div>
  ),
};

const statusFormatter = (internship: ProviderInternship, status: PROVIDER_INTERNSHIP_STATUS) => {
  return statusMap[status];
};

const actionFormatter = (
  { status }: ProviderInternship,
  onClick: () => void,
  { openConfirmInternshipModal, openAttachInvoiceModal }: { openConfirmInternshipModal: () => void; openAttachInvoiceModal: () => void }
) => {
  // TODO: SHould not be accessible to provider
  // if (status === PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_SUBMITTED_TO_FUNDER)
  //   return (
  //     <Button onClick={onClick}>
  //       <Wand /> <span className="ml-2">Financement accordé</span>
  //     </Button>
  //   );
  if (status === PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER)
    return (
      <Button
        className="bg-green-500"
        onClick={() => {
          openConfirmInternshipModal();
          onClick();
        }}
      >
        <MousePointerClickIcon /> <span className="ml-2">Confirmer stage</span>
      </Button>
    );
  // if (status === PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CONFIRMED)
  //   return (
  //     <Button variant="outline" onClick={onClick}>
  //       <X color="red" /> <span className="ml-2">Annuler stage</span>
  //     </Button>
  //   );
  // if (status === PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CANCELED_BY_PROVIDER)
  //   return (
  //     <Button variant="outline" onClick={onClick}>
  //       <X color="red" /> <span className="ml-2">Annuler stage</span>
  //     </Button>
  //   );
  if (status === PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_FINISHED)
    return (
      <div className="flex flex-col items-center">
        <Button variant="outline" onClick={onClick}>
          <X color="red" /> <span className="ml-2">Annuler stage</span>
        </Button>
        <Button
          className="bg-orange-400 mt-2"
          onClick={() => {
            openAttachInvoiceModal();
            onClick();
          }}
        >
          <FileText /> <span className="ml-2">Joindre facture</span>
        </Button>
      </div>
    );
  return <></>;
};

const columns: ColumnDef<ProviderInternship>[] = [
  {
    accessorKey: "creationDate",
    header: () => "",
    cell: info => {
      const initials = `${info.row.original.student.name[0]?.toUpperCase()}${info.row.original.student.lastName[0]?.toUpperCase()}`;
      return (
        <div className="flex items-center justify-center p-2">
          <Avatar>
            <AvatarImage src={info.row.original.student.profilePictureUrl ?? undefined} />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
        </div>
      );
    },
    size: 60,
    meta: {
      title: "",
    },
  },
  {
    accessorKey: "student.name",
    header: () => "Nom",
    cell: info => `${info.row.original.student.name} ${info.row.original.student.lastName}`,
    enableSorting: true,
    size: 70,
    meta: {
      title: "Nom",
    },
  },
  {
    accessorKey: "provider.tag",
    header: () => "Tag",
    enableSorting: true,
    size: 60,
    meta: {
      title: "Tag",
    },
  },
  {
    accessorKey: "details.training",
    header: () => "Formation",
    enableSorting: true,
    size: 60,
    meta: {
      title: "Formation",
    },
  },
  {
    accessorKey: "status",
    header: () => "Statut",
    cell: info => statusFormatter(info.row.original, info.row.original.status),
    enableSorting: true,
    meta: {
      title: "Statut",
    },
  },
];

interface Props {
  providerInternships: ProviderInternship[];
  setSelectedProviderId: (provider: string) => void;
  providerTrainings: { id: string; title: string }[];
  onConfirmInternship: (body: ConfirmInternshipBody) => void;
  onUpdateInternship: (body: ConfirmInternshipBody) => void;
  onAttachInvoice: (body: AttachInvoiceBody) => void;
}

export const ProviderInternshipListComponent = ({
  providerInternships,
  setSelectedProviderId,
  providerTrainings,
  onConfirmInternship,
  onUpdateInternship,
  onAttachInvoice,
}: Props) => {
  const {
    closeModal: closeConfirmInternshipModal,
    isModalOpen: isConfirmInternshipModalOpen,
    openModal: openConfirmInternshipModal,
  } = useModal();
  const { openModal: openAttachInvoiceModal } = useModal();
  // const { closeModal: closeAttachInvoiceModal, isModalOpen: isAttachInvoiceModalOpen, openModal: openAttachInvoiceModal } = useModal();
  const [dataColumns, setDataColumns] = useState<ColumnDef<ProviderInternship>[]>([...columns]);
  const currentSelectedLineInfo = useRef<Nullable<ProviderInternship>>(null);

  useEffect(() => {
    const actionsColumn: ColumnDef<ProviderInternship> = {
      accessorKey: "id",
      header: () => "Actions",
      size: 40,
      meta: {
        title: "Actions",
      },
      cell: ({ row }) => {
        const onClick = () => {
          currentSelectedLineInfo.current = row.original;
        };
        return actionFormatter(row.original, onClick, { openConfirmInternshipModal, openAttachInvoiceModal });
      },
    };

    setDataColumns([...columns, actionsColumn]);
  }, [openAttachInvoiceModal, openConfirmInternshipModal, providerInternships]);

  useEffect(() => {
    if (currentSelectedLineInfo.current?.provider.id) setSelectedProviderId(currentSelectedLineInfo.current.provider.id);
  }, [setSelectedProviderId, currentSelectedLineInfo.current?.provider.id]);

  return (
    <>
      {currentSelectedLineInfo.current && (
        <>
          <ConfirmInternshipModal
            isOpen={isConfirmInternshipModalOpen}
            internship={currentSelectedLineInfo.current}
            onClose={closeConfirmInternshipModal}
            providerTrainings={providerTrainings}
            onConfirmInternship={onConfirmInternship}
            onUpdateInternship={onUpdateInternship}
          />
          {/* TODO */}
          {/* <AttachInvoiceModal
            isOpen={isAttachInvoiceModalOpen}
            providerFile="todo"
            internship={currentSelectedLineInfo.current}
            onClose={closeAttachInvoiceModal}
            onAttachInvoice={onAttachInvoice}
          /> */}
        </>
      )}
      <DataTable
        columns={dataColumns}
        data={providerInternships}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher un dossier"
        pageSize={50}
      />
    </>
  );
};
