import { createReducer } from "@reduxjs/toolkit";
import { TrainingObjectiveUpdateState } from "@redux/app-state";
import { updateTrainingObjective } from "./update-training-objective";

const initialState: TrainingObjectiveUpdateState = { processing: "idle" };

export const updateTrainingObjectiveReducer = createReducer<TrainingObjectiveUpdateState>(initialState, builder => {
  builder.addCase(updateTrainingObjective.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(updateTrainingObjective.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(updateTrainingObjective.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
});
