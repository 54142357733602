import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { SideBar } from "./shared-kernel/primary/layout/side-bar/side-bar";
import { AppState } from "@redux/app-state";
import { adminRoutes, AppRoute, providerRoutes, studentRoutes, teacherRoutes } from "./routes";
import PrivateOutlet from "./shared-kernel/primary/shared/private-outlet";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { logout } from "./user-management-context/write/application/use-cases/user-login/login-user";
import LoginPage from "./user-management-context/primary/user/login/login-page";
import { AdminAccountCreation } from "./academy-context/primary/admin/account-creation/admin-account-creation.components";
import LoginOutlet from "./shared-kernel/primary/shared/login-outlet";
import { PasswordUpdatePage } from "./user-management-context/primary/user/password-update/password-update.components";
import { StudentAccountCreation } from "./user-management-context/primary/student/student-account-creation/student-account-creation.components";
import { TeacherAccountCreation } from "./user-management-context/primary/teacher/teacher-account-creation/teacher-account-creation.components";
import "./input.css";
import { Toaster } from "@shared-kernel/primary/shared/shadcn/ui/toaster";
import { resetRetrieveUserProfile } from "./user-management-context/read/application/use-cases/user-profile/retrieve-user-profile";
import { resetStudentRetrieveTrainings } from "./academy-context/read/application/use-cases/student/trainings-retrieval/retrieve-trainings";
import { resetTeacherRetrieveTrainings } from "./academy-context/read/application/use-cases/teacher/trainings-retrieval/retrieve-trainings";
import { resetRetrieveFundingRequestEligibility } from "./academy-context/read/application/use-cases/student/funding-request-eligibility-retrieval/retrieve-funding-request-eligibility";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { ProviderAccountCreationContainer } from "@user-management-context/primary/provider/provider-account-creation/provider-account-creation.container";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RedirectAfterLogin = () => {
  const { pathname } = useLocation();

  return <Navigate to="/login" replace state={{ from: pathname }} />;
};

function App() {
  const { data: user } = useAppSelector((state: AppState) => state.userLogin);
  const dispatch = useAppDispatch();

  const isStudent = user && user.role === ROLES.STUDENT;
  const isTeacher = user && user.role === ROLES.TEACHER;
  const isAdmin = user && user.role === ROLES.ADMIN;
  const isProvider = user && user.role === ROLES.PROVIDER;
  const isAuthenticated = Object.values(ROLES).includes(user?.role as ROLES);

  let routes: AppRoute[] = [];
  let defaultRoute = "dashboard";
  if (isAdmin) {
    routes = adminRoutes;
    defaultRoute = "transfers";
  } else if (isStudent) routes = studentRoutes;
  else if (isTeacher) routes = teacherRoutes;
  else if (isProvider) {
    routes = providerRoutes;
  }

  const onLogout = () => {
    dispatch(resetRetrieveUserProfile());
    dispatch(resetStudentRetrieveTrainings());
    dispatch(resetTeacherRetrieveTrainings());
    dispatch(resetRetrieveFundingRequestEligibility());

    dispatch(logout(null));
  };

  return (
    // <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
    <Router>
      <ScrollToTop />
      <div className="app-container">
        <Routes>
          <Route element={<LoginOutlet />}>
            <Route path="/login" element={<LoginPage />} />
            {!isAuthenticated && (
              <>
                <Route path="*" element={<RedirectAfterLogin />} />
                <Route path="password/reset" element={<PasswordUpdatePage />} />
                <Route path="invite">
                  <Route path="admin" element={<AdminAccountCreation />} />
                  <Route path="student" element={<StudentAccountCreation />} />
                  <Route path="teacher" element={<TeacherAccountCreation />} />
                  <Route path="provider" element={<ProviderAccountCreationContainer />} />
                </Route>
              </>
            )}
          </Route>
        </Routes>
        {isAuthenticated && (
          <div className="flex">
            <SideBar routes={routes} logout={onLogout} />
            <div className="w-[75%] m-auto my-10">
              <Routes>
                <Route path="/" element={<PrivateOutlet />}>
                  {routes.map(r => (
                    <Route key={r.path} path={r.path} element={r.element} />
                  ))}
                  <Route path="/" element={<Navigate replace to={defaultRoute} />} />
                  <Route path="*" element={<div>404</div>} />
                </Route>
              </Routes>
            </div>
          </div>
        )}
      </div>
      <Toaster />
    </Router>
    // </ThemeProvider>
  );
}

export default App;
