import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FormInputs, formatFormDataToBodyData } from "./form-validation/user-login";
import {
  FormInputs as ResetPasswordFormInputs,
  formatFormDataToBodyData as ResetPasswordFormatFormDataToBodyData,
} from "./form-validation/user-reset-password";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { loginUser } from "../../../write/application/use-cases/user-login/login-user";
import { AppState } from "@redux/app-state";
import LoginForm from "./login-form";
import ResetForm from "./reset-form";
import CheckPage from "./check-page";
import { resetUserPassword } from "../../../write/application/use-cases/user-password-reset/reset-user-password";
import { Nullable } from "../../../../shared-kernel/core/types/nullable";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

type STEP = "login" | "reset" | "check";
interface LocationState {
  from?: string;
}

const LoginPage = () => {
  const [step, setStep] = useState<STEP>("login");
  const [email, setEmail] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: user, fetching } = useAppSelector((state: AppState) => state.userLogin);

  useEffect(() => {
    if (user) {
      const redirect = (state as Nullable<LocationState>)?.from;
      if (redirect) navigate(redirect);
      else if (user.role === ROLES.STUDENT) navigate("/dashboard");
      else if (user.role === ROLES.TEACHER) navigate("/dashboard");
      else if (user.role === ROLES.PROVIDER) navigate("/dashboard");
      else navigate("/transfers");
    }
  }, [user, navigate, state]);

  const onLogin = (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    dispatch(loginUser(body));
  };

  const onForgetPassword = () => setStep("reset");
  const onPasswordReset = (formBody: ResetPasswordFormInputs) => {
    const body = ResetPasswordFormatFormDataToBodyData(formBody);
    dispatch(resetUserPassword(body));
    setEmail(formBody.email);
    setStep("check");
  };
  const onBackToLogin = () => setStep("login");

  return (
    <div className="flex justify-center items-center">
      {step === "login" && <LoginForm fetching={fetching} onSubmit={onLogin} onDisplayResetPasswordForm={onForgetPassword} />}
      {step === "reset" && <ResetForm onSubmit={onPasswordReset} onBack={onBackToLogin} />}
      {step === "check" && <CheckPage onBack={onBackToLogin} email={email} />}
    </div>
  );
};

export default LoginPage;
