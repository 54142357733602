import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";
import { adminRetrieveProviderStudentInvitations } from "@user-management-context/read/application/use-cases/admin/provider-student-invitations-retrieval/retrieve-provider-student-invitations";
import { resetAcceptProviderStudentInvitation } from "@user-management-context/write/application/use-cases/admin/provider-student-invitation-acceptation/accept-provider-student-invitation";

const selectProviderStudentInvitationAcceptation = (state: AppState) => state.providerStudentInvitationAcceptation;

export const useProviderStudentInvitationAcceptation = () => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId, processing } = useSelector(selectProviderStudentInvitationAcceptation);

  useEffect(() => {
    if (justUpdatedId && processing === "success") {
      dispatch(resetAcceptProviderStudentInvitation());
      dispatch(adminRetrieveProviderStudentInvitations());
      toast({ description: "Elève invité !" });
    } else if (processing === "failed") {
      toast({ description: "Erreur lors de l'invitation de l'élève", variant: "destructive" });
    }
  }, [justUpdatedId, dispatch, toast, processing]);
};
