import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherListElement } from "../../../../domain/types/admin/teacher";

export const adminRetrieveTeachers = createAsyncThunk<TeacherListElement[], void, { extra: Partial<Dependencies> }>(
  "teachers/adminRetrieveTeachers",
  async (_: void, { extra: { adminTeacherGateway } }) => {
    return adminTeacherGateway!.all();
  }
);
