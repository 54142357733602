import { createReducer } from "@reduxjs/toolkit";
import { StudentCreateState } from "../../../../../../redux/app-state";
import { createStudent, resetCreateStudent } from "./create-student";

const initialState: StudentCreateState = { justCreatedStudentId: null, fetching: "idle" };

export const createStudentReducer = createReducer<StudentCreateState>(initialState, builder => {
  builder.addCase(createStudent.fulfilled, (state, { payload }) => {
    return {
      ...state,
      justCreatedStudentId: payload,
      fetching: "success",
    };
  });
  builder.addCase(createStudent.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createStudent.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
  builder.addCase(resetCreateStudent, () => {
    return initialState;
  });
});
