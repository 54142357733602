import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherTrainingListVM } from "../../../../domain/types/training";

export const teacherRetrieveTrainings = createAsyncThunk<TeacherTrainingListVM[], void, { extra: Partial<Dependencies> }>(
  "trainings/teacherRetrieveTrainings",
  async (_: void, { extra: { trainingGateway } }) => {
    return trainingGateway!.teacherGetAll();
  }
);

export const resetTeacherRetrieveTrainings = createAction("trainings/resetTeacherRetrieveTrainings");
