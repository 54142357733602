import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";

export const studentRetrieveProviderTrainings = createAsyncThunk<ProviderTraining[], string, { extra: Partial<Dependencies> }>(
  "providers/retrieveProviderTrainings",
  async (providerId: string, { extra: { studentProviderRepository } }) => {
    return studentProviderRepository!.trainingList(providerId);
  }
);

export const resetStudentRetrieveProviderTrainings = createAction("providers/resetStudentRetrieveProviderTrainings");
