export interface ImageFileProps {
  fileSize: number;
  type: string;
  width: number;
  height: number;
}

export interface FileProps {
  fileSize: number;
  type: string;
}

export const MAX_HOURLY_PRICE = 150;
export const TEACHER_MIN_HOURLY_PRICE = 30;
export const ADMIN_MIN_HOURLY_PRICE = 0;

export const MAX_LESSON_HOURS = 10;
export const ADMIN_MAX_LESSON_HOURS = 40;
export const MIN_LESSON_HOURS = 0.25;

export const MAX_STUDIO_PRICE = 20;
export const MIN_STUDIO_PRICE = 0;

// Profile picture
export const IMAGE_TYPE = ["image/jpeg", "image/png"];
const IMAGE_RESOLUTION_MIN_WIDTH = 140;
const IMAGE_RESOLUTION_MIN_HEIGHT = 100;
const IMAGE_MAX_FILE_SIZE = 10 * 1024 * 1024;
export const ERROR_MESSAGE = "Veuillez uploader une image au format jpg ou png de 10mo max (140*100px minimum)";

const hasCorrectResolution = ({ width, height }: ImageFileProps): boolean => {
  const validWidthResolution = width >= IMAGE_RESOLUTION_MIN_WIDTH;
  const validHeightResolution = height >= IMAGE_RESOLUTION_MIN_HEIGHT;
  const isValid = validHeightResolution && validWidthResolution;
  return isValid;
};

const isCorrectImageType = (type: string): boolean => {
  return IMAGE_TYPE.includes(type);
};

const isCorrectImageSize = (fileSize: number): boolean => {
  return fileSize < IMAGE_MAX_FILE_SIZE;
};

export const isValidImageFile = (fileProps: ImageFileProps): boolean => {
  const fileHasCorrectResolution = hasCorrectResolution(fileProps);
  const fileIsCorrectType = isCorrectImageType(fileProps.type);
  const fileIsCorrectSize = isCorrectImageSize(fileProps.fileSize);

  return fileIsCorrectType && fileHasCorrectResolution && fileIsCorrectSize;
};

// Bio & Resume upload
const BIO_AND_RESUME_MAX_FILE_SIZE = 10 * 1024 * 1024;
export const BIO_AND_RESUME_FILETYPE = ["application/pdf"];

const isCorrectType = (type: string): boolean => {
  return BIO_AND_RESUME_FILETYPE.includes(type);
};

const isCorrectSize = (fileSize: number): boolean => {
  return fileSize < BIO_AND_RESUME_MAX_FILE_SIZE;
};

export const isValidFile = (fileProps: FileProps): boolean => {
  const fileIsCorrectType = isCorrectType(fileProps.type);
  const fileIsCorrectSize = isCorrectSize(fileProps.fileSize);

  return fileIsCorrectType && fileIsCorrectSize;
};

// Convention upload
const CONVENTION_MAX_FILE_SIZE = 10 * 1024 * 1024;
export const CONVENTION_FILETYPE = ["application/pdf"];
const isCorrectConventionType = (type: string): boolean => {
  return CONVENTION_FILETYPE.includes(type);
};

const isCorrectConventionSize = (fileSize: number): boolean => {
  return fileSize < CONVENTION_MAX_FILE_SIZE;
};

export const isValidConventionFile = (fileProps: FileProps): boolean => {
  const fileIsCorrectType = isCorrectConventionType(fileProps.type);
  const fileIsCorrectSize = isCorrectConventionSize(fileProps.fileSize);

  return fileIsCorrectType && fileIsCorrectSize;
};

// Invoice upload
const INVOICE_MAX_FILE_SIZE = 10 * 1024 * 1024;
export const INVOICE_FILETYPE = ["application/pdf", "image/jpeg", "image/png"];
const isCorrectInvoiceType = (type: string): boolean => {
  return INVOICE_FILETYPE.includes(type);
};

const isCorrectInvoiceSize = (fileSize: number): boolean => {
  return fileSize < INVOICE_MAX_FILE_SIZE;
};

export const isValidInvoiceFile = (fileProps: FileProps): boolean => {
  const fileIsCorrectType = isCorrectInvoiceType(fileProps.type);
  const fileIsCorrectSize = isCorrectInvoiceSize(fileProps.fileSize);

  return fileIsCorrectType && fileIsCorrectSize;
};
