import React from "react";
import { Outlet } from "react-router-dom";
import bgImg from "../../../assets/img/background/login.jpg";
import logo from "../../../assets/img/logo/transparent_logo.png";

const LoginOutlet = () => {
  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-6 lg:px-0 bg-slate-900 w-screen">
      <div className="relative hidden h-screen flex-col bg-muted dark:border-r lg:flex col-span-4">
        <img className="w-full h-full object-cover" src={bgImg} alt="Authentication" />
      </div>
      <div className="text-white lg:col-span-2 flex flex-col items-center space-y-6">
        <div className="flex justify-center w-[250px]">
          <img src={logo} alt="logo" width="250" />
        </div>
        <div className="lg:w-[300px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LoginOutlet;
