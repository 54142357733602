import { createReducer } from "@reduxjs/toolkit";
import { resetDeleteProviderTraining, deleteProviderTraining } from "./delete-provider-training";
import { SimpleState } from "@redux/app-state";

const initialState: SimpleState = { processing: "idle" };

export const deleteProviderTrainingReducer = createReducer<SimpleState>(initialState, builder => {
  builder.addCase(deleteProviderTraining.fulfilled, state => {
    return {
      ...state,
      processing: "success",
    };
  });
  builder.addCase(deleteProviderTraining.pending, state => {
    return {
      ...state,
      processing: "pending",
    };
  });
  builder.addCase(deleteProviderTraining.rejected, state => {
    return {
      ...state,
      processing: "failed",
    };
  });
  builder.addCase(resetDeleteProviderTraining, () => {
    return initialState;
  });
});
