import { createReducer } from "@reduxjs/toolkit";
import { CreateStudentAccountState } from "@redux/app-state";
import { createStudentAccount } from "./create-student-account";

const initialState: CreateStudentAccountState = { fetching: "idle" };

export const createStudentAccountReducer = createReducer<CreateStudentAccountState>(initialState, builder => {
  builder.addCase(createStudentAccount.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(createStudentAccount.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createStudentAccount.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
