import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { TeacherVM } from "../../../../domain/types/student/teacher";

export const retrieveTeachers = createAsyncThunk<TeacherVM[], void, { extra: Partial<Dependencies> }>(
  "teachers/retrieveTeachers",
  async (_: void, { extra: { studentGateway } }) => {
    return studentGateway!.teacherList();
  }
);
