import { createReducer } from "@reduxjs/toolkit";
import { CreateAdminAccountState } from "@redux/app-state";
import { createAdminAccount } from "./create-admin-account";

const initialState: CreateAdminAccountState = { fetching: "idle" };

export const createAdminAccountReducer = createReducer<CreateAdminAccountState>(initialState, builder => {
  builder.addCase(createAdminAccount.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(createAdminAccount.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(createAdminAccount.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
