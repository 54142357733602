import { createReducer } from "@reduxjs/toolkit";
import { ConventionCancellationState } from "@redux/app-state";
import { cancelConvention } from "./cancel-convention";

const initialState: ConventionCancellationState = {
  fetching: "idle",
};

export const cancelConventionReducer = createReducer<ConventionCancellationState>(initialState, builder => {
  builder.addCase(cancelConvention.fulfilled, state => {
    return {
      ...state,
      fetching: "success",
    };
  });
  builder.addCase(cancelConvention.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(cancelConvention.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
