import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";

export const doesUserExist = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "admins/doesUserExist",
  async (email: string, { extra: { userGateway } }) => {
    return userGateway!.doesUserExist(email);
  }
);

export const resetDoesUserExist = createAction("users/resetDoesUserExist");
