import { object, string } from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { CreateProviderAccountBody } from "@user-management-context/write/domain/types/provider/provider";

export type ProviderAccountCreationFormInputs = {
  name: string;
  mobile: string;
  address: string;
  additionalAddress: string;
  iban: string;
  siret: string;
  password: string;
};

export const defaultValues: ProviderAccountCreationFormInputs = {
  name: "",
  mobile: "",
  password: "",
  address: "",
  additionalAddress: "",
  iban: "",
  siret: "",
};

export const schema = object().shape({
  name: string().max(255).required(REQUIRED_FIELD),
  mobile: string()
    .required("Veuillez saisir un numéro de téléphone")
    .test("mobile", `Numéro de téléphone invalide`, value => Boolean(value) && isPossiblePhoneNumber(value as string)),
  password: string().max(255).required(REQUIRED_FIELD),
  address: string().max(255).required(REQUIRED_FIELD),
  additionalAddress: string().max(255).required(REQUIRED_FIELD),
  iban: string().max(255).required(REQUIRED_FIELD),
  siret: string().max(14),
});

export const providerAccountCreationFormatDataToFormData = (provider: Provider): ProviderAccountCreationFormInputs => {
  return {
    name: provider.name,
    mobile: provider.mobile,
    address: provider.address,
    additionalAddress: provider.additionalAddress,
    iban: provider.iban,
    password: "",
    siret: provider.siret ?? "",
  };
};

export const providerAccountCreationFormatFormDataToBodyData = (
  provider: ProviderAccountCreationFormInputs,
  accountId: string,
  token: string,
  providerId: string
): CreateProviderAccountBody => {
  return {
    accountId,
    providerId,
    token,
    name: provider.name,
    mobile: provider.mobile,
    address: provider.address,
    additionalAddress: provider.additionalAddress,
    iban: provider.iban,
    password: provider.password,
    siret: provider.siret ?? null,
  };
};
