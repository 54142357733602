import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "src/redux/store";

export const refuseProviderStudentInvitation = createAsyncThunk<void, string, { extra: Partial<Dependencies> }>(
  "providerStudentInvitations/refuseProviderStudentInvitation",
  async (id: string, { extra: { adminProviderRepository } }) => {
    return adminProviderRepository!.refuseStudentInvitation(id);
  }
);

export const resetRefuseProviderStudentInvitation = createAction("providerStudentInvitations/resetRefuseProviderStudentInvitation");
