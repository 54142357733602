import { PROVIDER_INTERNSHIP_STATUS, ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";
import { ProviderInternshipBuilder } from "@academy-context/test-utils/provider-internship-builder";

const providerInternship: ProviderInternship = ProviderInternshipBuilder().build();
const providerInternship10: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-10")
  .withStudent({
    id: "student-id-10",
    profilePictureUrl: null,
    name: "TITI",
    lastName: "TOTO",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER)
  .withCreationDate("2022-10-08T03:00:00Z")
  .withProvider({ id: "provider-3", tag: "LALA" })
  .build();
const providerInternship2: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-2")
  .withStudent({
    id: "student-id-2",
    profilePictureUrl: null,
    name: "Youssef",
    lastName: "Fki",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_SUBMITTED_BY_STUDENT)
  .withCreationDate("2022-10-10T03:00:00Z")
  .build();
const providerInternship3: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-3")
  .withStudent({
    id: "student-id-3",
    profilePictureUrl: null,
    name: "Clélia",
    lastName: "Tst",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_SUBMITTED_TO_FUNDER)
  .withCreationDate("2022-10-09T03:00:00Z")
  .build();
const providerInternship4: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-4")
  .withStudent({
    id: "student-id-4",
    profilePictureUrl: null,
    name: "Max",
    lastName: "R",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CONFIRMED)
  .withCreationDate("2022-10-07T03:00:00Z")
  .withDetails({
    training: "Chanteurs (répertoires lyrique, baroque et contemporain) : travailler sa technique vocale et son interprétation",
    interval: {
      start: new Date("2023-11-25T23:00:00Z").toISOString(),
      end: new Date("2023-12-02T22:59:59.999Z").toISOString(),
    },
    price: 700,
  })
  .build();
const providerInternship9: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-2")
  .withStudent({
    id: "student-id-2",
    profilePictureUrl: null,
    name: "Jul",
    lastName: "Marseille",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CANCELED_BY_PROVIDER)
  .withCreationDate("2022-10-06T03:00:00Z")
  .withDetails({
    training: "La formation de Ysf",
    interval: {
      start: new Date("2023-11-25T23:00:00Z").toISOString(),
      end: new Date("2023-12-02T22:59:59.999Z").toISOString(),
    },
    price: 700,
  })
  .build();
const providerInternship8: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-8")
  .withStudent({
    id: "student-id-8",
    profilePictureUrl: null,
    name: "Jean",
    lastName: "Sch",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_CANCELED_BY_ADMIN)
  .withCreationDate("2022-10-05T03:00:00Z")
  .withDetails({
    training: "La formation de Jean Claude la renault",
    interval: {
      start: new Date("2023-11-25T23:00:00Z").toISOString(),
      end: new Date("2023-12-02T22:59:59.999Z").toISOString(),
    },
    price: 700,
  })
  .build();
const providerInternship7: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-7")
  .withStudent({
    id: "student-id-7",
    profilePictureUrl: null,
    name: "Baptiste",
    lastName: "Bcn",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.INTERNSHIP_FINISHED)
  .withCreationDate("2022-10-04T03:00:00Z")
  .withDetails({
    training: "La formation de Jean Claude la renault",
    interval: {
      start: new Date("2023-11-25T23:00:00Z").toISOString(),
      end: new Date("2023-12-02T22:59:59.999Z").toISOString(),
    },
    price: 700,
  })
  .build();
const providerInternship6: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-6")
  .withStudent({
    id: "student-id-6",
    profilePictureUrl: null,
    name: "Guigui",
    lastName: "LR",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.INVOICE_RECEIVED)
  .withCreationDate("2022-10-03T03:00:00Z")
  .withDetails({
    training: "La formation de Jean Claude la renault",
    interval: {
      start: new Date("2023-11-25T23:00:00Z").toISOString(),
      end: new Date("2023-12-02T22:59:59.999Z").toISOString(),
    },
    price: 700,
  })
  .build();
const providerInternship5: ProviderInternship = ProviderInternshipBuilder()
  .withId("file-5")
  .withStudent({
    id: "student-id-5",
    profilePictureUrl: null,
    name: "Timo",
    lastName: "Ngolo",
  })
  .withStatus(PROVIDER_INTERNSHIP_STATUS.INVOICE_PAID)
  .withCreationDate("2022-10-02T03:00:00Z")
  .withDetails({
    training: "La formation de Jean Claude la renault",
    interval: {
      start: new Date("2023-11-25T23:00:00Z").toISOString(),
      end: new Date("2023-12-02T22:59:59.999Z").toISOString(),
    },
    price: 700,
  })
  .build();

export const providerInternships = [
  providerInternship3,
  providerInternship,
  providerInternship2,
  providerInternship4,
  providerInternship5,
  providerInternship6,
  providerInternship7,
  providerInternship8,
  providerInternship9,
  providerInternship10,
];
