import { createReducer } from "@reduxjs/toolkit";
import { StandardPaymentSummaryRetrievalState } from "@redux/app-state";
import { retrieveTransferSummary } from "./retrieve-transfer-summary";

const initialState: StandardPaymentSummaryRetrievalState = {
  fetching: "idle",
  data: {
    current: {
      data: [],
      interval: {
        start: "",
        end: "",
      },
    },
    previous: {
      data: [],
    },
  },
};

export const retrieveTransferSummaryReducer = createReducer<StandardPaymentSummaryRetrievalState>(initialState, builder => {
  builder.addCase(retrieveTransferSummary.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(retrieveTransferSummary.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(retrieveTransferSummary.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
