import { createReducer } from "@reduxjs/toolkit";
import { RetrievalState } from "@redux/app-state";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";

const initialState: RetrievalState<ProviderListItem[]> = { data: [], fetching: "idle" };

export const adminRetrieveProvidersReducer = createReducer<RetrievalState<ProviderListItem[]>>(initialState, builder => {
  builder.addCase(adminRetrieveProviders.fulfilled, (state, { payload }) => {
    return {
      ...state,
      data: payload,
      fetching: "success",
    };
  });
  builder.addCase(adminRetrieveProviders.pending, state => {
    return {
      ...state,
      fetching: "pending",
    };
  });
  builder.addCase(adminRetrieveProviders.rejected, state => {
    return {
      ...state,
      fetching: "failed",
    };
  });
});
