import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { Nullable } from "../../../../shared-kernel/core/types/nullable";

export enum PLACE_VALUE {
  PERSO = "perso",
  KALARI_PARIS = "kalari_paris",
  PIANO_INTERNATIONAL_PARIS = "piano_international_paris",
  PIANO_INTERNATIONAL_PARIS_PIANO_DROIT = "piano_international_paris_piano_droit",
  CITÉ_INTERNATIONALE_PARIS = "cité_internationale_paris",
  STUDIO_BLEU_PARIS = "studio_bleu_paris",
  ACCORD_PARFAIT = "accord_parfait",
  BERLIOZ_NICE = "berlioz_nice",
  AUTRE_NON_PAYANT = "autre_non_payant",
  LUNA_ROSSA = "luna_rossa",
  BEAUMARCHAIS = "beaumarchais",
}

export const PLACE_NAMES: Record<PLACE_VALUE, string> = {
  [PLACE_VALUE.PERSO]: "Studio non payant (perso)",
  [PLACE_VALUE.KALARI_PARIS]: "Kalari Paris",
  [PLACE_VALUE.PIANO_INTERNATIONAL_PARIS_PIANO_DROIT]: "Piano International - piano droit (12€)",
  [PLACE_VALUE.PIANO_INTERNATIONAL_PARIS]: "Piano International - piano à queue (15€)",
  [PLACE_VALUE.CITÉ_INTERNATIONALE_PARIS]: "Cité internationale Paris",
  [PLACE_VALUE.STUDIO_BLEU_PARIS]: "Studio Bleu Paris",
  [PLACE_VALUE.ACCORD_PARFAIT]: "Accord parfait",
  [PLACE_VALUE.BERLIOZ_NICE]: "Berlioz Nice",
  [PLACE_VALUE.AUTRE_NON_PAYANT]: "Autre non payant",
  [PLACE_VALUE.LUNA_ROSSA]: "Luna Rossa Paris 13",
  [PLACE_VALUE.BEAUMARCHAIS]: "Beaumarchais",
};

export const placeById: Map<PLACE_VALUE, { label: string; price: Nullable<number> }> = new Map([
  [PLACE_VALUE.PERSO, { label: PLACE_NAMES[PLACE_VALUE.PERSO], price: null }],
  [PLACE_VALUE.KALARI_PARIS, { label: PLACE_NAMES[PLACE_VALUE.KALARI_PARIS], price: 16.5 }],
  [PLACE_VALUE.PIANO_INTERNATIONAL_PARIS_PIANO_DROIT, { label: PLACE_NAMES[PLACE_VALUE.PIANO_INTERNATIONAL_PARIS_PIANO_DROIT], price: 12 }],
  [PLACE_VALUE.PIANO_INTERNATIONAL_PARIS, { label: PLACE_NAMES[PLACE_VALUE.PIANO_INTERNATIONAL_PARIS], price: 15 }],
  [PLACE_VALUE.CITÉ_INTERNATIONALE_PARIS, { label: PLACE_NAMES[PLACE_VALUE.CITÉ_INTERNATIONALE_PARIS], price: 15 }],
  [PLACE_VALUE.STUDIO_BLEU_PARIS, { label: PLACE_NAMES[PLACE_VALUE.STUDIO_BLEU_PARIS], price: 15 }],
  [PLACE_VALUE.ACCORD_PARFAIT, { label: PLACE_NAMES[PLACE_VALUE.ACCORD_PARFAIT], price: 15 }],
  [PLACE_VALUE.BERLIOZ_NICE, { label: PLACE_NAMES[PLACE_VALUE.BERLIOZ_NICE], price: 8 }],
  [PLACE_VALUE.AUTRE_NON_PAYANT, { label: PLACE_NAMES[PLACE_VALUE.AUTRE_NON_PAYANT], price: 0 }],
  [PLACE_VALUE.LUNA_ROSSA, { label: PLACE_NAMES[PLACE_VALUE.LUNA_ROSSA], price: 12 }],
  [PLACE_VALUE.BEAUMARCHAIS, { label: PLACE_NAMES[PLACE_VALUE.BEAUMARCHAIS], price: 13 }],
]);

export let locationOptions: ComboboxOptions[] = [];
placeById.forEach((v, k) => locationOptions.push({ value: k, label: v.label }));
locationOptions = locationOptions.filter(option => option.value !== PLACE_VALUE.KALARI_PARIS);
locationOptions = locationOptions.sort((a, b) => {
  if (b.label > a.label) {
    return -1;
  } else if (b.label < a.label) {
    return 1;
  }
  return 0;
});
