import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { AdminLessonListVM } from "../../../../../domain/types/admin/lesson";

export const adminRetrieveLastLessons = createAsyncThunk<AdminLessonListVM[], void, { extra: Partial<Dependencies> }>(
  "lessons/adminRetrieveLastLessons",
  async (_: void, { extra: { adminLessonGateway } }) => {
    return adminLessonGateway!.lastLessons();
  }
);
