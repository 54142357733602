import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Dependencies } from "@redux/store";
import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";

export const createConvention = createAsyncThunk<string, CreateConventionBody, { extra: Partial<Dependencies> }>(
  "conventions/createConvention",
  async (convention: CreateConventionBody, { extra: { adminConventionGateway } }) => {
    return adminConventionGateway!.create(convention);
  }
);

export const resetCreateConvention = createAction("conventions/resetCreateConvention");
