import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateRefundBody } from "@academy-context/write/domain/types/admin/refunds";
import { Dependencies } from "src/redux/store";

export const updateRefund = createAsyncThunk<void, { id: string; body: UpdateRefundBody }, { extra: Partial<Dependencies> }>(
  "refunds/updateRefund",
  async ({ id, body }: { id: string; body: UpdateRefundBody }, { extra: { refundRequestGateway } }) => {
    return refundRequestGateway!.update(id, body);
  }
);

export const resetUpdateRefund = createAction("refunds/resetUpdateRefund");
