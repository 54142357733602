import { Provider } from "@user-management-context/read/domain/types/admin/provider";
import { providerId } from "src/test/utils/common";

const defaultProvider: Provider = {
  id: providerId,
  name: "Les ateliers du libre Artiste",
  tag: "LALA",
  email: "lala@gmail.com",
  students: ["Clélia Timsit", "Maxime Reymond"],
  mobile: "+33656352678",
  iban: "FR76 4061 8803 0000 0408 3645 138",
  siret: null,
  address: "20 rue de Stalingrad",
  additionalAddress: "Paris 75019",
  configuration: {
    isTrainingFollowUpManagedByOperaOff: true,
    areConventionSentToOperaOff: true,
  },
};

export const ProviderBuilder = (data: Provider = defaultProvider) => {
  return {
    withId: (id: Provider["id"]) => ProviderBuilder({ ...data, id }),
    withName: (name: Provider["name"]) => ProviderBuilder({ ...data, name }),
    withTag: (tag: Provider["tag"]) => ProviderBuilder({ ...data, tag }),
    build: (): Provider => data,
  };
};
