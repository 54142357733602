import { PROVIDER_INTERNSHIP_STATUS, ProviderInternship } from "@academy-context/read/domain/types/admin/provider-internship";
import { OPERA_OFF_TRAINING } from "@academy-context/shared/domain/types/enums/funding-request-trainings";
import { providerId, providerInternshipId, studentId } from "src/test/utils/common";

const defaultStudentFile: ProviderInternship = {
  id: providerInternshipId,
  student: {
    id: studentId,
    profilePictureUrl: null,
    name: "Marc",
    lastName: "Siebert",
  },
  provider: {
    tag: "Lala",
    id: providerId,
  },
  details: {
    training: OPERA_OFF_TRAINING.ALLEMAND_CHANT,
    price: null,
    interval: null,
  },
  status: PROVIDER_INTERNSHIP_STATUS.FUNDING_REQUEST_ACCEPTED_BY_FUNDER,
  creationDate: "2022-10-08T03:00:00Z",
};

export const ProviderInternshipBuilder = (data: ProviderInternship = defaultStudentFile) => {
  return {
    withId: (id: ProviderInternship["id"]) => ProviderInternshipBuilder({ ...data, id }),
    withCreationDate: (creationDate: ProviderInternship["creationDate"]) => ProviderInternshipBuilder({ ...data, creationDate }),
    withProvider: (provider: ProviderInternship["provider"]) => ProviderInternshipBuilder({ ...data, provider }),
    withStatus: (status: ProviderInternship["status"]) => ProviderInternshipBuilder({ ...data, status }),
    withStudent: (student: ProviderInternship["student"]) => ProviderInternshipBuilder({ ...data, student }),
    withDetails: (details: ProviderInternship["details"]) => ProviderInternshipBuilder({ ...data, details }),
    build: (): ProviderInternship => data,
  };
};
