import { createReducer } from "@reduxjs/toolkit";
import {
  resetUploadInvoice,
  resetUploadInvoiceForId,
  uploadInvoice,
} from "@academy-context/write/application/use-cases/student/invoice-upload/upload-invoice";
import { InvoiceUploadsState, InvoiceUploadSubState } from "src/redux/app-state";

const initialState: InvoiceUploadsState = {};

export const uploadInvoiceReducer = createReducer<InvoiceUploadsState>(initialState, builder => {
  builder.addCase(uploadInvoice.fulfilled, (state, { meta: { arg }, payload }) => {
    const upload: InvoiceUploadSubState = { processing: "success", invoiceUrl: payload };
    return {
      ...state,
      [arg.uploadId]: upload,
    };
  });
  builder.addCase(uploadInvoice.pending, (state, { meta: { arg } }) => {
    const upload: InvoiceUploadSubState = { processing: "pending", invoiceUrl: null };
    return {
      ...state,
      [arg.uploadId]: upload,
    };
  });
  builder.addCase(uploadInvoice.rejected, (state, { meta: { arg } }) => {
    const upload: InvoiceUploadSubState = { processing: "failed", invoiceUrl: null };
    return {
      ...state,
      [arg.uploadId]: upload,
    };
  });
  builder.addCase(resetUploadInvoice, () => {
    return initialState;
  });
  builder.addCase(resetUploadInvoiceForId, (state, { payload }) => {
    const upload: InvoiceUploadSubState = { processing: "idle", invoiceUrl: null };
    return {
      ...state,
      [payload]: upload,
    };
  });
});
