import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { Progress } from "@shared-kernel/primary/shared/shadcn/ui/progress";
import clsx from "clsx";

interface Props {
  convention: { teacher: string; hours: number; hoursDone: number };
}

export const ConventionCard = ({ convention }: Props) => {
  const { teacher, hours, hoursDone } = convention;

  const isFinished = hours === hoursDone;

  return (
    <Card className="bg-primary-foreground text-white w-full dark:bg-primary-foreground dark:text-white">
      <CardHeader className="border-b border-gray-400 p-2">
        <CardTitle>{teacher}</CardTitle>
      </CardHeader>
      <CardContent className="flex h-[60px] py-0  px-2 items-center">
        <Progress value={(hoursDone / hours) * 100} indicatorClassName={clsx(isFinished && "bg-[#2ab514]")} />
        <div className={clsx("pl-1 flex flex-col items-center justify-center", isFinished && "text-[#2ab514]")}>
          <span className="text-base">
            {hoursDone}/{hours}
          </span>
          <span className="font-normal text-xs text-center">heures prises</span>
        </div>
      </CardContent>
    </Card>
  );
};
