import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToast } from "@shared-kernel/primary/shared/shadcn/ui/use-toast";
import { AppState } from "@redux/app-state";
import { useAppDispatch } from "@redux/hooks";

const selectRefundUpdate = (state: AppState) => state.refundUpdate;

interface Props {
  onRefresh: () => void;
}

export const useRefundUpdate = ({ onRefresh }: Props) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { justUpdatedId } = useSelector(selectRefundUpdate);

  useEffect(() => {
    if (justUpdatedId) {
      toast({ description: "Mise à jour effectuée avec succès!" });
      onRefresh();
    }
  }, [justUpdatedId, dispatch, onRefresh, toast]);
};
